import {
  APPROACHING,
  ARRIVED_ONSITE,
  ARRIVED_ONSITE_RSV,
  ASSIGNED,
  BOOKED,
  COMPLETED,
  DROP_LOCATION,
  DROP_LOCATION_RSV,
  DROP_SIGN,
  DROP_SIGN_RSV,
  EN_ROUTE_DROP,
  EN_ROUTE_DROP_RSV,
  LOADED
} from "../../app-consts/appConstants";
import {TRANSLATION_CONSTANTS} from "../../app-consts/translations";

export const softServiceSteps = [
  {
    title: TRANSLATION_CONSTANTS.FINDING_NEARBY_RESCUER,
    desc: TRANSLATION_CONSTANTS.FINDING_RESCUER_DESC,
    servicestatus: ["NEW JOB", "CSR TAKE OVER"],
    shortTitle: TRANSLATION_CONSTANTS.FINDING_PROVIDER,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.WEVE_FOUND_A_RESCUER,
    desc: TRANSLATION_CONSTANTS.NOTIFY_OF_PROVIDER_FOUND,
    servicestatus: ["BOOKED","ASSIGNED"],
    shortTitle: TRANSLATION_CONSTANTS.PROVIDER_ASSIGNED,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.RESCUER_ON_WAY,
    desc: TRANSLATION_CONSTANTS.STAY_WITH_VEHICLE,
    servicestatus: ["EN-ROUTE", "GPS", "APPROACHING"],
    shortTitle: TRANSLATION_CONSTANTS.PROVIDER_EN_ROUTE,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.RESCUER_ARRIVED,
    desc: TRANSLATION_CONSTANTS.RESCUER_WITH_VEHICLE,
    servicestatus: ["ARRIVED ONSITE"],
    shortTitle: TRANSLATION_CONSTANTS.PROVIDER_ARRIVED,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.RESCUE_COMPLETED,
    desc: TRANSLATION_CONSTANTS.MISSION_ACCOMPLISHED,
    servicestatus: ["COMPLETED"],
    shortTitle: TRANSLATION_CONSTANTS.RESCUE_COMPLETE,
    status: `notcomplete`
  }
]
export const multilanguageSoftServiceSteps = [
  {
    title: TRANSLATION_CONSTANTS.FINDING_NEARBY_RESCUER,
    desc: TRANSLATION_CONSTANTS.FINDING_RESCUER_DESC,
    servicestatus: ["NEW_JOB"],
    shortTitle: TRANSLATION_CONSTANTS.FINDING_PROVIDER,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.WEVE_FOUND_A_RESCUER,
    desc: TRANSLATION_CONSTANTS.NOTIFY_OF_PROVIDER_FOUND,
    servicestatus: ["BOOKED","ASSIGNED"],
    shortTitle: TRANSLATION_CONSTANTS.PROVIDER_ASSIGNED,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.RESCUER_ON_WAY,
    desc: TRANSLATION_CONSTANTS.STAY_WITH_VEHICLE,
    servicestatus: ["EN-ROUTE", "GPS", "APPROACHING"],
    shortTitle: TRANSLATION_CONSTANTS.PROVIDER_EN_ROUTE,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.RESCUER_ARRIVED,
    desc: TRANSLATION_CONSTANTS.RESCUER_WITH_VEHICLE,
    servicestatus: ["ARRIVED_ONSITE"],
    shortTitle: TRANSLATION_CONSTANTS.PROVIDER_ARRIVED,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.RESCUE_COMPLETED,
    desc: TRANSLATION_CONSTANTS.MISSION_ACCOMPLISHED,
    servicestatus: ["COMPLETED"],
    shortTitle: TRANSLATION_CONSTANTS.RESCUE_COMPLETE,
    status: `notcomplete`
  }
]

export const towSteps = [
  {
    title: TRANSLATION_CONSTANTS.FINDING_NEARBY_RESCUER,
    desc: TRANSLATION_CONSTANTS.FINDING_RESCUER_DESC,
    servicestatus: ["NEW JOB", "CSR TAKE OVER"],
    shortTitle: TRANSLATION_CONSTANTS.FINDING_PROVIDER,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.WEVE_FOUND_A_RESCUER,
    desc: TRANSLATION_CONSTANTS.NOTIFY_OF_PROVIDER_FOUND,
    servicestatus: ["BOOKED","ASSIGNED"],
    shortTitle: TRANSLATION_CONSTANTS.PROVIDER_ASSIGNED,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.RESCUER_ON_WAY,
    desc: TRANSLATION_CONSTANTS.STAY_WITH_VEHICLE,
    servicestatus: ["EN-ROUTE", "GPS", "APPROACHING"],
    shortTitle: TRANSLATION_CONSTANTS.PROVIDER_EN_ROUTE,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.RESCUER_ARRIVED,
    desc: TRANSLATION_CONSTANTS.RESCUER_WITH_VEHICLE,
    servicestatus: ["ARRIVED ONSITE", "ONSITE", "LOADED", "EN-ROUTE DROP"],
    shortTitle: TRANSLATION_CONSTANTS.PROVIDER_ARRIVED,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.VEHICLE_DROPPED_OFF,
    desc: TRANSLATION_CONSTANTS.BACK_ON_THE_ROAD,
    servicestatus: ["DROP LOCATION", "DROP SIGN"],
    shortTitle: TRANSLATION_CONSTANTS.DROPPED_OFF,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.RESCUE_COMPLETED,
    desc: TRANSLATION_CONSTANTS.MISSION_ACCOMPLISHED_SHARE_FEEDBACK,
    servicestatus: ["COMPLETED"],
    shortTitle: TRANSLATION_CONSTANTS.RESCUE_COMPLETE,
    status: `notcomplete`
  }
]
export const multilanguageTowSteps = [
  {
    title: TRANSLATION_CONSTANTS.FINDING_NEARBY_RESCUER,
    desc: TRANSLATION_CONSTANTS.FINDING_RESCUER_DESC,
    servicestatus: ["NEW_JOB"],
    shortTitle: TRANSLATION_CONSTANTS.FINDING_PROVIDER,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.WEVE_FOUND_A_RESCUER,
    desc: TRANSLATION_CONSTANTS.NOTIFY_OF_PROVIDER_FOUND,
    servicestatus: ["BOOKED","ASSIGNED"],
    shortTitle: TRANSLATION_CONSTANTS.PROVIDER_ASSIGNED,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.RESCUER_ON_WAY,
    desc: TRANSLATION_CONSTANTS.STAY_WITH_VEHICLE,
    servicestatus: ["EN-ROUTE", "GPS", "APPROACHING"],
    shortTitle: TRANSLATION_CONSTANTS.PROVIDER_EN_ROUTE,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.RESCUER_ARRIVED,
    desc: TRANSLATION_CONSTANTS.RESCUER_WITH_VEHICLE,
    servicestatus: ["ARRIVED_ONSITE", "ONSITE", "LOADED", "EN-ROUTE_DROP"],
    shortTitle: TRANSLATION_CONSTANTS.PROVIDER_ARRIVED,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.VEHICLE_DROPPED_OFF,
    desc: TRANSLATION_CONSTANTS.BACK_ON_THE_ROAD,
    servicestatus: ["DROP_LOCATION", "DROP_SIGN"],
    shortTitle: TRANSLATION_CONSTANTS.DROPPED_OFF,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.RESCUE_COMPLETED,
    desc: TRANSLATION_CONSTANTS.MISSION_ACCOMPLISHED,
    servicestatus: ["COMPLETED"],
    shortTitle: TRANSLATION_CONSTANTS.RESCUE_COMPLETE,
    status: `notcomplete`
  }
]

export const rentalTwoWayTowSteps = [
  {
    title: TRANSLATION_CONSTANTS.FINDING_NEARBY_RESCUER,
    desc: TRANSLATION_CONSTANTS.FINDING_RESCUER_DESC,
    servicestatus: ["NEW_JOB"],
    shortTitle: TRANSLATION_CONSTANTS.FINDING_PROVIDER,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.WEVE_FOUND_A_RESCUER,
    desc: TRANSLATION_CONSTANTS.CALCULATED_NO_GPS,
    servicestatus: ["BOOKED", "ASSIGNED"],
    shortTitle: TRANSLATION_CONSTANTS.PROVIDER_ASSIGNED,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.RESCUER_ON_WAY,
    desc: TRANSLATION_CONSTANTS.PICKING_REPLACEMENT_DESC,
    servicestatus: ["EN-ROUTE", "ARRIVED_ONSITE_EXCHG", "GPS"],
    shortTitle: TRANSLATION_CONSTANTS.PICKING_REPLACEMENT,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.RESCUER_ARRIVED,
    desc: TRANSLATION_CONSTANTS.PROVIDER_EN_ROUTE_DESC,
    servicestatus: ["LOADED_EXCHG", "APPROACHING_EXCHG"],
    shortTitle: TRANSLATION_CONSTANTS.PROVIDER_EN_ROUTE,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.VEHICLE_DROPPED_OFF,
    desc: TRANSLATION_CONSTANTS.RESCUER_WITH_VEHICLE,
    servicestatus: ["ARRIVED_ONSITE", "EN-ROUTE_DISBLE", "ARRIVED_ONSITE_DISBL", "LOADED"],
    shortTitle: TRANSLATION_CONSTANTS.PROVIDER_ARRIVED,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.RESCUE_COMPLETED,
    desc: TRANSLATION_CONSTANTS.RESCUE_COMPLETE_DESC,
    servicestatus: ["DROP_LOCATION", "COMPLETED"],
    shortTitle: TRANSLATION_CONSTANTS.RESCUE_COMPLETE,
    status: `notcomplete`
  }
];

export const reverseTowSteps = [
  {
    title: TRANSLATION_CONSTANTS.FINDING_NEARBY_RESCUER,
    desc: TRANSLATION_CONSTANTS.FINDING_RESCUER_DESC,
    servicestatus: ["NEW_JOB"],
    shortTitle: TRANSLATION_CONSTANTS.FINDING_PROVIDER,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.WEVE_FOUND_A_RESCUER,
    desc: TRANSLATION_CONSTANTS.CALCULATED_NO_GPS,
    servicestatus: ["BOOKED", "ASSIGNED"],
    shortTitle: TRANSLATION_CONSTANTS.PROVIDER_ASSIGNED,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.RESCUER_ON_WAY,
    desc: TRANSLATION_CONSTANTS.PICKING_REPLACEMENT_DESC,
    servicestatus: ["EN-ROUTE", "ARRIVED_ONSITE_EXCHG", "GPS"],
    shortTitle: TRANSLATION_CONSTANTS.PICKING_REPLACEMENT,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.RESCUER_ARRIVED,
    desc: TRANSLATION_CONSTANTS.PROVIDER_EN_ROUTE_DESC,
    servicestatus: ["LOADED_EXCHG", "APPROACHING_EXCHG"],
    shortTitle: TRANSLATION_CONSTANTS.PROVIDER_EN_ROUTE,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.VEHICLE_DROPPED_OFF,
    desc: TRANSLATION_CONSTANTS.RESCUER_WITH_VEHICLE,
    servicestatus: ["ARRIVED_ONSITE"],
    shortTitle: TRANSLATION_CONSTANTS.PROVIDER_ARRIVED,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.RESCUE_COMPLETED,
    desc: TRANSLATION_CONSTANTS.RESCUE_COMPLETE_REVERSE_TOW,
    servicestatus: ["COMPLETED"],
    shortTitle: TRANSLATION_CONSTANTS.RESCUE_COMPLETE,
    status: `notcomplete`
  }
];

export const passengerDropOffTowSteps = [
  {
    title: TRANSLATION_CONSTANTS.FINDING_NEARBY_RESCUER,
    desc: TRANSLATION_CONSTANTS.FINDING_RESCUER_DESC,
    servicestatus: ["NEW_JOB"],
    shortTitle: TRANSLATION_CONSTANTS.FINDING_PROVIDER,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.WEVE_FOUND_A_RESCUER,
    desc: TRANSLATION_CONSTANTS.CALCULATED_NO_GPS,
    servicestatus: ["BOOKED", "ASSIGNED"],
    shortTitle: TRANSLATION_CONSTANTS.PROVIDER_ASSIGNED,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.RESCUER_ON_WAY,
    desc: TRANSLATION_CONSTANTS.PROVIDER_EN_ROUTE_PASSENGER,
    servicestatus: ["EN-ROUTE", "APPROACHING", "GPS"],
    shortTitle: TRANSLATION_CONSTANTS.PROVIDER_EN_ROUTE,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.RESCUER_ARRIVED,
    desc: TRANSLATION_CONSTANTS.RESCUER_WITH_VEHICLE,
    servicestatus: ["ARRIVED_ONSITE", "LOADED"],
    shortTitle: TRANSLATION_CONSTANTS.PROVIDER_ARRIVED,
    status: `notcomplete`
  },
  {
    title: TRANSLATION_CONSTANTS.RESCUE_COMPLETED,
    desc: TRANSLATION_CONSTANTS.RESCUE_COMPLETE_PASSENGER_DESC,
    servicestatus: ["DROP_LOCATION", "COMPLETED"],
    shortTitle: TRANSLATION_CONSTANTS.RESCUE_COMPLETE,
    status: `notcomplete`
  }
];

export const ETA_NOT_REQUIRED_STATUSES = ["ARRIVED_ONSITE", "COMPLETED", "ONSITE", "LOADED", "EN-ROUTE_DROP", "DROP_LOCATION", "ARRIVED_ONSITE_DISBL", "EN-ROUTE_DISBLE"];
export const PREDICTIVE = 'Predictive';
export const CALCULATED_GPS = 'Calculated GPS';
export const CALCULATED_NO_GPS = 'Calculated No GPS';
export const INITIAL = 'INITIAL';
export const ETA_UPDATE = 'ETA Update';
export const CALCULATED_ETA_UPDATE = 'Calculated ETA Update';

export const STATUS_FOR_HIDE_CANCEL = [APPROACHING, ARRIVED_ONSITE, ARRIVED_ONSITE_RSV, LOADED, DROP_LOCATION, DROP_LOCATION_RSV, DROP_SIGN, DROP_SIGN_RSV, EN_ROUTE_DROP, EN_ROUTE_DROP_RSV, COMPLETED];


export const updatePredictiveMessagingConstant = (projectedETAType, rescueStatus, isTwoWayTow) => {
  switch (projectedETAType){
    case (!isTwoWayTow || rescueStatus === BOOKED || rescueStatus === ASSIGNED) && CALCULATED_NO_GPS:
      return TRANSLATION_CONSTANTS.CALCULATED_NO_GPS
    case PREDICTIVE:
    case CALCULATED_GPS:
      return TRANSLATION_CONSTANTS.CALCULATED_GPS
    case (!isTwoWayTow || rescueStatus === BOOKED || rescueStatus === ASSIGNED) && ETA_UPDATE:
    case (!isTwoWayTow || rescueStatus === BOOKED || rescueStatus === ASSIGNED) && CALCULATED_ETA_UPDATE:
      return TRANSLATION_CONSTANTS.ETA_UPDATED
    default:
      break;
  }
}
