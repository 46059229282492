import {REFRESH_REDUCERS} from './../action';
import {UPDATE_PREDICTIVE_ETA} from '../action/predictiveETA-action';
const initialState = {
  predictiveETAFrom: '',
  predictiveETATo: ''
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_PREDICTIVE_ETA:
      return {...state, ...action.payload};
    case REFRESH_REDUCERS:
      return {...initialState};
    default:
      return state;
  }
}
