import React from 'react';
import {isEmpty, isEqual} from 'lodash';
import {connect} from 'react-redux';
import renderIcon from '../../utilities/render-icon';
import {
  generatePath
} from './../../utilities/RedirectUtils';
import {TimelineMax} from 'gsap';
import Icon from '../icon';
import SelectablePanel from '../selectable-panel';
import {
  refreshServiceRequestInfo,
  selectedMakeModelYear,
  submitServiceRequest,
  updatedEpcsStatus,
  updatePpuFlag,
  updateLoggedInFlag,
  updateCCardInfo,
  updateServiceTypeOptions,
  updateVehicle,
  updateVehicleInfo,
  updatePpuFuelPrice
} from '../../action';
import titleCase from '../../utils/titleCase';
import isGMPartnerCode from '../../utils/isGMPartnerCode';
import Spinner from "../spinner";
import {datadogRum} from "@datadog/browser-rum";
import {sendVehicleToJacada} from "../../jacada/sendJacadaEventData";
import {TRANSLATION_CONSTANTS} from "../../app-consts/translations";
import {LocaleContext} from "../../contexts/LocaleContext";
import {colorTranslatedText, serviceTranslatedText} from '../../utils/translatedTextUtils';
import CONSTANTS, {
  FUEL_SERVICE_ID,
  DISPLAY_TRIAGE_STEPS_STATUSES,
  TOW
} from "../../app-consts/appConstants";
import {isScheduleService} from "../../utils/serviceRequestPayloadUtils";
import { Button } from 'mesh-component-library';
import { hasPepSessionSelector } from '../../selector';
import {saveRescueDetails} from "../../api";
export class ModalScreens extends React.Component {
  static contextType = LocaleContext;
  constructor(props) {
    super(props);
    this.state = {
      yearValue: '',
      vehicleValue: '',
      vehicleColor : '',
      make: '',
      model: '',
      vehiclePanelText: '',
      vehicleMeta: '',
      fill: '#B9C6D3',
      showSpinner: false,
      fuelType: '',
      benefitsMsg:'',
      metaMsg : '',
      paymentText : '',
      addedVehicle : undefined,
      selectedVehicleIndex : undefined,
      gmModal: false,
    };

  }

  componentDidMount() {
    const getTranslatedText = this.context;
    this.setState({
      vehiclePanelText: getTranslatedText(TRANSLATION_CONSTANTS.CONFIRM_VEHICLE),
      paymentText: getTranslatedText(TRANSLATION_CONSTANTS.ADD_CONTACT)
    });
    this.caranimationtl = new TimelineMax({ paused: true });
    const {partnerCode, commonBenefits, serviceRequestPayload, partnerDetails, serviceInfo} = this.props;
    let isPep = partnerCode === "PEP";
    let vehicleInfo = '';
     if (serviceInfo.id === FUEL_SERVICE_ID && commonBenefits?.vehicles?.length === 1
         && commonBenefits.vehicles[0].color
         && commonBenefits.vehicles[0].fuelType
         && !(this.props.serviceRequestPayload.serviceRequest.vehicle || this.props.serviceRequestPayload.serviceRequest.vehicles)) {
      vehicleInfo = {...commonBenefits.vehicles[0]};
      this.props.updateServiceTypeOptions({gas: titleCase(vehicleInfo.fuelType)});
      this.props.updateVehicle(vehicleInfo);
     } else {
      vehicleInfo = serviceRequestPayload.serviceRequest.vehicle || serviceRequestPayload.serviceRequest.vehicles;
      if(serviceInfo.id === FUEL_SERVICE_ID && !serviceRequestPayload.serviceRequest.serviceTypeOptions?.gas){
        vehicleInfo = "";
      }
    }
    if(vehicleInfo) {
      this.caranimationtl.play(0, false);
      this.setState({
        vehiclePanelText: `${vehicleInfo.year} ${getTranslatedText(colorTranslatedText(titleCase(vehicleInfo.color)))} ${vehicleInfo.make} ${vehicleInfo.model}`,
      })
      sendVehicleToJacada(vehicleInfo)
      if (partnerDetails.omniStreamlined && serviceInfo.service !== 'gas') {
        saveRescueDetails(serviceRequestPayload, partnerDetails.partnerCode, serviceInfo.service)
      }
    }
    let paymentText = getTranslatedText(TRANSLATION_CONSTANTS.CONTACT_PAYMENT);

    if(isGMPartnerCode(partnerCode))  {
      paymentText = getTranslatedText(TRANSLATION_CONSTANTS.ADD_CONTACT);
    }

    this.creditcardanimationtl = new TimelineMax({ paused: true });
    this.props.refreshServiceRequestInfo();

    if (isPep || commonBenefits.benefits || partnerDetails.experience?.passThruEnabled) {
      paymentText = getTranslatedText(TRANSLATION_CONSTANTS.CONFIRM_CONTACT);
    }
    this.setState({paymentText: paymentText,metaMsg:'',benefitsMsg:''});

    const {
      creditcardanimationtl,
      caranimationtl
    } = this;

    const paymentValue = this.props.epcsStatus;

    if (paymentValue.completed && serviceRequestPayload.serviceRequest.firstName) {
      let displayName = titleCase(serviceRequestPayload.serviceRequest.firstName + " " + serviceRequestPayload.serviceRequest.lastName)
      this.setState({paymentText: displayName, metaMsg:''});
      this.creditcardMicrointeraction();
    }

    caranimationtl.to(".Car", 0.2, { opacity: 0, scaleY: 0.5, scaleX: 0.5, delay: 0.7, transformOrigin: "50% 50%" })
      .from(".Tick", 1, { opacity: 0, scaleY: 0.1, scaleX: 0.1, transformOrigin: "50% 50%", ease: "Elastic.easeOut" })
    caranimationtl.timeScale(0.8);

    creditcardanimationtl.to(".CreditCard", 0.2, { opacity: 0, scaleY: 0.5, scaleX: 0.5, delay: 0.7, transformOrigin: "50% 50%" })
      .from(".TickTwo", 1, { opacity: 0, scaleY: 0.1, scaleX: 0.1, transformOrigin: "50% 50%", ease: "Elastic.easeOut" })
    creditcardanimationtl.timeScale(0.8);
  }

   componentDidUpdate(prevProps) {
     this.redirectPage();
     this.submitPersonalInfo();

     if( this.props.partnerDetails.omniStreamlined && this.props.serviceInfo.service === 'gas'
         && (this.props.serviceRequestPayload.serviceRequest.vehicle || this.props.serviceRequestPayload.serviceRequest.vehicles)
         && this.props.serviceRequestPayload.serviceRequest.serviceTypeOptions) {
       saveRescueDetails(this.props.serviceRequestPayload, this.props.partnerDetails.partnerCode, this.props.serviceInfo.service)
     }
   }

  creditcardMicrointeraction = () => {
    this.creditcardanimationtl.play(0, false);
  }

  openVehicleModal = () => {
    if(this.props.commonBenefits.benefits) {
      this.props.history.push(generatePath('confirm-vehicle'))
      datadogRum.addAction('Vehicle Select Modal Clicked', undefined);
    } else {
      this.props.history.push(generatePath('add-vehicle'))
      datadogRum.addAction('Vehicle Information Modal Clicked', undefined);
    }
  }

  sendRequest = () => {
    if(this.props.commonBenefits?.vehicles?.find(vehicle => vehicle?.vehicleID ===
      this.props.serviceRequestPayload?.serviceRequest?.vehicles?.consumervehicleid)?.suspendIndicator === "Y") {
        this.props.updatePpuFuelPrice(0);
    }
    datadogRum.addAction('Submit Rescue Clicked');
    if(this.state.showSpinner === false) {
      this.setState({ showSpinner: true });
    }
    this.props.submitServiceRequest(this.props.serviceRequestPayload);
  }

  openPaymentModal = () => {
    this.props.history.push(generatePath('payment'));
  }

  redirectPage = () => {
    if (this.props.submitRequest.success) {
      let partnerCode = this.props.partnerCode;
      let sessionId, callId, requestId, usageId;
      if (this.props.partnerDetails.omniStreamlined) {
        sessionId = localStorage.getItem("benefitSession");
        callId = sessionId;
        usageId = localStorage.getItem("usageId");
      } else {
        sessionId = this.props.submitRequest.response.sessionId;
        callId = this.props.submitRequest.response.callId;
        requestId = this.props.submitRequest.response.requestId;
        usageId = this.props.submitRequest.response.usageId;
      }
      localStorage.setItem('requestId', requestId);
      localStorage.setItem('submitRequest', JSON.stringify(this.props.submitRequest));

      if (isScheduleService(this.props.serviceRequestPayload)) {
        this.props.history.push(`/appointment-confirmation?id=${usageId}`);
      } else if (this.props.partnerDetails.omniStreamlined) {
        this.props.history.push(`/wd/l/${partnerCode}/${sessionId}/${usageId}`);
      } else {
        this.props.history.push(generatePath(`tracker/${sessionId}/${callId}/${requestId}/${usageId}`));
      }
    }
    if (this.props.makemodel.error || this.props.submitRequest.fail) {
      let failureType;
      let response;
      if (this.props.makemodel.error) {
        failureType = "HowEmbarrassing-MakeModel"
        response = this.props.makemodel.payload;
      } else if(this.props.submitRequest.fail){
        failureType = "HowEmbarrassing-ServiceRequest"
        response = this.props.submitRequest.response;
      }
      datadogRum.addError(`Failure Type: ${failureType}, Response: ${response}`)
      this.props.history.push(generatePath('sitedown'));
    }
  }

  submitPersonalInfo = () => {
    if (!isEmpty(this.props.epcsStatus.response) && !this.props.epcsStatus.completed) {
      this.creditcardMicrointeraction();
      this.props.updatedEpcsStatus();
    }
  }

  amountDue(){
    const {
      destination,commonBenefits,serviceInfo,
      serviceRequestPayload: {serviceRequest}, hasPepSession
    } = this.props
    if(DISPLAY_TRIAGE_STEPS_STATUSES.includes(commonBenefits.status) && commonBenefits?.status?.toUpperCase() === CONSTANTS.MUST_SHOW_NO_BENEFITS_PRICING_INFO){
      return serviceInfo?.cost ? '$'+serviceInfo?.cost  : '';
    } else{
      if (hasPepSession && serviceInfo.serviceText === TOW) {
        return '$'  + destination?.towInfo?.totalTowCost;
      } else {
        const transactionAmount = serviceRequest.CCardInfo?.transactionAmount;
        return transactionAmount ? '$'+transactionAmount : '';
      }
    }
  }

  isSubmitButtonDisabled = () => {
    const {serviceRequestPayload: {serviceRequest}, partnerDetails: {experience}, serviceInfo} = this.props;
    const {ppuEnabled} = experience;
    const {
      firstName,
      lastName,
      contactNumber,
      email,
      CCardInfo,
      ppuFlag,
      serviceTypeOptions,
    } = serviceRequest;
    const vehicle = serviceRequest.vehicle || serviceRequest.vehicles;
    let personalInfo = firstName && lastName && contactNumber;
    if(ppuFlag === 'Y' && ppuEnabled){
      personalInfo = personalInfo && email && CCardInfo?.maskedCardNumber && CCardInfo?.billingZipCode;
    }

    let vehicleInfoExists = vehicle?.color && vehicle?.make && vehicle?.model && vehicle?.year;

    if(serviceInfo.id === FUEL_SERVICE_ID) {
      vehicleInfoExists = vehicleInfoExists && serviceTypeOptions?.gas;
    }

    return !vehicleInfoExists || !personalInfo
  };

  render() {
    const {
      partnerCode,
      serviceInfo,
      partnerDetails: {
        experience: {
          canDetermineFinalCost
        }
      },
    } = this.props;
    const {
      vehiclePanelText
    } = this.state;

    let isPep = partnerCode === "PEP";
    const getTranslatedText = this.context;

    return (
      <div className="vehiclepaycontainer">

        {this.state.showSpinner && <Spinner/>}

        <div className="l-grid__col">
          <div className="c-selected-service">
            <Icon className="c-selected-service__icon" icon={renderIcon(serviceInfo.serviceText)} size={1.5} color="brand" />
            <h5 className="c-selected-service__type">{getTranslatedText(serviceTranslatedText(serviceInfo.serviceText))}</h5>
            <h4 className="c-selected-service__price">{this.amountDue()} </h4>
          </div>
        </div>

        <div className="l-grid__col">
          <SelectablePanel
            text={vehiclePanelText}
            id="vehicle-info"
            className="vehiclepanel"
            name="radiooptions"
            ariaLabel={vehiclePanelText}
            value="vehiclepanel"
            type="button"
            role="link"
            serviceclass="vehicle-item-1"
            metaMessage={this.state.vehicleMeta}
            location={true}
            onClick={() => {this.openVehicleModal()}}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                this.openVehicleModal()
              }
            }}
            canDetermineFinalCost={canDetermineFinalCost}
          >
            <svg id="car-animation-svg" className="vehicle-item-2" viewBox="0 0 22 22">
              <defs />
              <g fill="none" stroke="none" strokeWidth="1">
                <g className="carAnimation">
                  <g className="Car" fill="#B9C6D3">
                    <path d="M6.17569546,7.92534786 C5.68374817,7.81795238 4.56734993,7.76242819 4.56734993,7.76242819 C4.36603221,7.75293063 4.20058565,7.91000565 4.20058565,8.11091555 L4.51098097,9.90887656 C4.51098097,10.1105171 4.67642753,10.280012 4.87847731,10.2865872 L9.58931186,10.4378176 C9.79136164,10.4436622 9.85065886,10.3216551 9.72108346,10.1667718 C9.72108346,10.1667718 8.22547584,8.3731943 6.17569546,7.92534786 L6.17569546,7.92534786 Z M14.1500732,16.5768937 C14.9641288,16.5768937 15.6259151,15.916448 15.6259151,15.1040414 C15.6259151,14.2916348 14.9641288,13.6304586 14.1500732,13.6304586 C13.3352855,13.6304586 12.6734993,14.2916348 12.6734993,15.1040414 C12.6734993,15.916448 13.3352855,16.5768937 14.1500732,16.5768937 L14.1500732,16.5768937 Z M3.261347,10.2361771 C3.46339678,10.2434829 3.62884334,10.0849467 3.62884334,9.88257563 L3.31844802,8.08242287 C3.31844802,7.88078239 3.15300146,7.72370737 2.95095168,7.73320493 C2.95095168,7.73320493 1.60834553,7.79822668 0.319180088,8.24388138 C0.161054173,8.85464749 0.0512445095,9.48294756 0,10.1295122 L3.261347,10.2361771 Z M22,10.9996347 C22,17.0751503 17.0658858,22 10.9780381,22 C6.63103953,22 2.88286969,19.4816857 1.0885798,15.8324312 L11.8960469,15.8324312 C12.2064422,16.7843788 13.0944363,17.4791618 14.1500732,17.4791618 C15.2005857,17.4791618 16.0841874,16.7916847 16.3989751,15.8477734 C17.0710102,15.8148972 18.4121523,15.6921595 18.7247438,15.2370073 C19.375549,14.2894431 19.295022,12.4133099 18.602489,11.2604523 C18.3565154,10.8513267 17.0966325,10.3917909 15.4150805,10.0396506 C13.7335286,9.68751038 11.8345534,9.57938432 11.8345534,9.57938432 C11.8345534,9.57938432 9.42020498,7.81941354 8.49780381,7.3321157 C7.57540264,6.84481785 6.41434846,6.27131139 3.15446559,6.33048849 C2.33308931,6.3458307 1.58052709,6.44811211 0.877745242,6.60372597 C2.57833089,2.71849367 6.45827233,0 10.9780381,0 C17.0658858,0 22,4.92484973 22,10.9996347 L22,10.9996347 Z" />
                  </g>
                  <g className="Tick" fill="#95D600">
                    <path d="M18.1097695,6.99743871 L9.03183315,16.075375 C8.89718258,16.2100256 8.71789243,16.2839371 8.52762532,16.2839371 L8.52469813,16.2839371 C8.33443103,16.2839371 8.15514087,16.2100256 8.0212221,16.075375 L3.83315038,11.8880351 C3.55506769,11.6092206 3.55506769,11.1577022 3.83315038,10.8788877 L4.51372119,10.1990487 C4.78302232,9.92974753 5.2542993,9.93047933 5.52286864,10.1997805 L8.52616173,13.2023417 L16.420783,5.30772045 C16.6893524,5.03915112 17.1606293,5.03915112 17.4291987,5.30772045 L18.1097695,5.98829126 C18.2444201,6.12294182 18.3183315,6.30150018 18.3183315,6.49323088 C18.3183315,6.68422978 18.2444201,6.86278814 18.1097695,6.99743871 L18.1097695,6.99743871 Z M11.0003659,0 C4.92499085,0 0,4.92499085 0,10.9996341 C0,17.0750091 4.92499085,22 11.0003659,22 C17.0750091,22 22,17.0750091 22,10.9996341 C22,4.92499085 17.0750091,0 11.0003659,0 L11.0003659,0 Z" />
                  </g>
                </g>
              </g>
            </svg>
            <Icon icon="chevron" color="brand" size={1.2} className="icon-rotation-reverse vehicle-item-3" />
          </SelectablePanel>
        </div>

        <div data-dd-privacy="mask" className="l-grid__col">
          <SelectablePanel
            text={this.state.paymentText}
            id="payment-info"
            className="paymentpanel"
            name="radiooptions"
            ariaLabel="Contact or Payment Info"
            value="paymentpanel"
            type="button"
            role="link"
            serviceclass="vehicle-item-1"
            errorMessage={this.state.benefitsMsg}
            metaMessage={this.state.metaMsg}
            location={true}
            onClick={() => { this.openPaymentModal() }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                this.openPaymentModal()
              }
            }}
            canDetermineFinalCost={canDetermineFinalCost}
          >
            {isPep || this.props.commonBenefits.benefits ?
              <svg id="creditcard-animation-svg" className="vehicle-item-2" viewBox="0 0 22 22">
                <g fill="none" stroke="none" strokeWidth="1">
                  <g className="cardAnimation">
                    <g className="CreditCard" transform='translate(-32 -612)' fill='#B9C6D3'>
                      <g id='Add-Section' className="cardAnimation" transform='translate(0 490)'>
                        <g id='Add-Payment' transform='translate(16 112)'>
                          <path d='M40,21.9995988 C40,26.0893317 37.9402895,29.7024506 34.8085988,31.8696801 L34.8085988,29.0902344 C34.8085988,26.7962288 33.9508542,24.1371402 30.275952,23.3764836 C31.6263582,22.5925579 32.5394671,21.1338304 32.5394671,19.4632744 C32.5394671,16.9678714 30.5094447,14.937849 28.0132393,14.937849 C25.5186386,14.937849 23.4878138,16.9678714 23.4878138,19.4632744 C23.4878138,21.1338304 24.4009227,22.5925579 25.7513289,23.3764836 C22.0764267,24.1371402 21.2186821,26.7962288 21.2186821,29.0902344 L21.2186821,31.8897396 C18.0701414,29.7249173 16,26.1013674 16,21.9995988 C16,15.3831701 21.3831701,10 27.9995988,10 C34.6168299,10 40,15.3831701 40,21.9995988 Z M31.7073986,19.4632744 C31.7073986,21.4997158 30.0504831,23.1566313 28.0132393,23.1566313 C25.9767978,23.1566313 24.3198823,21.4997158 24.3198823,19.4632744 C24.3198823,19.4063054 24.3263014,19.3517435 24.3287085,19.2955769 C25.0556651,19.257865 26.4654475,19.0981913 27.8631941,18.4602989 C28.2419177,18.2869847 28.5684865,18.1168801 28.8589482,17.9515897 C29.9694427,18.4298084 31.0679014,18.5357226 31.5902511,18.5573869 C31.6640701,18.8478486 31.7073986,19.150346 31.7073986,19.4632744 Z M33.9765304,29.0902344 L33.9765304,32.3904249 C32.214503,33.4078433 30.1764568,34 27.9995988,34 C25.8339741,34 23.8063589,33.4150647 22.0499482,32.4072749 L22.0499482,29.0902344 C22.0499482,25.657651 24.0013373,23.9886998 28.0132393,23.9886998 C32.0259436,23.9886998 33.9765304,25.657651 33.9765304,29.0902344 Z'
                                id='06010profileagentC' />
                        </g>
                      </g>
                    </g>
                    <g className="TickTwo" fill="#95D600">
                      <path id="06007okayC" d="M18.1097695,6.99743871 L9.03183315,16.075375 C8.89718258,16.2100256 8.71789243,16.2839371 8.52762532,16.2839371 L8.52469813,16.2839371 C8.33443103,16.2839371 8.15514087,16.2100256 8.0212221,16.075375 L3.83315038,11.8880351 C3.55506769,11.6092206 3.55506769,11.1577022 3.83315038,10.8788877 L4.51372119,10.1990487 C4.78302232,9.92974753 5.2542993,9.93047933 5.52286864,10.1997805 L8.52616173,13.2023417 L16.420783,5.30772045 C16.6893524,5.03915112 17.1606293,5.03915112 17.4291987,5.30772045 L18.1097695,5.98829126 C18.2444201,6.12294182 18.3183315,6.30150018 18.3183315,6.49323088 C18.3183315,6.68422978 18.2444201,6.86278814 18.1097695,6.99743871 L18.1097695,6.99743871 Z M11.0003659,0 C4.92499085,0 0,4.92499085 0,10.9996341 C0,17.0750091 4.92499085,22 11.0003659,22 C17.0750091,22 22,17.0750091 22,10.9996341 C22,4.92499085 17.0750091,0 11.0003659,0 L11.0003659,0 Z" />
                    </g>
                  </g>
                </g>
              </svg>

              :

              <svg id="creditcard-animation-svg" className="vehicle-item-2" viewBox="0 0 22 22">
                <defs />
                <g fill="none" stroke="none" strokeWidth="1">
                  <g className="cardAnimation">
                    <g className="CreditCard" fill="#B9C6D3">
                      <path id="05016creditcardC" d="M18.4792109,14.261424 C18.4792109,15.0870085 17.8077843,15.7577046 16.9829304,15.7577046 L5.04702444,15.7577046 C4.22217056,15.7577046 3.5514745,15.0870085 3.5514745,14.261424 L3.5514745,7.73419235 C3.5514745,6.90860786 4.22217056,6.2379118 5.04702444,6.2379118 L16.9829304,6.2379118 C17.8077843,6.2379118 18.4792109,6.90860786 18.4792109,7.73419235 L18.4792109,14.261424 L18.4792109,14.261424 Z M16.603746,10.9978082 L13.9377657,10.9978082 C13.7127391,10.9978082 13.5278959,11.1826514 13.5278959,11.407678 L13.5278959,11.9329835 C13.5278959,12.1580101 13.7127391,12.3428533 13.9377657,12.3428533 L16.603746,12.3428533 C16.8295032,12.3428533 17.0136158,12.1580101 17.0136158,11.9329835 L17.0136158,11.407678 C17.0136158,11.1826514 16.8295032,10.9978082 16.603746,10.9978082 L16.603746,10.9978082 Z M4.35075717,9.92089532 L17.6791977,9.92089532 L17.6791977,8.02424283 L4.35075717,8.02424283 L4.35075717,9.92089532 Z M11,0 C4.92501328,0 0,4.92501328 0,11 C0,17.0749867 4.92501328,22 11,22 C17.0749867,22 22,17.0749867 22,11 C22,4.92501328 17.0749867,0 11,0 L11,0 Z" />
                    </g>
                    <g className="TickTwo" fill="#95D600">
                      <path id="06007okayC" d="M18.1097695,6.99743871 L9.03183315,16.075375 C8.89718258,16.2100256 8.71789243,16.2839371 8.52762532,16.2839371 L8.52469813,16.2839371 C8.33443103,16.2839371 8.15514087,16.2100256 8.0212221,16.075375 L3.83315038,11.8880351 C3.55506769,11.6092206 3.55506769,11.1577022 3.83315038,10.8788877 L4.51372119,10.1990487 C4.78302232,9.92974753 5.2542993,9.93047933 5.52286864,10.1997805 L8.52616173,13.2023417 L16.420783,5.30772045 C16.6893524,5.03915112 17.1606293,5.03915112 17.4291987,5.30772045 L18.1097695,5.98829126 C18.2444201,6.12294182 18.3183315,6.30150018 18.3183315,6.49323088 C18.3183315,6.68422978 18.2444201,6.86278814 18.1097695,6.99743871 L18.1097695,6.99743871 Z M11.0003659,0 C4.92499085,0 0,4.92499085 0,10.9996341 C0,17.0750091 4.92499085,22 11.0003659,22 C17.0750091,22 22,17.0750091 22,10.9996341 C22,4.92499085 17.0750091,0 11.0003659,0 L11.0003659,0 Z" />
                    </g>
                  </g>
                </g>
              </svg>
            }

            <Icon icon="chevron" color="brand" size={1.2} className="icon-rotation-reverse vehicle-item-3" />
          </SelectablePanel>
          <br/>
          <Button
            size='lg'
            hasUpgrade
            id="submit-request"
            disabled={this.isSubmitButtonDisabled()}
            onClick={this.sendRequest}
            utils={{
              fullWidth: true
            }}>
            {getTranslatedText(TRANSLATION_CONSTANTS.SUBMIT_REQUEST)}
          </Button>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    commonBenefits: state.commonBenefits,
    serviceRequestPayload: state.serviceRequestPayload,
    serviceInfo: state.serviceInfo,
    vehicleValue: state.vehicleValue,
    destination: state.destination,
    makemodel: state.makemodel,
    submitRequest: state.submitRequest,
    partnerDetails: state.partnerDetails.partnerDetails,
    epcsStatus: state.epcsStatus,
    hasPepSession: hasPepSessionSelector(state),
  };
}

export default connect(mapStateToProps, {
  updateVehicleInfo,
  submitServiceRequest,
  selectedMakeModelYear,
  updatedEpcsStatus,
  refreshServiceRequestInfo,
  updatePpuFlag,
  updateLoggedInFlag,
  updateCCardInfo,
  updateVehicle,
  updateServiceTypeOptions,
  updatePpuFuelPrice
})(ModalScreens);
