import React, {Component} from "react";
import { Button, Grid } from "mesh-component-library";
import {AutoC, ClocktimeF, CreditcardC} from "../../icon-library";
import TrackStatus from "../../components/svg/TrackStatus";
import map from "lodash/map";
import filter from "lodash/filter";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import renderIcon from "../../utilities/render-icon";
import Icon from "../../components/icon";
import Lyft from "../../components/lyft";
import ProviderInfo from "../../components/providerinfo";
import {connect} from "react-redux";
import {
  dispatchProviderInfo,
  dispatchServiceRequestInfo,
  refreshReducersToIntial,
} from "../../action";
import TrackerMap from "../../components/tracker-map";
import {
  INITIAL,
  softServiceSteps,
  STATUS_FOR_HIDE_CANCEL,
  towSteps,
  updatePredictiveMessagingConstant,
} from "./status-data";
import {CancellationModal} from "../../components/cancellationmodal";
import {generatePath, redirectNavigationUrl} from "./../../utilities/RedirectUtils";
import {GoneOnArrival} from "../../components/gone-on-arrival";
import SlideArrow from "../../components/svg/SlideArrow";
import {sendJacadaRequest} from '../../api';
import CONSTANTS, {
  APPROACHING,
  ARRIVED_ONSITE,
  BOOKED,
  CANCELLED,
  COMPLETED,
  DROP_LOCATION,
  DROP_SIGN,
  EN_ROUTE,
  EN_ROUTE_DROP,
  FUEL_DELIVERY,
  GONE_ON_ARRIVAL,
  LOADED,
  NEW_JOB,
  NO,
  ONSITE,
  TOW,
  YES,
  DISPLAY_TRIAGE_STEPS_STATUSES,
  CSR_TAKEOVER,
  PEP,
  AMAZON_CONNNECT_CHAT_USER_TYPE,
} from "../../app-consts/appConstants";
import titleCase from "../../utils/titleCase";
import {INTAKE_COMPLETED} from "../../jacada/selectors";
import {OvercostWarning} from "../../components/warning-alerts";
import {datadogRum} from "@datadog/browser-rum";
import {getRescueTrackerStatus} from "../../api/api";
import { LocaleConsumer } from "../../contexts/LocaleContext";
import {TRANSLATION_CONSTANTS} from "../../app-consts/translations";
import { colorTranslatedText, getTextByUnits, serviceTranslatedText } from "../../utils/translatedTextUtils";
import ShareButton from '../../components/sharebutton';
import { RenderShareModal, createShareShortUrl } from "../RescueTracker";
import { getUpdatedClock } from "../../utils/rescueTrackerUtil";

import { disablementLocationTextByService } from "../../utils/rescueTrackerUtil";
import RentalExchange from "../../components/rental-exchange";
import { GiftIcon, PersonIcon } from "mesh-icon-library";
import { Icon as MeshIcon, Slab, Type } from "mesh-component-library";
import CreditCardOffer from "../../components/credit-card-offer";
import { EN_US, formatTimeAsString, formatTimeToLowerCasesWithPeriods, HOUR12 } from "../../utils/timeUtil";
import { getAmazonConnectSelector, hasPepSessionSelector } from "../../selector";
import { getVaChat, injectAmazonConnect } from "../../utils/amazonConnect";

class Tracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: NEW_JOB,
      collapseRequest: false,
      textHref: "",
      openShareModal: false,
      amazonConnectScriptLoaded: false,
    };
    this.cancelRescue = this.cancelRescue.bind(this);
    this.showCancelModal = this.showCancelModal.bind(this);
    this.hideCancelModal = this.hideCancelModal.bind(this);
    this.openLyft = this.openLyft.bind(this);
    this.isOverCost = false;
    this.partnerCode = this.props.match.params.partnercode;
  }

  async componentDidMount() {
    document.title = "Tracker | Roadside Assistance";
    window.scrollTo(1, 1);

    const { sessionId, callId, requestId, partnercode } = this.props.match.params;
    let { usageId } = this.props.match.params;
    if (usageId === 'null' || usageId === 'undefined' ) {
      datadogRum.addAction("UsageId Not Available From Submission Response")
      const rsvResponse = await getRescueTrackerStatus(partnercode, callId, 'callid')
      usageId = rsvResponse?.usageId
    }

    sendJacadaRequest({
      'externalSystemId': localStorage.getItem('conversationId'),
      'events': [{
        'name': 'call_id',
        'value': callId
      }]
    });
    const request = {requestId,sessionId};
    this.props.dispatchServiceRequestInfo(request);
    this.interval = setInterval(() => this.props.dispatchServiceRequestInfo(request), 30*1000);

    if(this.props.commonBenefits.benefits) {
      this.props.dispatchProviderInfo(request);
      this.providerInterval = setInterval(() => this.props.dispatchProviderInfo(request), 30*1000);
    }
    const { amazonConnect }= this.props
    injectAmazonConnect(amazonConnect, ({loaded = false}) => {
      if(loaded) {
        this.setState({amazonConnectScriptLoaded: true});
      }
    })
    datadogRum.addAction("Tracker component mounts")
  }

  componentDidUpdate(prevProps, prevState) {
    const { amazonConnectScriptLoaded = false } = this.state;
    if(prevState.amazonConnectScriptLoaded !== amazonConnectScriptLoaded && amazonConnectScriptLoaded) {
      const { serviceRequestPayload: { serviceRequest }, amazonConnect }= this.props
      const { callId, usageId } = this.props.match.params;
      getVaChat().set({
        attributes: {
          userType: AMAZON_CONNNECT_CHAT_USER_TYPE,
          usageId: usageId,
          va_botname: amazonConnect?.botName,
          firstName: serviceRequest?.firstName,
          lastName: serviceRequest?.lastName,
          appointmentNumber: `${callId}`
        },
      });
      getVaChat().initializeChat();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.providerInterval);
    this.isOverCost = false;
  }

  cancelRescue() {
    this.showCancelModal();
  }

  removeSpecialChar(transcationAmount) {
    return transcationAmount.replace("$", "");
  }

  showCancelModal() {
    this.setState({cancelModal: true});
  }

  hideCancelModal() {
    this.setState({cancelModal: false});
  }

  redirectPage = () => {
    if (this.props.serviceRequestInfo.response && this.props.serviceRequestInfo.response.serviceRequest) {
      const rescueRequest = this.props.serviceRequestInfo.response.serviceRequest;
      let date1 = new Date().getTime();
      let creationTime = rescueRequest.serviceCreationTime;
      let date2 = new Date(creationTime).getTime();
      let difference = date1 - date2;
      let hoursDifference = Math.floor(difference / 1000 / 60 / 60);
      if (rescueRequest.status === COMPLETED) {
        localStorage.setItem("isRequestCompleted", "true");
        this.props.history.push(generatePath("feedback"));
      }
      if (hoursDifference >= 4 || rescueRequest.status === CANCELLED) {
        if(rescueRequest.status === CANCELLED) {
          localStorage.setItem('rescueCancel', 'YES');
        } else {
          localStorage.clear();
        }
        clearInterval(this.interval);
        this.props.refreshReducersToIntial();
        let url = redirectNavigationUrl(this.props.partnerDetails.partnerDetails);
        this.props.history.push(generatePath(url));
      }
    }
  };

  openLyft() {
    window.open("https://lyft.com/ride?id=lyft&partner=ODC8ns6F-IQI");
  }

  toggleRequestSection = () => {
    const {collapseRequest} = this.state;
    if (collapseRequest) {
      this.setState({collapseRequest: false});
    } else {
      this.setState({collapseRequest: true});
    }
  };

  renderRequestSummarySection(getTranslatedText) {
    const {
      partnerDetails: {
        partnerDetails: {
          experience
          }
        },
      serviceRequestPayload: {
        serviceRequest
      },
      commonBenefits,
      serviceInfo,
      submitRequest,
      currentLocation,
      destination: {
        towInfo,
        name,
        formatted_address,
        vicinity
      },
      ppuFuelPrice,
      hasPepSession
    } = this.props;

    let coveredMilesLimit;
    let costPerAdditionalMile
    if (serviceInfo.value === TOW) {
      let services = commonBenefits?.benefits ? commonBenefits.services : experience?.serviceCost?.serviceCosts
      let towServiceInfo =  services?.find(service => service.type === 'Tow' || service.serviceType === 'Tow')
      coveredMilesLimit = towServiceInfo?.towIncludedQty || parseInt(towServiceInfo?.towIncludedDistanceQty);
      costPerAdditionalMile = towServiceInfo?.towExtraUnitAmt || towServiceInfo?.costPerMile;
    }

    const vehicle = serviceRequest.vehicle || serviceRequest.vehicles
    const maskedNumber = serviceRequest.CCardInfo?.maskedCardNumber;
    let destinationAddress = formatted_address || vicinity
    const formatContactNumber = contactNum => contactNum.slice(0, 3) + "-" + contactNum.slice(3, 6) + "-" + contactNum.slice(6, 15);

    return (
      <div className="l-confirmation-container">
        <div className="l-container-location request-section">
          <div
            role="link"
            tabIndex="0"
            className="request-section-header"
            onClick={() => this.toggleRequestSection()}
            onKeyDown={() => this.toggleRequestSection()}
          >
            <div>
              <h4 id={INTAKE_COMPLETED} className="title">{getTranslatedText(TRANSLATION_CONSTANTS.REQUEST_SUMMARY)}</h4>
              <h5 id="reference-number" className="c-current-location__tow">
              {getTranslatedText(TRANSLATION_CONSTANTS.REFERENCE_NUMBER)}: {submitRequest.response.callId}{" "}
              </h5>
            </div>
            <SlideArrow
              direction="up"
              color="blue"
              size="large"
              rotate={`${this.state.collapseRequest ? "" : "rotate"}`}
            />
          </div>
          <div
            data-testid="requestSummaryDetails"
            hidden={this.state.collapseRequest}
            ref={(divElement) => (this.divElement = divElement)}
          >
            <div className={`${serviceInfo.value === TOW ? 'c-current-location--tow ' : ''} c-current-location c-rt-tow-margin`}>
              <Icon className="c-current-location__icon"
                    icon={renderIcon(serviceInfo.value)} color="brand"
                    size={1.5}/>
              <h4 className="c-current-location__tow">{getTranslatedText(serviceTranslatedText(serviceInfo.value))}</h4>
              <h6 className="c-current-location__title">{getTranslatedText(disablementLocationTextByService(serviceInfo.value))}</h6>
              <p
                className="c-current-location__address">{currentLocation.formatted_address}</p>
              {serviceInfo.value === TOW &&
              <h6 className="c-current-location__dropoff-title"> {getTranslatedText(TRANSLATION_CONSTANTS.DESTINATION_LOCATION)} </h6>
              }
              {!experience?.towToEnabled &&
              <React.Fragment>
                <p className="c-current-location__dropoff-shop-name">{serviceInfo.value === TOW && name !== formatted_address?.split(',')[0] ? name : formatted_address}</p>
                <p className="c-current-location__dropoff-address">{serviceInfo.value === TOW && name !== formatted_address?.split(',')[0] ? formatted_address : ""}</p>
              </React.Fragment>
              }
              {experience?.towToEnabled && serviceInfo.value === TOW &&
              <React.Fragment>
                <p className="c-current-location__dropoff-shop-name">{titleCase(name)}</p>
                <p className="c-current-location__dropoff-address">{destinationAddress}</p>
              </React.Fragment>
              }
            </div>
            <div className="c-container-vehicle c-rt-margin">
              <Icon icon={AutoC} color="brand" size={1.5}/>
              <h4 className="c-container-vehicle__title"> {getTranslatedText(TRANSLATION_CONSTANTS.VEHICLE)}  </h4>
              <h6
                className="c-container-vehicle__values u-text-capitalize">{`${vehicle?.year} ${getTranslatedText(colorTranslatedText(vehicle?.color))} ${vehicle?.make} ${vehicle?.model} `}</h6>
            </div>
            <div className="c-contact-payment__payment-info c-rt-margin">
              <div className="u-flex u-flex--middle u-flex--start">
                <div className="icon-person u-hr-2">
                  <MeshIcon icon={PersonIcon} color="#FFF" size={1.5} />
                </div>
                <h4 className="c-contact-payment__title">{getTranslatedText(TRANSLATION_CONSTANTS.CONTACT)}</h4>
              </div>
              {serviceRequest?.firstName &&
                <div className="u-flex u-flex--top u-flex--between u-hr-p-left">
                  <p className="c-contact-payment__benefits-text mbottom">
                    {titleCase(serviceRequest.firstName + ' ' + (serviceRequest.lastName ?? ''))}
                  </p>
                </div>
              }
              {serviceRequest?.email &&
                <div className="u-flex u-flex--top u-flex--between u-hr-p-left">
                  <p className="c-contact-payment__benefits-text mbottom">{serviceRequest.email.toLowerCase()}</p>
                </div>
              }
              {serviceRequest?.contactNumber &&
                <div className="u-flex u-flex--top u-flex--between u-hr-p-left">
                  <p className="c-contact-payment__benefits-text mbottom">{formatContactNumber(serviceRequest.contactNumber)}</p>
                </div>
              }
            </div>
            <div className="c-contact-payment__payment-info c-rt-margin">
              <div className="u-flex u-flex--middle u-flex--start">
                <Icon className="u-hr-2" icon={CreditcardC} color="brand" size={1.5}/>
                <h4 className="c-contact-payment__title"> {getTranslatedText(TRANSLATION_CONSTANTS.PAYMENT)} </h4>
              </div>
              <div className="c-contact-payment__line">
                {maskedNumber &&
                <div className="u-flex u-flex--top u-flex--between u-hr-p-left">
                  <h6
                    className="c-contact-payment__card-number">{maskedNumber}</h6>
                </div>
                }
                {!(DISPLAY_TRIAGE_STEPS_STATUSES.includes(commonBenefits.status)) && (<>
                {(serviceRequest.ppuFlag === YES || hasPepSession)&&
                <React.Fragment>
                  <div className="u-flex u-flex--top u-flex--between u-hr-p-left">
                    {serviceInfo.value === TOW ?
                      <p className="c-contact-payment__base-miles">{coveredMilesLimit} {getTextByUnits(towInfo?.distanceUnits, TRANSLATION_CONSTANTS.MILES_INCLUDED, TRANSLATION_CONSTANTS.KILOMETERS_INCLUDED)}</p>
                      :
                      <p className="c-contact-payment__base-miles">{getTranslatedText(serviceTranslatedText(serviceInfo.value))}</p>
                    }
                    <p className="c-contact-payment__price">{serviceRequest.loggedIn === YES && !hasPepSession ? "$0.00" : '$'+serviceInfo.originalCost}</p>
                  </div>
                  <div>
                    {serviceInfo.value === TOW && towInfo?.overMileageDistance > 0 &&
                      <React.Fragment>
                        <div className="u-flex u-flex--top u-flex--between u-hr-p-left">
                          <p className="c-contact-payment__extra-miles ">
                            {towInfo.overMileageDistance} {getTextByUnits(towInfo?.distanceUnits, TRANSLATION_CONSTANTS.ADDITIONAL_MILES, TRANSLATION_CONSTANTS.ADDITIONAL_KILOMETERS)}
                          </p>
                          <p className="c-contact-payment__extra-price">
                            ${serviceRequest.loggedIn === YES && !hasPepSession ? serviceRequest.CCardInfo?.transactionAmount : towInfo?.overMileageCost}
                          </p>
                        </div>
                        <div className="u-flex u-flex--top u-flex--between u-hr-p-left u-align-right">
                          <p className="overmileage-cost rightAlign">
                            + ${costPerAdditionalMile}/{getTextByUnits(towInfo?.distanceUnits, TRANSLATION_CONSTANTS.MILE, TRANSLATION_CONSTANTS.KILOMETER).toLowerCase()} {getTranslatedText(TRANSLATION_CONSTANTS.OVER)} {coveredMilesLimit} {getTextByUnits(towInfo?.distanceUnits, TRANSLATION_CONSTANTS.MILES, TRANSLATION_CONSTANTS.KILOMETERS).toLowerCase()}
                          </p>
                        </div>
                      </React.Fragment>
                      }
                  </div>
                </React.Fragment>
                }
                {serviceInfo.value === FUEL_DELIVERY && ppuFuelPrice !== 0 &&
                <div className="u-flex u-flex--top u-flex--between u-hr-p-left">
                  <p className="c-contact-payment__extra-miles">
                    Fuel Charge
                  </p>
                  <p className="c-contact-payment__extra-price">
                    ${ppuFuelPrice}
                  </p>
                </div>
                }
                {((commonBenefits.benefits && serviceRequest.ppuFlag === NO) || (experience?.passThruEnabled)) &&
                <div className="u-flex u-flex--top u-flex--between u-hr-p-left">
                  <p className="c-contact-payment__benefits-text">{getTranslatedText(TRANSLATION_CONSTANTS.COVERED_BY_BENEFITS)}</p>
                </div>
                }
                {(!experience?.milesBased && !experience?.canDetermineFinalCost && commonBenefits.benefits && serviceInfo.value === TOW) &&
                <div className="u-flex u-flex--top u-flex--between u-hr-p-left">
                  <p className="memberTowNote">{getTranslatedText(TRANSLATION_CONSTANTS.FINAL_COST_WILL_BE_DETERMINED)}</p>
                </div>
                }
                {serviceRequest.ppuFlag === YES &&
                <div>
                  <div className="c-divider c-divider--wide"/>
                  <div className="u-flex u-flex--top u-flex--between u-hr-p-left u-vr-top">
                    <p className="c-contact-payment__total">{getTranslatedText(TRANSLATION_CONSTANTS.TOTAL)}</p>
                    <p className="c-contact-payment__total-price">${serviceRequest.CCardInfo?.transactionAmount}</p>
                  </div>
                </div>
                }
                {hasPepSession && serviceInfo.serviceText === TOW &&
                <div>
                  <div className="c-divider c-divider--wide"/>
                  <div className="u-flex u-flex--top u-flex--between u-hr-p-left u-vr-top">
                    <p className="c-contact-payment__total">{getTranslatedText(TRANSLATION_CONSTANTS.ESTIMATED_AMOUNT_DUE)}</p>
                    <p className="c-contact-payment__total-price">${towInfo?.totalTowCost}</p>
                  </div>
                </div>
                }</>)}
                {(DISPLAY_TRIAGE_STEPS_STATUSES.includes(commonBenefits.status)) && (<>
                  {(commonBenefits?.status?.toUpperCase() !== CONSTANTS.MUST_SHOW_NO_BENEFITS_PRICING_INFO) &&
                    <div className="u-flex u-flex--top u-flex--between u-hr-p-left">
                      <p className="c-contact-payment__benefits-text">{getTranslatedText(TRANSLATION_CONSTANTS.COVERED_BY_BENEFITS)}</p>
                    </div>
                  }
                  {(commonBenefits?.status?.toUpperCase() === CONSTANTS.MUST_SHOW_NO_BENEFITS_PRICING_INFO) &&
                    <div>
                      <div className="c-divider c-divider--wide" />
                      <div className="u-flex u-flex--top u-flex--between u-hr-p-left u-vr-top">
                        <p className="c-contact-payment__total">{getTranslatedText(TRANSLATION_CONSTANTS.ESTIMATED_MINIMUM_DUE)}</p>
                        <p className="c-contact-payment__total-price">{serviceInfo?.cost ? '$' + serviceInfo?.cost : ''}</p>
                      </div>
                    </div>
                  }
                </>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCancelRescueSection(getTranslatedText) {
    const {serviceRequestInfo} = this.props;
    return (
      !STATUS_FOR_HIDE_CANCEL.includes(serviceRequestInfo.response?.serviceRequest?.status) &&
            <Grid.Column
              col={{ sm: 10, md: 2, lg: 2, xl: 2 }}
              offset={{ sm: 1, md: 5, lg: 5, xl: 5 }}>
              <Button
                hasUpgrade
                isPrimary={false}
                size='lg'
                onClick={() => this.cancelRescue()}
                data-testid="cancelRescueButton"
                utils={{
                  fullWidth: true
                }}>
                {getTranslatedText(TRANSLATION_CONSTANTS.CANCEL_RESCUE)}
              </Button>
            </Grid.Column>);
  }

  displayETA(getTranslatedText) {
    const {serviceRequestInfo} = this.props;
    let eta, etaTime;
    if (serviceRequestInfo.response.serviceRequest.projectedETATime) {
      const projectedEtaTimeStrictTime = serviceRequestInfo.response.serviceRequest.projectedETATime?.replace(" ", "T");
      const eta = formatTimeAsString(new Date(projectedEtaTimeStrictTime), EN_US, HOUR12);
      etaTime = formatTimeToLowerCasesWithPeriods(eta);
    }
    const invalidDate = etaTime === 'Invalid Date';
    if (invalidDate) {
      datadogRum.addError(new Error("Invalid ETA Encountered"), serviceRequestInfo.response.serviceRequest, undefined);
    }
    const time = invalidDate ? "" : etaTime;
    const projectedEtaMinutes = serviceRequestInfo.response?.serviceRequest?.projectedETAMinute;
    eta = getTranslatedText(TRANSLATION_CONSTANTS.ETA) + ": " + time + " | " + projectedEtaMinutes + ` min${projectedEtaMinutes > 1 ? 's' : ''}`;
    return eta;
  }

  trackRescueSectionAndLyft(status) {
    const {serviceRequestInfo, serviceInfo,partnerDetails: {
      partnerDetails: {
        experience
      }
    }} = this.props;
    let trackeringSteps =
      serviceInfo.serviceText === "Tow" ? towSteps : softServiceSteps; //taking flow based on tow/soft service
    let trackerSteps = map(trackeringSteps, function (o) {
      return {...o, status: `notcomplete`};
    }); //reseting status to not complete
    const trackerLen = trackerSteps.length;
    let result = [];
    //matching service status with the incoming service status
    if (
      serviceRequestInfo.response &&
      serviceRequestInfo.response.serviceRequest
    ) {
      result = filter(trackerSteps, function (ele) {
        return includes(
          ele.servicestatus,
          status
        )
          ? ele
          : "";
      });
    }
    //if match happen loop through the steps and make status as complete and make matching status as inprogress and break the loop.
    if (!isEmpty(result)) {
      for (var i = 0; i < trackerSteps.length; i++) {
        if (
          includes(
            trackerSteps[i].servicestatus,
            serviceRequestInfo.response.serviceRequest.status
          )
        ) {
          trackerSteps[i].status = "inprogress";
          break;
        } else {
          trackerSteps[i].status = "complete";
        }
      }
    }

    const inProgressStep = trackerSteps.filter(step => step.status === 'inprogress').pop();
    let etaMinutes;
    const etaType = serviceRequestInfo.response?.serviceRequest?.projectedETAType;
    if (etaType && etaType.toUpperCase() !== INITIAL && status !== ARRIVED_ONSITE && status !== LOADED && status !== DROP_LOCATION && status !== DROP_SIGN && status !== EN_ROUTE_DROP)
    {
      etaMinutes =serviceRequestInfo.response.serviceRequest.projectedETAMinute;
      etaMinutes = etaMinutes === 1 ? `${etaMinutes} minute` : `${etaMinutes} minutes`;
      const descUpdateResult = updatePredictiveMessagingConstant(etaType);
      if(descUpdateResult && inProgressStep) {
        inProgressStep.desc = descUpdateResult;
      }
    }
    let home = {
      pos: {
        lat: "",
        lng: "",
      },
    };

    let truckLo = {
      pos: {
        lat: "",
        lng: "",
      },
    };

    let destinationLocation = {
      lat: "",
      lng: "",
    };

    if (
      serviceRequestInfo.response &&
      serviceRequestInfo.response.provider &&
      serviceRequestInfo.response.provider &&
      serviceRequestInfo.response.provider.id
    ) {
      localStorage.setItem(
        "providerId",
        serviceRequestInfo.response.provider.id
      );
    }
    if (
      serviceRequestInfo.response &&
      serviceRequestInfo.response.driver &&
      serviceRequestInfo.response.driver.latitude &&
      serviceRequestInfo.response.driver.longitude
    ) {
      truckLo.pos.lat = serviceRequestInfo.response.driver.latitude;
      truckLo.pos.lng = serviceRequestInfo.response.driver.longitude;
      home.pos.lat = serviceRequestInfo.response.serviceRequest.incidentAddress.latitude;
      home.pos.lng = serviceRequestInfo.response.serviceRequest.incidentAddress.longitude;
      if (serviceRequestInfo.response.serviceRequest.destinationAddress) {
        destinationLocation.lat = serviceRequestInfo.response.serviceRequest.destinationAddress.latitude;
        destinationLocation.lng = serviceRequestInfo.response.serviceRequest.destinationAddress.longitude;
      }
    }

    const renderTrackRescueView = (getTranslatedText) => {
      const status = serviceRequestInfo.response.serviceRequest?.status;

      return (
        <div className="l-tracker-container">
          <h4 className="c-title" id="tracking-your-rescue">{getTranslatedText(TRANSLATION_CONSTANTS.TRACKING_YOUR_RESCUE)}</h4>
          <div className="c-clock">
            <p className="c-clockTime">{getUpdatedClock(getTranslatedText(TRANSLATION_CONSTANTS.CLOCK_UPDATED))}</p>
          </div>
          <div className="l-tracker-section">
            <React.Fragment>
              { inProgressStep &&
                <div className="c-eta-steps">
                  <span>{getTranslatedText(TRANSLATION_CONSTANTS.RESCUE_STEP)}: </span>
                  <span
                    data-testid="stepsComplete"> {`${i + 1} of ${trackerLen}`}
                  </span>
                </div>
              }
              <div className="c-eta">
              {serviceRequestInfo.response.serviceRequest &&
              (serviceRequestInfo.response.serviceRequest.projectedETAMinute
                || status === NEW_JOB
                || status === CSR_TAKEOVER
              ) ? (
                <Icon icon={ClocktimeF} color="gray-dark" size={1}/>
              ) : null}
                {serviceRequestInfo.response.serviceRequest ?
                  (serviceRequestInfo.response.serviceRequest.projectedETAMinute?
                    (<p className="c-waitTime">{this.displayETA(getTranslatedText)}</p>) :
                      (status === NEW_JOB || status === CSR_TAKEOVER ?
                        (<p className="c-waitTime"> {getTranslatedText(TRANSLATION_CONSTANTS.ETA_CALCULATING)} </p>)
                        : null))
                  : null
                }
              </div>
              <div className="c-trackerlist c-trackerlist__container">
                {map(trackerSteps, (trackerStep, i) => {
                  let lastClass = false;
                  if (trackerLen === i + 1) {
                    lastClass = true;
                  }
                  return (
                    <div key={i} className="c-trackerlist__trackerStep">
                      <div
                        className={`c-trackerlist__item ${trackerStep.status}`}
                      >
                        <div
                          id={`track-status-icon-${i}`}
                          className="c-trackerlist__icon"
                        >
                          <TrackStatus
                            id={`track-status-${i}`}
                            number={i + 1}
                            status={trackerStep.status}
                            lastItem={lastClass}
                          />
                        </div>
                      </div>
                      <div
                        className={((trackerStep.status === 'complete' || trackerStep.status === 'notcomplete') &&
                          serviceRequestInfo?.response?.serviceRequest?.status !== COMPLETED) ? 'c-complete-text' : 'c-inprogress-text'}>
                          {getTranslatedText(trackerStep.shortTitle)}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="c-divider"/>
              <div className="c-trackerlist">
                {inProgressStep &&
                <div
                  id='track-status-text'
                  className='c-trackerlist__text completed'
                >
                  <p data-testid="stepTitle"
                     className="c-trackerlist__text__title">
                     {getTranslatedText(inProgressStep.shortTitle)}
                  </p>
                  <p data-testid="stepDesc" className="c-trackerlist__text__description">
                    {getTranslatedText(inProgressStep.desc).replace(/{(\w*)}/g, etaMinutes)}
                  </p>
                </div>
                }
              </div>
            </React.Fragment>

            {serviceRequestInfo.response.provider &&
            serviceRequestInfo.response.provider.companyName ? (
              <div>
                <ProviderInfo
                  contactNumber={serviceRequestInfo.response.provider.contactNumber}
                  driverName={serviceRequestInfo.response.driver?.name}
                  companyName={serviceRequestInfo.response.provider.companyName}/>
              </div>
            ) : (
              ""
            )}

            {serviceRequestInfo.response.driver &&
            Number(serviceRequestInfo.response.driver.latitude) &&
            Number(serviceRequestInfo.response.driver.longitude) ? (
              <div>
                <div className="c-divider"/>
                <TrackerMap
                  myLocation={home.pos} //Testing positions - Current Location from redux to go here.
                  towTruckLocation={truckLo.pos} //Testing positions - Tow Truck co-ords to go here.
                  destinationLocation={destinationLocation}
                  mapOptions={{
                    class: "tracker",
                    zoom: 4,
                    streetViewControl: false,
                    scrollwheel: false,
                    draggable: true,
                    mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                    mapTypeControl: false,
                    zoomControl: false,
                    fullscreenControl: false,
                    center: {
                      lat: 39.82835,
                      lng: -98.5816684,
                    },
                    streetViewControlOptions: {
                      position: window.google.maps.ControlPosition.RIGHT_CENTER,
                    },
                    styles: [
                      {
                        featureType: "poi",
                        stylers: [{visibility: "off"}],
                      },
                    ],
                  }}
                />
              </div>
            ) : (
              ""
            )}

            {inProgressStep ?
              <>
                <div className="share-rescue-block l-grid l-grid__col">
                  <div className="l-grid__col l-grid__col--9">
                    <p className={'share-rescue-text'}>{getTranslatedText(TRANSLATION_CONSTANTS.FAMILY_AND_FRIENDS)}</p>
                  </div>
                  <div className="l-grid__col l-grid__col--3">
                    <ShareButton shareRescueOnClick={shareRescueOnClick}/>
                  </div>
                </div>
              </>
              : null}

          </div>
          {this.renderCancelRescueSection(getTranslatedText)}
        </div>
      );
    }

    const shareRescueOnClick = async () => {
      const href = await buildTextHref();
      datadogRum.addAction(`WLE RT share with family and friends with url: ${href}`)
      this.setState({textHref: href, openShareModal: true});
    }

    const buildTextHref = async () => {
      this.setState({openShareModal: false});
      const deviceAgent = navigator.userAgent.toLowerCase();
      const isIOS =  /(iphone|ipod|ipad)/.test(deviceAgent);
      const paramOperator = (isIOS) ? "&" : "?";
      let preFix = `sms:${paramOperator}body=Track My Rescue Now `;
      const friendsAndFamilyShareUrl = await createShareShortUrl(this.props.match.params.usageId,
        this.partnerCode, this.props.match.params.sessionId, "WLE", (this.props.match.params.language || new URLSearchParams(this.props.location.search).get("language")));
      return preFix + friendsAndFamilyShareUrl;
    };

    const renderLyftView = (getTranslatedText) => {
      return inProgressStep && experience?.showLyftSection &&
        <div className="l-tracker-container">
          <div className="c-lyftSection">
            <div className="c-lyftSection--content">
              <div className="c-lyftSection--title">
                <h3>{getTranslatedText(TRANSLATION_CONSTANTS.SCHEDULE_A_RIDE)}</h3>
              </div>
              <div className="c-lyftSection--main">
                <p>{getTranslatedText(TRANSLATION_CONSTANTS.HAVE_LYFT_READY)}</p>
              </div>
            </div>

            <div className="c-shareSection--button">
              <Lyft handleClick={this.openLyft}/>
            </div>
          </div>
        </div>
    }

    return {
      renderTrackRescueView,
      renderLyftView
    }
  }

  render() {
    this.redirectPage();
    const {
      providerInfo,
      serviceRequestInfo,
      partnerDetails: {
        partnerDetails: {
          experience
        }
      },
      commonBenefits,
      serviceRequestPayload,
      partnerDetails
    } = this.props;

    const {sessionId, requestId} = this.props.match.params;

    const status = serviceRequestInfo?.response?.serviceRequest?.status
    if (
      providerInfo &&
      providerInfo.response &&
      (providerInfo.response.status === BOOKED ||
        providerInfo.response.status === EN_ROUTE ||
        providerInfo.response.status === APPROACHING ||
        providerInfo.response.status === ONSITE ||
        providerInfo.response.status === LOADED ||
        providerInfo.response.status === DROP_LOCATION ||
        providerInfo.response.status === COMPLETED)
    ) {
      if (
        providerInfo.response.overCostAmount &&
        providerInfo.response.overCostAmount > 0
      ) {
        this.overCostAmount = parseFloat(
          providerInfo.response.overCostAmount
        ).toFixed(2);
        this.isOverCost = true;
      }
      clearInterval(this.providerInterval);
    }

    const showOvercostWarning = !experience?.canDetermineFinalCost && commonBenefits.benefits && this.isOverCost;
    const { renderTrackRescueView, renderLyftView } = this.trackRescueSectionAndLyft(status);

    return (
      <LocaleConsumer>
      {(getTranslatedText) => (
        <>
          <RenderShareModal
            openShareModal={this.state.openShareModal}
            setOpenShareModal={() => this.setState({ openShareModal: false })}
            closeModal={() => this.setState({ openShareModal: false })}
            textHref={this.state.textHref}
            getTranslatedText={getTranslatedText}
            usageId={this.props.match.params.usageId}
            partnerCode={this.partnerCode}
            sessionId={this.props.match.params.sessionId}
            rtName="WLE"
            language={this.props.match.params.language || new URLSearchParams(this.props.location.search).get("language")}
          />
          <div role="main" className="l-container">
            {this.state.cancelModal &&
              <CancellationModal
                requestId={requestId}
                sessionId={sessionId}
                ppuFlag={serviceRequestPayload.serviceRequest.ppuFlag}
                refreshReducersToInitial={this.props.refreshReducersToIntial}
                partnerCode={partnerDetails.partnerDetails.displayCode}
                appId={partnerDetails.partnerDetails.appId}
                history={this.props.history}
                hideCancelModal={this.hideCancelModal}
                cancellationSource={"WLE"}
              />
            }
            {showOvercostWarning && <OvercostWarning amount={this.overCostAmount}/>}
            {status === GONE_ON_ARRIVAL ? <GoneOnArrival/> : renderTrackRescueView(getTranslatedText)}
            <Grid.Column
              col={{ sm: 10, md: 2, lg: 2, xl: 2 }}
              offset={{ sm: 1, md: 5, lg: 5, xl: 5 }}>
              <Button
                hasUpgrade
                size='lg'
                id="ars_chat"
                onClick={() => {
                  if(this.state.amazonConnectScriptLoaded) getVaChat().open();
                }}
                isLoading={!this.state.amazonConnectScriptLoaded}
                data-dd-privacy="mask"
                utils={{
                  fullWidth: true
                }}>
                {getTranslatedText(TRANSLATION_CONSTANTS.LIVE_CHAT)}
              </Button>
            </Grid.Column>
            {this.renderRequestSummarySection(getTranslatedText)}
            {status !== GONE_ON_ARRIVAL && renderLyftView(getTranslatedText)}
            {partnerDetails.partnerDetails.displayCode === PEP && this.props.serviceInfo.value !== TOW &&
              (<div className="l-confirmation-container l-confirmation-container--couponOffer">
                <Slab
                  size="sm"
                  borderLeft="default"
                  icon={GiftIcon}
                  title={<Type variant="darker" weight="bold" size="2xl">{getTranslatedText(TRANSLATION_CONSTANTS.PEP_BOYS_OFFERS)}</Type>}
                  subtitle={<Type size="md" lineHeight="md">{getTranslatedText(TRANSLATION_CONSTANTS.PEP_BOYS_COUPON)}</Type>}
                  onClick={() => {
                    datadogRum.addAction(`${partnerDetails.partnerDetails.displayCode} Coupon link clicked`);
                    window.open("https://allstate.com/myoffers/pepboys", "_blank")
                  }}
                />
              </div>)
            }
            { partnerDetails.partnerDetails.displayCode === 'PEP' ? <CreditCardOffer displayCode={partnerDetails.partnerDetails.displayCode}/> : null}
            <RentalExchange />
          </div>
        </>
        )}
      </LocaleConsumer>
    );
  }
}

function mapStateToProps(state) {
  return {
    yearValue: state.yearValue,
    vehicleValue: state.vehicleValue,
    currentLocation: state.currentLocation,
    destination: state.destination,
    personalInfo: state.personalInfo,
    serviceInfo: state.serviceInfo,
    serviceDetails: state.serviceDetails,
    submitRequest: state.submitRequest,
    serviceRequestInfo: state.serviceRequestInfo,
    partnerDetails: state.partnerDetails,
    buildDetails: state.buildDetails,
    providerInfo: state.providerInfo,
    jacadards: state.jacadards,
    serviceRequestPayload: state.serviceRequestPayload,
    commonBenefits: state.commonBenefits,
    ppuFuelPrice: state.generalInfo.ppuFuelPrice,
    hasPepSession: hasPepSessionSelector(state),
    amazonConnect: getAmazonConnectSelector(state),
  };
}

export default connect(mapStateToProps, {
  dispatchServiceRequestInfo,
  refreshReducersToIntial,
  sendJacadaRequest,
  dispatchProviderInfo,
})(Tracker);
