import React, { useCallback, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import FirstNameLastNameZipLookup from "../../components/lookupForms/FirstNameLastNameZipLookup";
import FirstNameLastNameCompanyLookup from '../../components/lookupForms/FirstNameLastNameCompanyLookup';
import PhoneNumberLookup from '../../components/lookupForms/PhoneNumberLookup';
import PolicyMemberLookup from '../../components/lookupForms/PolicyMemberLookup';
import MotorNumberLookup from '../../components/lookupForms/MotorNumberLookup';
import {
  commonBenefitsStart,
  noCommonBenefits,
  requestPartnerDetails,
  updatePpuFlag,
  lastSuccessfulSessionType,
  updateIsPrimaryLookup,
  refreshReducersToIntial,
  updateLoggedInFlag
} from "../../action";
import Spinner from "../../components/spinner";
import Alert from "../../components/alert";
import {getPartnerDetails, getCommonBenefits, getExpirationMileageByIndex} from "../../selector";
import {generatePath} from "../../utilities/RedirectUtils";
import VinOdometerLookup from "../../components/lookupForms/vinOdometerLookup";
import SearchByLicensePlatePage
  from "../../components/lookupForms/SearchByLicensePlatePage";
import {ConfirmationModal} from "../InformationConfirmationModal/ConfirmationModal";
import VinOnlyLookupForm from "../../components/lookupForms/VinOnlyLookup";
import {datadogRum} from "@datadog/browser-rum";
import {useLocale} from "../../contexts/LocaleContext";
import {TRANSLATION_CONSTANTS} from "../../app-consts/translations";
import StartOverAlertMessage from '../../components/start-over-alert/StartOverAlertMessage';
import TriageStepsFixedAlert from '../../components/triage-steps-fixed-alert/TriageStepsFixedAlert';
import lastNameStateZipLookup
  from "../../components/lookupForms/LastNameStateZipLookup";
import CONSTANTS, {NOT_COVERED_REASONS} from "../../app-consts/appConstants";
import {benefitsLookupStart} from "../../action";
import PolicyNumberStateCodeLookup from '../../components/lookupForms/PolicyNumberStateCodeLookup';
import OdometerLookup from '../../components/lookupForms/OdometerLookup';

const LookupComponents = {
  firstNameLastNameZipLookup: FirstNameLastNameZipLookup,
  phoneNumberLookup: PhoneNumberLookup,
  firstNameLastNameCompanyLookup: FirstNameLastNameCompanyLookup,
  memberlookup: FirstNameLastNameZipLookup,
  memberNumberLookup: PolicyMemberLookup,
  vinOdoLookupForm: VinOdometerLookup,
  vinOdoTokenLookupForm: VinOdometerLookup,
  partnerlogin: VinOdometerLookup,
  policyNumberLookup: PolicyMemberLookup,
  policyNumberLastNameLookup: PolicyMemberLookup,
  memberNumberLastNameLookup: PolicyMemberLookup,
  licensePlateLookup: SearchByLicensePlatePage,
  vinOnlyLookup: VinOnlyLookupForm,
  motorNumberLookup: MotorNumberLookup,
  lastNameStateZipLookup: lastNameStateZipLookup,
  memberNumberVinLookup: lastNameStateZipLookup,
  policyNumberStateCodeLookup: PolicyNumberStateCodeLookup,
  odometerLookUp: OdometerLookup,
  licensePlateOrVinLookup: SearchByLicensePlatePage,
};

const CommonLookup = ({ history }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [modalOptions, setModalOptions] = useState({});
  const [hasUsedAlternate, setHasUsedAlternate] = useState(false);

  const showSpinner = useSelector((state) => !!state.commonBenefits.loading);
  const partnerDetails = useSelector(getPartnerDetails);
  const commonBenefits = useSelector(getCommonBenefits);
  const expirationMileage = useSelector((state) => getExpirationMileageByIndex(state, 0));
  const dispatch = useDispatch();
  const primaryLookup = partnerDetails.experience?.lookupMethods?.components.primary;
  const alternateLookup = partnerDetails.experience?.lookupMethods?.components.alternate;
  const primaryFormValidations = partnerDetails.experience?.lookupMethodRules?.primary || {};
  const alternateFormValidations = partnerDetails.experience?.lookupMethodRules?.alternate || {};
  const primarySessionType = partnerDetails.experience?.lookupMethods?.sessionType?.primary;
  const alternateSessionType = partnerDetails.experience?.lookupMethods?.sessionType?.alternate;
  const enhancedAuthPartner = partnerDetails.enhancedAuthPartner;
  const [currentLookup, setCurrentLookup] = useState(primaryLookup);
  const [useAlternateLookup, setUseAlternateLookup] = useState(false);
  const getTranslatedText = useLocale();
  const [isPartnerChanged, setPartnerChanged] = useState(false);

  useEffect(() => {
    setCurrentLookup(primaryLookup);
  }, [primaryLookup]);

  // If current lookup needs to be set on second render
  if(currentLookup === undefined && primaryLookup !== undefined){
    setCurrentLookup(primaryLookup);
  }

  useEffect(() => {
    datadogRum.addAction("User lands on CommonLookup");
    const currentPartnerCode = window.location.pathname.split('/')[2];
    if (!partnerDetails.experience) {
      dispatch(requestPartnerDetails());
    } else if (partnerDetails.experience && currentPartnerCode !== undefined && currentPartnerCode !== partnerDetails.partnerCode && currentPartnerCode !== partnerDetails.displayCode) {
      datadogRum.addAction(`User changed partner code from ${partnerDetails.displayCode} to ${currentPartnerCode}`);
      dispatch(refreshReducersToIntial());
      localStorage.clear();
      dispatch(requestPartnerDetails());
      setPartnerChanged(true);
    }
  }, []);

  useEffect(() => {
    if(!currentLookup || currentLookup === primaryLookup) {
      dispatch(updateIsPrimaryLookup(true))
    } else {
      dispatch(updateIsPrimaryLookup(false))
    }
  }, [currentLookup])

  useEffect(() => {
    if(partnerDetails.partnerCode !== undefined &&
      partnerDetails.partnerCode === window.location.pathname.split('/')[2] &&
      partnerDetails.partnerCode !== partnerDetails.displayCode) {
      history.push(generatePath('memberlookup', partnerDetails.displayCode))
    }
  }, [partnerDetails, window.location.pathname.split('/')[2] !== undefined])

  useEffect(() => {
    if (commonBenefits.loading === false &&
      !partnerDetails.experience.ppuEnabled &&
      !partnerDetails.experience.benefitsEnabled &&
      partnerDetails.experience.serviceSelectionEnabled) {
      history.push(generatePath('home'));
    }

    if(NOT_COVERED_REASONS.find(pair => pair.id === commonBenefits.status?.toLowerCase())) {
      history.push(generatePath('home'));
    }

    if(partnerDetails.experience?.serviceBased &&
      ((commonBenefits.lookupType === CONSTANTS.AUTO_PHONE_LOOKUP && commonBenefits.status === CONSTANTS.INACTIVE)
        || (commonBenefits.lookupType !== CONSTANTS.AUTO_PHONE_LOOKUP &&
        (commonBenefits.errorMessage === CONSTANTS.MEMBER_NOT_FOUND || commonBenefits.errorMessage === CONSTANTS.MEMBER_NOT_FOUND_MESSAGE)))) {
      history.push(generatePath('ServiceTransition'))
    }
    // If benefits lookup API has finished
    if(useAlternateLookup && commonBenefits.loading === false) {
      localStorage.removeItem('rescueCancel')
      localStorage.removeItem('isPpu')
      localStorage.removeItem('feedbackConfirmation')

      dispatch(updateIsPrimaryLookup(false))
      // If there weren't any benefits found
      // If an error comes back from looking for benefits or
      // When benefits are not found and reducer initializes experience to an empty object
      //todo: refactor this on day 2
      if(commonBenefits.experience.claimsExhausted && !partnerDetails.experience.ppuEnabled) {
        history.push(generatePath('ServiceTransition'))
      } else if(commonBenefits.error || (commonBenefits.noBenefits && !partnerDetails.experience.claimsExhausted)){
        setCurrentLookup(alternateLookup);
        if(hasUsedAlternate || (currentLookup === alternateLookup)){
          const location = partnerDetails.experience.ppuEnabled ? 'home' : 'ServiceTransition';
          history.push(generatePath(location));
        } else{
          setHasUsedAlternate(true);
        }
      } else if(commonBenefits.benefits || partnerDetails.experience.claimsExhausted){
        if(!!expirationMileage) {
          setCurrentLookup("odometerLookUp");
        } else {
          history.push(generatePath('home'));
        }
      }
    }
  }, [commonBenefits.loading]);

  const dispatchCommonBenefitsLookup = useCallback((data) => {
    data.sessionType = primarySessionType;
    if (currentLookup === alternateLookup) {
      data.sessionType = alternateSessionType;
    }
    setUseAlternateLookup(true);
    dispatch(lastSuccessfulSessionType(data.sessionType));
    if(!!partnerDetails?.omniStreamlined) {
      dispatch(benefitsLookupStart(data));
    } else {
      dispatch(commonBenefitsStart(data));
    }
  }, [currentLookup]);

  const showModal = useCallback((options) => {
    setModalOptions({header: getTranslatedText(TRANSLATION_CONSTANTS.IS_INFORMATION_CORRECT), ...options});
    setShowConfirmationModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalOptions({});
    setShowConfirmationModal(false);
  }, []);

  const continueAsGuest = () => {
    localStorage.removeItem('rescueCancel')
    localStorage.removeItem("sessionType");
    localStorage.removeItem("benefitSession");
    localStorage.removeItem("memberId");
    localStorage.removeItem("lastName");
    localStorage.removeItem("commonBenefits");
    localStorage.removeItem("zip");
    localStorage.removeItem("ppuFuelPrice");
    localStorage.removeItem("firstName");
    dispatch(updateLoggedInFlag("N"))
    dispatch(updatePpuFlag("Y"))
    dispatch(noCommonBenefits(getTranslatedText(TRANSLATION_CONSTANTS.MEMBER_NOT_FOUND)))
    datadogRum.addAction('Continued As Guest');
    dispatch(updateIsPrimaryLookup(false))
    history.push(generatePath('home'));
  };

  const { experience } = partnerDetails;
  const hideContinueAsGuest = experience?.hideContinueAsGuest;
  const ppuEnabled = experience?.ppuEnabled;
  const milesBased = experience?.milesBased;
  let currentFormValidations = hasUsedAlternate ? alternateFormValidations : primaryFormValidations;
  const LookupComponent =  currentLookup !== undefined ? React.createElement(LookupComponents[currentLookup], {
    partnerDetails,
    dispatchCommonBenefitsLookup,
    showModal,
    milesBased,
    closeModal,
    history,
    setUseAlternateLookup,
    setHasUsedAlternate,
    setCurrentLookup,
    currentFormValidations,
    currentLookup,
    enhancedAuthPartner
  }): null ;

  return (
    <div className='l-container-memberLookup' id='common-lookup'>
      <ConfirmationModal
        isActive={showConfirmationModal}
        {...modalOptions}
      />
      {localStorage.getItem('rescueCancel') === 'YES' && localStorage.getItem('isPpu') === 'true' ?
        <div style={{margin: '1rem'}}>
          <Alert type='confirmation'>{getTranslatedText(TRANSLATION_CONSTANTS.TAKE_UP_5_DAYS_CREDIT_REFUND)}</Alert>
        </div>
        : null}
      {localStorage.getItem('rescueCancel') === 'YES' && localStorage.getItem('isPpu') !== 'true' ?
        <div style={{margin: '1rem'}}>
          <Alert type='confirmation'>{getTranslatedText(TRANSLATION_CONSTANTS.CANCEL_REQUEST_SUCCESFULLY)}</Alert>
        </div>
        : null}
      {!isPartnerChanged && localStorage.getItem('feedbackConfirmation') === 'YES' ?
        <div style={{margin: '1rem'}}>
          <Alert type='confirmation'>{getTranslatedText(TRANSLATION_CONSTANTS.APPRECIATE_FEEDBACK)}</Alert>
        </div>
        : null}
      {!isPartnerChanged &&
      <StartOverAlertMessage
        enhancedAuthPartner={enhancedAuthPartner}
        benefitsLoading={commonBenefits.loading}
        isAlternateLookup={useAlternateLookup}
        primaryLookup={primaryLookup}
      />}
      <TriageStepsFixedAlert
        benefitsLoading={commonBenefits.loading}
        isAlternateLookup={useAlternateLookup}
      />
      {showSpinner && <Spinner/>}
      {currentLookup && LookupComponent}
      {
        ppuEnabled && !hideContinueAsGuest &&
        <div>
          <div className="or-text u-align-center u-vr-2-top">- {getTranslatedText(TRANSLATION_CONSTANTS.OR)} -</div>
          <button id="amc-continue-as-guest"
                  className="btn btn--tertiary u-vr-3-top"
                  onClick={continueAsGuest}>
                    {getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE_AS_GUEST)}
          </button>
          <p className="pay-per-use u-vr-2-top">
            {getTranslatedText(TRANSLATION_CONSTANTS.USE_PPU_OPTION_TO_PAY)}
          </p>
        </div>
      }
      <div className="c-divider u-vr-5-top"></div>
    </div>
  );
};

export default CommonLookup;
