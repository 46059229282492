const CONSTANTS = {

  INVALID_OAUTH_TOKEN: "Invalid oauth token",
  OUT_OF_CLAIMS_MESSAGE: "You’ve used up all of your benefits. To pay for today’s service, choose from the options below.",
  MEMBER_NOT_FOUND_MESSAGE: "It looks as though you haven't signed up for roadside benefits yet. You can either pay for just today's service, or call us to ask about a membership.",
  MEMBER_NOT_UNIQUELY_FOUND_MESSAGE: "We're unable to find your benefits information.",
  OUT_OF_CLAIMS: 'OutOfClaims',
  INVALID_MVA_NUMBER: 'Invalid MVA Number',
  MEMBER_NOT_FOUND: 'memberNotFound',
  JACADA_AUTHENTICATION_MESSAGE: 'JacadaRequestSent authenticated set to Y',
  JACADA_RESPONSE_FAILURE_MESSAGE: 'JacadaResponseReceived No response from Jacada or API failed',
  MUST_CALL_FOR_WEIGHT_CLASS: "MUST_CALL_FOR_WEIGHT_CLASS",
  MUST_SHOW_NO_BENEFITS_PRICING_INFO: "MUST_SHOW_NO_BENEFITS_PRICING_INFO",
  WAIT_PERIOD: "Your membership is not yet eligible for roadside coverage due to 2 day wait period. To pay for today's service, choose from the options below",
  MUST_DISPLAY_TRIAGE_STEPS: "MUST_DISPLAY_TRIAGE_STEPS",
  NO_MEMBER_FOUND: "NOMEM",
  MUTLIPLE_MEMBERS_FOUND: "MULTIMEMBER",
  INACTIVE: "INACTIVE",
  AUTO_PHONE_LOOKUP: "autoPhoneLookup",
  MOTOR_NUMBER_LOOKUP: "motorNumberLookup",

  NO_CLAIMS_LEFT: 0,
  HTML_CLASS: {
    DISABLED: 'is-disabled',
    ERROR: 'has-error',
    WARNING: 'has-warning',
    ACTIVE: 'is-active',
    FOCUS: 'has-focus'
  },

  KEY_CODES: {
    ENTER: 13,
    ESCAPE: 27,
    LEFT: 37,
    RIGHT: 38,
    UP: 39,
    DOWN: 40,
  },

  DELETE : 46,
  BACKSPACE: 8,
  EXPONENTIAL: 69,
  DECIMALPOINT: 190,


  DEFAULT_DATE_FORMAT: 'MM/DD/YYYY',

  MONTHS: [
    ['January', 'Jan'],
    ['February', 'Feb'],
    ['March', 'Mar'],
    ['April', 'Apr'],
    ['May', 'May'],
    ['June', 'Jun'],
    ['July', 'Jul'],
    ['August', 'Aug'],
    ['September', 'Sept'],
    ['October', 'Oct'],
    ['November', 'Nov'],
    ['December', 'Dec'],
  ],

  MONTHS_IN_A_YEAR: 12,
  DAYS_IN_A_WEEK: 7,
  MILLISECONDS_IN_ONE_DAY: (1000 * 60 * 60 * 24),

  DAYS: [
    ['Sunday', 'Sun', 'S'],
    ['Monday', 'Mon', 'M'],
    ['Tuesday', 'Tue', 'T'],
    ['Wednesday', 'Wed', 'W'],
    ['Thursday', 'Thur', 'T'],
    ['Friday', 'Fri', 'F'],
    ['Saturday', 'Sat', 'S'],
  ],

  PARTNER_CODES: {
    GM: 'GM',
    PEP_BOYS: 'PEP'
  },
  ALLSTATE_HOST : "roadside.allstate.com",
  VANITY_HOST :   "www.getroadsideassistance.com",
  MANAGE_ROADSIDE_URL: "https://manageroadside.allstate.com/login",
  QA_MANAGE_ROADSIDE_URL: "https://manageroadside-test.allstate.com/login",
  SERVICE_STATUS_COVERED : "Covered",
  SERVICE_STATUS_NOT_COVERED : "Not Covered"
};

export default CONSTANTS;

export const CANADA = 'CA';

export const AAR = 'AAR';
export const AMC = 'AMC';
export const D24 = 'D24';
export const ETL = 'ETL';
export const GHR = 'ghr';
export const GM = 'GM';
export const JJ = 'JJ';
export const PEP = 'PEP';
export const VZW = 'VZW';
export const GMC = 'GMC';

export const BAD_REQUEST = 'BAD REQUEST';
export const NEW_JOB = 'NEW JOB';
export const NEW_JOB_RSV = 'NEW_JOB';
export const DROP_PASSG = 'DROP_PASSG';
export const COMPLETED = 'COMPLETED';
export const GONE_ON_ARRIVAL = 'GOA REQ';
export const GONE_ON_ARRIVAL_RSV = 'GOA_REQ';
export const PROVIDER_CANCEL = 'PROVIDER_CANCEL';
export const CANCELLED = 'CANCELLED';
export const CANCELED = 'CANCELED';
export const PROVIDER_NOT_FOUND = 'PROVIDER NOT FOUND';
export const BOOKED = 'BOOKED';
export const ASSIGNED = 'ASSIGNED';
export const CSR_TAKEOVER = 'CSR TAKE OVER'
export const EN_ROUTE = 'EN-ROUTE';
export const GPS = 'GPS';
export const APPROACHING = 'APPROACHING';
export const ONSITE = 'ONSITE';
export const ARRIVED_ONSITE = 'ARRIVED ONSITE';
export const ARRIVED_ONSITE_RSV = 'ARRIVED_ONSITE';
export const LOADED = 'LOADED';
export const EN_ROUTE_DROP = 'EN-ROUTE DROP';
export const EN_ROUTE_DROP_RSV = 'EN-ROUTE_DROP';
export const DROP_LOCATION = 'DROP LOCATION';
export const DROP_LOCATION_RSV = 'DROP_LOCATION';
export const DROP_SIGN = 'DROP SIGN';
export const DROP_SIGN_RSV = 'DROP_SIGN';

export const ACTIVE_RESCUE_STATUSES = [
  BOOKED,
  ASSIGNED,
  CSR_TAKEOVER,
  EN_ROUTE,
  APPROACHING,
  GPS,
  ONSITE,
  ARRIVED_ONSITE_RSV,
  LOADED,
  DROP_SIGN_RSV,
  DROP_LOCATION_RSV,
  GONE_ON_ARRIVAL_RSV,
  COMPLETED]

export const TOW = 'Tow';
export const FUEL_DELIVERY = 'Fuel Delivery';
export const GAS = 'Gas';
export const JUMP_START = 'Jump Start';
export const LOCKOUT = 'Lockout';
export const TIRE_CHANGE = 'Tire Change';
export const supportedColors = [
  { text: 'Black', value: 'BLACK', },
  { text: 'Blue', value: 'BLUE', },
  { text: 'Brown', value: 'BROWN', },
  { text: 'Burgundy', value: 'BURGUNDY', },
  { text: 'Gold', value: 'GOLD', },
  { text: 'Green', value: 'GREEN', },
  { text: 'Grey', value: 'GREY', },
  { text: 'Orange', value: 'ORANGE', },
  { text: 'Purple', value: 'PURPLE', },
  { text: 'Red', value: 'RED', },
  { text: 'Silver', value: 'SILVER', },
  { text: 'White', value: 'WHITE', },
  { text: 'Yellow', value: 'YELLOW', },
]
export const GM_BRANDS = ['CHE', 'BUC', 'CAD', 'GMC', 'GMI', 'CCD', 'FLC'];
export const GM_US_BRANDS = ['CHE', 'BUC', 'CAD', 'GMC', 'FLC'];
export const GM_CA_BRANDS = ['GMI', 'CCD'];
export const FINAL_COST_DETERMINED_ONCE_RESCUER_ASSIGNED = 'The final cost will be determined once your rescuer has been assigned. Any amount exceeding your benefits must be paid directly to the rescuer.';
export const GOA_MESSAGE  = "The rescuer was on site and was unable to contact you or couldn't locate the vehicle. We will contact you shortly to see if you still need service.";

export const ZIP_LENGTH_REGEX = /^\d{5}(-\d{4})?$/;
export const ETL_POLICY_NUMBER_REGEX = /([A-Z]{4})+(\d{9})/;

export const hasSpecialChars = /[\W]|[`~!@?$%^&*()_+=<>\]\\[\\{};:'"|\\/]/;
export const isOnlyNumbers = /^\d+$/;
export const isOnlyAlpha = /^[A-Za-z]+$/i

export const JACADA_PROD_URL='https://as-analytics.gointeract.io/conversations';
export const JACADA_DEV_URL='https://as-analytics.gointeract.io/conversations';
export const API_COMBO ='9JxqyeBSNk94HYl4zrHBT60KCAzs170b2JvaY7eP';

export const DD_PROD_ENV='0418000019QP - Roadside';
export const DD_PROD_ID='16d8dc99-e708-49fb-80fb-1da0837875f7';
export const DD_PROD_TOKEN='pubf7ade4f3be1308963c5b8312e3e5e3c6';

export const DD_QA_ENV='0418000019QP - QA Roadside';
export const DD_QA_ID='5f201f28-b926-48ef-a119-b052abab9a3e';
export const DD_QA_TOKEN='pub1fffb43ae32c9a41de9c46b333e624ba';

export const TOW_SERVICE_ID= 1;
export const FUEL_SERVICE_ID= 3;
export const BATTERY_SERVICE_ID= 5;

export const NO = "N";
export const YES = "Y";

export const PPU = 'ppu';
export const OMNI_ASSIST = 'OmniAssist';

export const SERVICE_LIMIT = 'service-limit';
export const SERVICE_UNAVAILABLE = 'service-unavailable';

export const en_US = 'en_US';
export const en_CA = 'en_CA';
export const USA_COUNTRY_CODE = "USA";
export const CANADA_COUNTRY_CODE = 'CAN';
export const GALLON = 'gallon';
export const LITER = 'liter';
export const fr_CA = 'fr_CA';
export const es_US = 'es_US';
export const SUPPORTED_LANGUAGES = [en_US, en_CA, fr_CA, es_US]
export const FRIENDS_FAMILY_VERSION = 'f'; // RT SMS
export const RESCUE_CUSTOMER_VERSION = 'l'; // RT SMS
export const RESCUE_WLEB = 'wleb'; // RT WLE
export const RESCUE_WLEOA = 'wleoa'; // RT WLE
export const RESCUE_WLEM = 'wlem'; // RT WLE
export const RESCUE_WLEP = 'wlep'; // RT WLE

export const RESCUE_WLES = [RESCUE_WLEB, RESCUE_WLEOA, RESCUE_WLEM, RESCUE_WLEP];

export const BENEFITS = 'benefits';
export const MEMBER = 'member';

export const DEFAULT_OPENFROM = '08:00';
export const DEFAULT_CLOSEAT = '17:00';

export const SUSPENDED_VEHICLE_TO_CALL_CENTER = 'SUSPENDED_VEHICLE_TO_CALL_CENTER';

export const DISPLAY_TRIAGE_STEPS_STATUSES = [CONSTANTS.MUST_DISPLAY_TRIAGE_STEPS, CONSTANTS.MUST_SHOW_NO_BENEFITS_PRICING_INFO];

export const ACTIVE_MEMBERSHIP_STATUSES = [CONSTANTS.MUST_DISPLAY_TRIAGE_STEPS, CONSTANTS.MUST_SHOW_NO_BENEFITS_PRICING_INFO, "ACTIVE"]

export const CALLBACK_NUMBER_RETURNED_MEMBERSHIP_STATUSES = [CONSTANTS.INACTIVE, CONSTANTS.MUTLIPLE_MEMBERS_FOUND, CONSTANTS.NO_MEMBER_FOUND, "ACTIVE"]

export const DISPATCH_GUARANTEE_MEMBERSHIP_STATUSES = ["VAS_DG3090", "VAS_DG3180", "VAS_DG4090", "VAS_DG4180"];

export const EPCS_SCRIPT_ID = "epcs_script";

export const EPCS_STATUS_SUCCESS = "SUCCESS";
export const EPCS_STATUS_FATAL = "FATAL";
export const EPCS_STATUS_CARD_ERROR = "CARD_ERROR";

export const NOT_COVERED_REASONS = [
  {
    id:"contract cancelled",
    reason: "Your coverage  was cancelled but we can still help. Use our pay-per-use option to get roadside help today."
  },
  {
    id:"contract expired",
    reason: "Your coverage is expired but we can still help. Use our pay-per-use option to get roadside help today."
  },
  {
    id:"contract not active yet",
    reason: "Your coverage is not active yet but we can still help. Use our pay-per-use options to get roadside help today."
  },
  {
    id: "inactive",
    reason: "Your coverage is not active yet but we can still help. Use our pay-per-use options to get roadside help today."
  },
  {
    id: "benefits_used_up",
    reason: "You’ve used up all of your benefits. To pay for today’s service, choose from the options below."
  }

];

export const AMAZON_CONNNECT_ID = "amazon_connect"

export const AMAZON_CONNNECT_CHAT_USER_TYPE = "Customer"

export const UNSUPPORTED_EQUIPMENT_CLASSES = ['medium', 'motorcycle', 'heavy'];

export const LAST_NAME_AUTHENTICATION_CHECK_PARTNERS = ['AMC', 'JJ', 'AAR'];

export const LAST_NAME_AUTHENTICATION_CHECK_LOOKUP_TYPES = ['memberNumberLastNameLookup', 'policyNumberLastNameLookup'];
