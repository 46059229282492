import { cloneDeep, divide, isEmpty } from "lodash";

export const EN_US = "en-US";
export const HOUR12 = {
  hour: "numeric",
  minute: "numeric",
  hour12: true,
};

export const minuteOrMinutes = (number) => {
  return number === 1 ? 'minute' : 'minutes';
}

export const formatDateAsString = (date = new Date(), locale, options) => {
  let defaultoptions = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  };
  if (!date) {
    return new Date().toLocaleDateString("en-US", defaultoptions);
  }
  if (!locale) {
    locale = "en-US";
  }
  if (!options) {
    return date.toLocaleDateString(locale, defaultoptions);
  }
  return date.toLocaleDateString(locale, options);
};

export const formatTimeAsString = (date = new Date(), locale, options) => {
  let defaultoptions = {
    minute: "2-digit",
    hour: "2-digit",
    hour12: true,
  };
  if (!date) {
    return new Date().toLocaleTimeString("en-US", defaultoptions);
  }
  if (!locale) {
    locale = "en-US";
  }
  if (!options) {
    return date.toLocaleTimeString(locale, defaultoptions);
  }
  return date.toLocaleTimeString(locale, options);
};

export const getRoundOffTimeAddingAdditionalMinutes = (minutes, currentDate = new Date()) => {
  const dateAddedWithMins = new Date(currentDate.getTime() + minutes * 60000);
  const roundOffDate = new Date(dateAddedWithMins.getTime() + (((60 - dateAddedWithMins.getMinutes()) % 5) * 60000));
  const roundOffTime = formatTimeAsString(roundOffDate, EN_US, HOUR12);
  return formatTimeToLowerCasesWithPeriods(roundOffTime);
};

export const formatTimeToLowerCasesWithPeriods = time => {
  const timeLowerCase = time?.toLowerCase();
  return timeLowerCase?.slice(0, timeLowerCase?.length-1) + '.' + timeLowerCase?.slice(timeLowerCase?.length-1) + '.';
};

export const roundToNearestMin = (date = new Date(), minutes = 30) => {
  if(minutes > 60) {
    return;
  }
  const ms = 1000 * 60 * minutes;
  return new Date(Math.round(date.getTime() / ms) * ms);
};

export const timeStaggeredRangeInHours = (
  startTime = new Date(),
  endTime = new Date(),
  staggerMins = 30,
  rangeHours = 1
) => {
  if (!startTime || !endTime || startTime.getTime() >= endTime.getTime()) {
    return [];
  }
  if (!staggerMins || staggerMins <= 1) {
    staggerMins = 30;
  }
  if (staggerMins > 60) {
    return [];
  }
  if (!rangeHours || rangeHours <= 1) {
    rangeHours = 1;
  }
  if (rangeHours > 24) {
    return [];
  }
  const totalHours = diffInhours(startTime, endTime);
  if (totalHours < rangeHours) {
    return [];
  }
  const totalHoursByRange = divide(totalHours, rangeHours);
  const totalStaggeredMins = divide(60, staggerMins);
  const getInitialAndEndDates = (initial, end, mins = 0) => {
    const tempInitial = new Date();
    tempInitial.setHours(initial, mins, 0, 0);
    const tempEnd = new Date();
    tempEnd.setHours(end, mins, 0, 0);
    const stubDate = new Date();
    stubDate.setHours(endTime.getHours(), endTime.getMinutes(), endTime.getSeconds(), endTime.getMilliseconds());
    if (
      tempInitial.getTime() > stubDate.getTime() ||
      tempEnd.getTime() > stubDate.getTime()
    ) {
      return [];
    }
    return [tempInitial, tempEnd];
  };
  const timeStaggered = [];
  const startTimeHour = startTime.getHours();
  const startTimeMinutes = startTime.getMinutes();
  let tempStartTimeHours = cloneDeep(startTimeHour);
  for (let i = 0; i < totalHoursByRange; i++) {
    const currentInitialHour = tempStartTimeHours;
    const currentEndHour = tempStartTimeHours + rangeHours;
    if(currentInitialHour > endTime.getHours() || currentEndHour > endTime.getHours()){
      break;
    }
    let tempStaggerMins = startTimeMinutes;
    for (let j = 0; j < totalStaggeredMins; j++) {
      const tempInitialAndEndDates = getInitialAndEndDates(currentInitialHour, currentEndHour, tempStaggerMins);
      if (isEmpty(tempInitialAndEndDates)) {
        break;
      }
      timeStaggered.push(tempInitialAndEndDates);
      tempStaggerMins += staggerMins;
    }
    tempStartTimeHours += rangeHours;
  }
  return timeStaggered;
};

export const diffInhours = (date1, date2) => Math.round(Math.abs(date1 - date2) / 36e5);

export const updateScheduledTimeToReadableTimeRange = (time) => {
  const convertedTime = time.split(':');

  let startHours = Number(convertedTime[0]);
  let endHours = Number(convertedTime[0]) + 1;
  let minutes = Number(convertedTime[1]);

  let readableStartTime;
  let readableEndTime;
  if (startHours > 0 && startHours < 12) {
    readableStartTime = "" + startHours;
    readableEndTime = "" + endHours;
  } else if (startHours > 12) {
    readableStartTime = "" + (startHours - 12);
    readableEndTime = "" + (endHours - 12);
  } else if (startHours === 0 || startHours === 12) {
    readableStartTime = "12";
    readableEndTime = "1";
  }

  let readableTimeRange = "";
  readableStartTime += (minutes < 10) ? ":0" + minutes : ":" + minutes;
  readableEndTime += (minutes < 10) ? ":0" + minutes : ":" + minutes
  readableStartTime += (startHours >= 12) ? "pm" : "am";
  readableEndTime += (endHours >= 12 && endHours <= 23) ? "pm" : "am";
  readableTimeRange += readableStartTime + " - " + readableEndTime;

  return readableTimeRange;
}

export const updateScheduledDateToReadableValue = (dateString) => {
  const updatedDateString = dateString.split('-')
  return updatedDateString[1] + "/" + updatedDateString[2] + "/" + updatedDateString[0]

}
