import React, {Component} from 'react';
import {connect} from 'react-redux';
import _find from "lodash/find";
import _map from "lodash/map";
import _isEmpty from "lodash/isEmpty";
import Loader from '../../components/loader';
import {datadogRum} from "@datadog/browser-rum";

import {
  refreshServiceRequestInfo,
  requestPartnerDetails,
  selectedMakeModelYear,
  updatePpuFlag,
  updateLoggedInFlag,
  updateServiceInfo,
  updateCCardInfo,
  updateServiceTransition,
  updatePartnerDetailsExperience,
  updateCurrentLocation,
  updateIsPrimaryLookup,
  updatePpuFuelPrice,
  clearServiceTypeOptions,
  updateFirstName,
  updateLastName,
  updatePromoCode
} from '../../action';
import FormOption from '../../components/form-option';
import Icon from '../../components/icon';
import ModalDialogAlt from '../../components/modal-dialog-alt';
import SelectablePanel from '../../components/selectable-panel';
import ServiceCancelled from '../../components/service-cancelled';
import FeedbackConfirmation from '../../components/feedback-confirmation';
import CarAnn from '../../components/svg/CarAnn';
import Benefits from '../../components/membershipBenefits';
import {saveRescueDetails, sendJacadaRequest} from '../../api';
import CommonBenefits from '../../aop/commonBenefits';
import {
  isPayPerUseSelector,
  hasPepSessionSelector,
  partnerDetailsLoadedSelector
} from '../../selector';
import CONSTANTS, {
  SERVICE_LIMIT,
  FUEL_DELIVERY,
  TOW,
  GAS,
  SERVICE_UNAVAILABLE,
  JUMP_START,
  DISPLAY_TRIAGE_STEPS_STATUSES,
  LOCKOUT,
  NOT_COVERED_REASONS
} from "../../app-consts/appConstants";
import {generatePath} from '../../utilities/RedirectUtils'
import { LocaleConsumer } from "../../contexts/LocaleContext";
import {TRANSLATION_CONSTANTS} from "../../app-consts/translations";
import { errorTranslatedText, experimentalServiceText, serviceTranslatedText } from '../../utils/translatedTextUtils';
import AlertModal from "../../components/alertmodal";
import isGMPartnerCode from '../../utils/isGMPartnerCode';
import PricingInfoModal from './modal/PricingInfoModal';
import { convertToBoolean } from '../../utils/regexUtil';
import { Button, Type } from 'mesh-component-library';

export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      helpMeChooseModal: false,
      helpMeChooseButtonDisabled: false,
      helpMeChooseOption: undefined,
      rescueCancelled: false,
      feedbackConfirmation: false,
      homeStatic: false,
      phoneLookup: false,
      showContinueAsPPUForServiceModal: {
        active: false,
        service: null
      },
      ppuFuelPrice: 0,
      showPricingInfoModal: false,
    }
  }

  componentDidMount() {
    datadogRum.addAction("User lands on service selection page");
    document.title = 'Home | Roadside Assistance';
    window.scrollTo(1, 1);

    const {
      partnerCode,
      refreshServiceRequestInfo,
      selectedMakeModelYear,
      requestPartnerDetails,
      userType,
      partnerDetailsLoaded,
      partnerDetails,
      submitRequest,
      commonBenefits,
      updateFirstName,
      updateLastName
    } = this.props;

    if (commonBenefits?.errorMessage === "MUST_CALL_FOR_WEIGHT_CLASS") {
      this.props.history.push(generatePath('ServiceTransition'));
    }

    if (submitRequest?.success) {
      const {sessionId, callId, requestId, usageId} = submitRequest.response
      this.props.history.push(generatePath(`tracker/${sessionId}/${callId}/${requestId}/${usageId}`));
    }

    refreshServiceRequestInfo();
    selectedMakeModelYear({});

    if (partnerCode && !partnerDetailsLoaded && !partnerDetails.error) {
      requestPartnerDetails(window.location.pathname.split("/")[2]);
    }

    if(userType==='ppu'){
      this.props.updatePpuFlag("Y")
      this.props.updateIsPrimaryLookup(true)
    }
    if (userType === 'benefits' || userType === 'ppu') {
      let cancelMessageFlag = localStorage.getItem('isPpu');
      localStorage.setItem('isPpu', cancelMessageFlag);
    }

    if (localStorage.getItem('rescueCancel') === 'YES') {
      this.setState({rescueCancelled: true});
      localStorage.setItem('rescueCancel', 'NO');
    }
    if (localStorage.getItem('feedbackConfirmation') === 'YES') {
      this.setState({feedbackConfirmation: true});
      localStorage.setItem('feedbackConfirmation', 'NO');
    }
    if(localStorage.getItem('firstName') !== null && localStorage.getItem('lastName') !== null) {
      updateFirstName(localStorage.getItem('firstName'))
      updateLastName(localStorage.getItem('lastName'))
    }
  }

  componentDidUpdate() {
    const {
      commonBenefits,
      partnerDetails : {
        partnerDetails
      },
      updatePartnerDetailsExperience
    } = this.props;
    if (commonBenefits.benefits && (JSON.stringify(partnerDetails.experience) !== JSON.stringify(commonBenefits.experience))) {
      updatePartnerDetailsExperience(commonBenefits.experience);
    }

    this.rerouteUserIfNeeded();
  }

  rerouteUserIfNeeded = () => {
    const {
      userType,
      partnerDetails,
      commonBenefits,
      isPayPerUse
    } = this.props;

    if (userType === 'benefits'
      && (
        (commonBenefits.error === true && (commonBenefits.unauthorized?.response?.status === 401))
        || (partnerDetails && partnerDetails.status === 401)
      )
    ) {
      let newURL = window.location.origin + '/login';
      let search = window.location.search
      window.location.replace(newURL+search);
    }
    else if (commonBenefits.error || partnerDetails.error) {
      this.props.history.push(generatePath('sitedown'));
    }

    if (commonBenefits.experience?.coverageFollowsVehicle && commonBenefits.vehicles?.length === 0) {
      this.props.history.push(generatePath('memberlookup'));
    }
    if (commonBenefits.noBenefits && !partnerDetails.partnerDetails?.experience?.passThruEnabled && !isPayPerUse) {
      this.props.history.push(generatePath('ServiceTransition'));
    }
  }

  _onChangeDispatcher = (event) => {
    let payload = event.target.value;
    let payload_new = payload;
    let Service_ID = '';
    let Event_ID = '';

    if (payload === "Fuel Delivery") {
      payload_new = "Gas";
      Service_ID = 7;
      Event_ID = 25;
    } else if (payload === "Tire Change") {
      payload_new = "Changetire";
      Service_ID = 44;
      Event_ID = 138;
    } else if (payload === "Tow") {
      Service_ID = 45;
      Event_ID = 52;
    } else if (payload === "Lockout") {
      Service_ID = 24;
      Event_ID = '';
    } else {
      payload_new = "Battery";
      Service_ID = 23;
      Event_ID = '';
    }

    let eventsData = [];
    eventsData.push(
      {
        'name': 'svc_id',
        'value': Service_ID
      },
      {
        'name': 'event_id',
        'value': Event_ID
      },
      {
        'name': 'SVC_DETL_TYPE_ID',
        'value': ''
      },
      {
        'name': 'flat_tire_type_id',
        'value': ''
      }
    );
    let jacadaRequestData = {
      'externalSystemId': localStorage.getItem('conversationId'),
      'events': eventsData
    }
    sendJacadaRequest(jacadaRequestData);
    datadogRum.addAction(`${payload} Selected`)
    datadogRum.addAction("Service Type Selected", {eventsData});

    localStorage.setItem('payload_new', payload_new);
    localStorage.setItem('triage_type', payload);
    let services = this.props.commonBenefits.services;
    localStorage.setItem('services', JSON.stringify(services));

    let selectedService = _find(services, ['type', payload_new]);
    this.props.updateIsPrimaryLookup(false)
    const partnerDetails = this.props.partnerDetails.partnerDetails;
    const excludedServiceTypes = partnerDetails?.experience?.excludedServiceTypes;

    if(partnerDetails.omniStreamlined && this.props.isPayPerUse) {
      this.props.updatePromoCode(partnerDetails.promoCode)
    }
    this.props.clearServiceTypeOptions();
    if (excludedServiceTypes?.includes(payload) || excludedServiceTypes?.includes(payload_new)) {
      this.props.updateServiceTransition({
        serviceType: payload,
        declineType: SERVICE_UNAVAILABLE
      });
      this.props.history.push(generatePath('ServiceTransition'));
    } else if (this.props.isPpuEnabled === false && selectedService && Number(selectedService.claimCount) >= Number(selectedService.maxClaimCount) && partnerDetails.experience.coverageFollowsService) {
      this.props.updateServiceTransition({
        serviceType: payload_new,
        declineType: SERVICE_LIMIT
      });
      this.props.history.push(generatePath('ServiceTransition'));
    } else if (this.props.isPpuEnabled === true && selectedService && Number(selectedService.claimCount) >= Number(selectedService.maxClaimCount) && this.props.partnerDetails.partnerDetails.experience.coverageFollowsService) {
      this.setState({showContinueAsPPUForServiceModal: {active: true, service: payload}})
    } else {
      this.gotoLocation(payload)
    }
  }

  gotoLocation = (payload) => {
    const {
      serviceInfo, commonBenefits, partnerDetails: {
        partnerDetails: {
          experience,
          partnerCode,
          omniStreamlined
        }
      },
      serviceRequestPayload
    } = this.props;
    if(omniStreamlined) {
      saveRescueDetails(serviceRequestPayload, partnerCode)
    }
    const ppuFuelPrice = localStorage.getItem('ppuFuelPrice')
    let service = _find(this.props.serviceDetails.serviceDetails, ['serviceText', payload]);
    let benefitsService = commonBenefits?.services?.filter(services => services.type === service.serviceType)[0];
    if(experience.serviceBased && benefitsService.status === CONSTANTS.SERVICE_STATUS_NOT_COVERED
    && experience.ppuEnabled) {
      this.props.updatePpuFlag("Y");
      this.props.updateLoggedInFlag("N");
      this.props.updateCCardInfo({transactionAmount: service.originalCost})
    } else if(experience.serviceBased && benefitsService.status === CONSTANTS.SERVICE_STATUS_NOT_COVERED
      && !experience.ppuEnabled) {
      //TODO: We may need if excluded service types cannot be reused
      this.props.history.push(generatePath("ServiceTransition"))
    }
    serviceInfo['value'] = payload;
    serviceInfo['cost'] = service.cost;
    localStorage.setItem('serviceText', payload);
    this.props.updateServiceInfo(service);
    if(this.props.isPayPerUse) {
      this.props.updateCCardInfo({transactionAmount: service.originalCost})
    }
    if(ppuFuelPrice) {
      if(serviceInfo.value === FUEL_DELIVERY) {
        this.props.updatePpuFlag("Y");
        this.props.updateCCardInfo({transactionAmount: ppuFuelPrice});
      } else {
        this.props.updatePpuFlag("N");
        this.props.updateCCardInfo({transactionAmount: ''});
      }
    }
    const hasConnectedVehicle = () => {
      let benefitsVehicles = commonBenefits?.vehicles?.length > 0 ? commonBenefits.vehicles : [];
      return benefitsVehicles.some((vehicle) => convertToBoolean(vehicle.vehiclePropID))
    }
    if(DISPLAY_TRIAGE_STEPS_STATUSES.includes(commonBenefits.status) && (payload === JUMP_START || (payload === LOCKOUT && hasConnectedVehicle()) || payload === FUEL_DELIVERY)) {
      this.props.history.push(generatePath('triage-steps'));
    } else if (payload === "Tire Change" || payload === "Lockout" || payload === "Jump Start") {
      this.props.history.push(generatePath('triage'));
    }
    else if (payload === "Tow" && experience.towTriageEnabled) {
      this.props.history.push(generatePath('triage'));
    }
    else if (experience?.schedulingEnabled) {
      this.props.history.push(generatePath('ScheduleService'));
    } else {
      this.props.updateCurrentLocation({setFromLocationServices: false})
      this.props.history.push(generatePath('disablementlocation'));
    }
  }

  openHelpMeChoose = () => {
    this.setState({
      helpMeChooseModal: true,
      homeStatic: true
    });
  }

  closeHelpMeChoose = () => {
    this.setState({
      homeStatic: false,
      helpMeChooseModal: false
    });
  }

  enableHelpMeChooseModalButton = (event) => {
    const option = event.target.value;
    this.setState({helpMeChooseOption: option});
    this.setState({helpMeChooseButtonDisabled: true});
  }

  continueToTow = () => {
    this.props.updateIsPrimaryLookup(false);
    this.props.history.push(generatePath(`help/${this.state.helpMeChooseOption}`));
  }

  renderSpinnerModal = () => {
    // loading isn't a property by default, is only added once it starts loading and then goes to false when it's finished
    let { commonBenefits } = this.props;
    if (commonBenefits && commonBenefits.loading) {
      return (
        <div className="c-overlay has-loader is-fixed">
          <Loader className="c-loader--xl c-loader--position"/>
        </div>
      )
    }
  }

  suspendedBtnCancel = () => {
    this.setState({
      showContinueAsPPUForServiceModal:{
        active: false,
        service: null
      },
    });
  }

  suspendedBtnGuest = () => {
    this.setState({
      showContinueAsPPUForServiceModal: false});
    this.props.updatePpuFlag("Y")
    let exactCost = this.props.serviceDetails?.serviceDetails.filter(detail => detail.serviceText === this.state.showContinueAsPPUForServiceModal.service)[0].exactCost
    this.props.updateCCardInfo({transactionAmount: exactCost})
    this.props.updateLoggedInFlag('N')
    this.gotoLocation(this.state.showContinueAsPPUForServiceModal.service);
  }

  renderServiceDetails = (getTranslatedText, serviceDetail) => {
    const { commonBenefits, isMilesBased, partnerCode, partnerDetails } = this.props;
    const {
      experience
    } = commonBenefits;
    const { serviceType } = serviceDetail;
    let serviceEnabled = true;
    let { isPayPerUse } = this.props;
    if (serviceType === GAS
      && commonBenefits.vehicles?.length === 1
      && commonBenefits.vehicles[0].fuelType
      && typeof commonBenefits.vehicles[0].fuelType === 'string'
      && commonBenefits.vehicles[0].fuelType.toLowerCase() === 'electric') {
      serviceEnabled = false;
    }

    let mileageBenefits = null;
    let coverageUsedUpMessage = null;
    if (commonBenefits?.benefits && commonBenefits?.services?.length) {
      if (serviceType === TOW && (isMilesBased || experience.variableCoverage)) {
        const coveredMilesValue = commonBenefits.services.filter(service => service.type === TOW)[0]?.towIncludedQty;
        const isCoveredMilesLimited = coveredMilesValue && coveredMilesValue !== "N/A" ? coveredMilesValue : null;
        mileageBenefits = isCoveredMilesLimited ? `${getTranslatedText(TRANSLATION_CONSTANTS.SERVICE_COVERAGE_UPTO)} ${coveredMilesValue} ${getTranslatedText(TRANSLATION_CONSTANTS.MILES)}` : null;
      };

      if (serviceType === GAS) {
        let gasService = commonBenefits.services.filter(service => service.type === GAS)[0];
        if(gasService?.fuelPaymentRequired) {
          const fuelPrice = this.determineFuelPriceByCountry(gasService, partnerCode);
          if (!isNaN(fuelPrice)) {
            const formatFuelPrice = Math.abs(fuelPrice).toFixed(2);
            this.props.updatePpuFuelPrice(formatFuelPrice);
            localStorage.setItem('ppuFuelPrice', formatFuelPrice);
            mileageBenefits = `${getTranslatedText(TRANSLATION_CONSTANTS.FUEL_PRICE)} $${fuelPrice}`;
          }
        }
      }
    }
    const currentService = commonBenefits?.services?.filter(service => service.type === serviceType)[0];
    if(commonBenefits.experience?.dollarBased && !commonBenefits.experience?.serviceBased && currentService?.type === TOW) {
      const coverageAmountService = currentService?.coverageAmount;

      if(coverageAmountService) mileageBenefits =  `${getTranslatedText(TRANSLATION_CONSTANTS.SERVICE_COVERAGE_UPTO)} $${coverageAmountService}`;
    }

    if(commonBenefits.experience?.coverageFollowsService && commonBenefits?.services?.length) {
      const serviceFiltered = commonBenefits.services.filter(service => service.type === serviceType)[0];
      const coverageUsedUp = serviceFiltered ? serviceFiltered.claimCount >= serviceFiltered.maxClaimCount : false;
      if (coverageUsedUp) {
        coverageUsedUpMessage = getTranslatedText(TRANSLATION_CONSTANTS.CLAIMS_USED_UP)
      }
      if(coverageUsedUp && commonBenefits.experience?.ppuEnabled) {
        isPayPerUse = true;
        mileageBenefits = null;
      } else {
        isPayPerUse = false;
      }
    }

    const [pricingLabel, outOfPocketPricing, price] = this.determinePricing(getTranslatedText, serviceDetail, currentService, serviceType)
    const serviceErrorMessage = outOfPocketPricing ? outOfPocketPricing : coverageUsedUpMessage;
    if(pricingLabel) {
      mileageBenefits = null;
    }

    const { id, serviceText, icon, iconSize, cost } = serviceDetail;
    const translatedText = serviceTranslatedText(serviceText);

    return serviceEnabled && (
        <div className="l-grid__col" key={id + serviceText}>
          <SelectablePanel
            ariaLabel={getTranslatedText(translatedText)}
            text={getTranslatedText(translatedText)}
            price={isPayPerUse ? cost : price}
            pricingLabel={pricingLabel}
            name="radiooptions"
            id={serviceText}
            value={serviceText}
            type="button"
            role="link"
            onClick={this._onChangeDispatcher}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                this._onChangeDispatcher(e);
              }
            }}
            containerclass="d-flex"
            serviceclass="flex-item-1-grow-1"
            mileageBenefits={mileageBenefits}
            errorMessage={serviceErrorMessage}
          >
            <Icon
              icon={icon}
              size={iconSize}
              className="panel-icon flex-item-2"
              color="brand"
            />
            <div className="flex-item-3"></div>
          </SelectablePanel>
        </div>
      );
  }

  determinePricing = (getTranslatedText, serviceDetail, currentService, serviceType) => {
    const { commonBenefits, isDollarBased, isMilesBased } = this.props;
    if (_isEmpty(commonBenefits?.status)) {
      return ["", "", ""];
    }
    const { experience = {}, amcCoverageAmount = "" } = commonBenefits;
    const { serviceBased = false, variableCoverage = false } = experience;
    const serviceStatus = currentService?.status ?? "";
    const { cost = 0 } = serviceDetail;
    let pricingLabel = "";
    let outOfPocketPricing = "";
    let price = "";
    const benefitStatus = commonBenefits?.status?.toUpperCase() ?? "";
    if (benefitStatus === CONSTANTS.MUST_SHOW_NO_BENEFITS_PRICING_INFO) {
      pricingLabel = getTranslatedText(TRANSLATION_CONSTANTS.START_AT_PRICING_INFO);
      price = cost;
    } else if(serviceBased && serviceStatus === CONSTANTS.SERVICE_STATUS_COVERED && !amcCoverageAmount) {
      const unlimitedServices = experience?.unlimitedServices ?? [];
      if((!variableCoverage && !isDollarBased && isMilesBased && serviceType !== TOW) || unlimitedServices.includes(serviceType)) {
        pricingLabel = getTranslatedText(TRANSLATION_CONSTANTS.SERVICE_COVERED);
      } else if(isDollarBased || (variableCoverage && serviceType !== TOW)) {
        pricingLabel = getTranslatedText(TRANSLATION_CONSTANTS.SERVICE_COVERAGE_UPTO);
        price = `${currentService.coverageAmount}` ?? "";
      }
    } else if(serviceBased && serviceStatus === CONSTANTS.SERVICE_STATUS_NOT_COVERED) {
      outOfPocketPricing = getTranslatedText(TRANSLATION_CONSTANTS.OUT_OF_POCKET).replace("{{price}}", `$${cost}`);
    }
    return [pricingLabel, outOfPocketPricing, price];
  };

  determineFuelPriceByCountry(gasService, partnerCode) {
    return isGMPartnerCode(partnerCode)
    ? gasService.fuelQtyCan * gasService.unitCostLiters
    : gasService.fuelQty * gasService.unitCostGallons;
  }

  renderCancelled(getTranslatedText) {
    let wasPpu = localStorage.getItem('isPpu')

    const messageText = wasPpu && wasPpu === 'true'
      ? getTranslatedText(TRANSLATION_CONSTANTS.TAKE_UP_5_DAYS_CREDIT_REFUND)
      : getTranslatedText(TRANSLATION_CONSTANTS.CANCEL_REQUEST_SUCCESFULLY);

    return (
      <div>
        <ServiceCancelled messageText={messageText}/>
      </div>
    );

  }

  renderFeedbackConfirmation() {
    return (
      <div>
        <FeedbackConfirmation/>
      </div>
    );
  }

  determineBenefits = () => {
    const {
      commonBenefits,
      partnerDetails: {
        partnerDetails: {
          experience
        }
      },
      isPayPerUse,
      isDollarBased,
      hasPepSession,
    } = this.props;
    const { rescueCancelled } = this.state;

    let showBenefitsSection = false;

    if (!isPayPerUse) {
      localStorage.setItem('commonBenefits', JSON.stringify(commonBenefits));
    }

    if (!isPayPerUse) {
      if (isDollarBased) {
        showBenefitsSection = true;
      }
      localStorage.setItem('memberId', commonBenefits.memberId);
      if (commonBenefits.status === CONSTANTS.MUST_SHOW_NO_BENEFITS_PRICING_INFO) {
        showBenefitsSection = true;
      }
    } else {
      localStorage.removeItem('memberId');
      if (experience?.benefitsEnabled) {
        showBenefitsSection = !rescueCancelled;
      }
      if(hasPepSession) {
        showBenefitsSection = true;
      }
    }

    return {
      showBenefitsSection,
      benefitsMessage: commonBenefits.errorMessage
    };
  }

  showPricingInfoModal = () => {
    this.setState({
      showPricingInfoModal: true
    })
  }

  closePricingInfoModal = () => {
    this.setState({
      showPricingInfoModal: false
    })
  }

  render() {
    const {
      showContinueAsPPUForServiceModal,
      showPricingInfoModal,
    } = this.state;
    const {
      serviceDetails,
      commonBenefits,
      partnerCode,
      isPayPerUse,
      isPpuEnabled,
      partnerDetails = {},
    } = this.props;

    let showBenefitsSection = false;
    let benefitsMessage;
    if ((commonBenefits?.loading === false && commonBenefits?.error === false && !commonBenefits.noBenefits) || (commonBenefits?.noBenefits && isPayPerUse)) {
      ({ showBenefitsSection, benefitsMessage } = this.determineBenefits());
    }

    const showServiceBasedBanner = commonBenefits?.services?.some((currentService) => {
      const serviceStatus = currentService?.status ?? "";
      return serviceStatus === CONSTANTS.SERVICE_STATUS_NOT_COVERED;
    });

    const showReasonNotCoveredBanner = NOT_COVERED_REASONS.find(pair => pair.id === commonBenefits.status?.toLowerCase())

    return (
      <LocaleConsumer>
        {(getTranslatedText) => (
        <div>
          <CommonBenefits history={this.props.history} />
          <div role="main" className={`l-container-home ${this.state.homeStatic ? ' static' : ''}`}>
            {this.renderSpinnerModal()}
            <CarAnn/>
            {this.state.rescueCancelled && (isPpuEnabled || partnerDetails?.partnerDetails?.experience?.passThruEnabled) && this.renderCancelled(getTranslatedText)}
            {this.state.feedbackConfirmation ? this.renderFeedbackConfirmation() : ''}

            <h1 id='how-can-we-help-you' className={`u-vr-3-top l-container-home__hd u-vr-3 u-hr-2 u-hr-2-left`}>
              {getTranslatedText(TRANSLATION_CONSTANTS.HOW_HELP_TEXT)}
            </h1>

            <div className="l-container-home__bd">
              {(showBenefitsSection || partnerCode !== 'ETL') ? null : this.renderSpinnerModal()}
              {(showBenefitsSection || showServiceBasedBanner) ?
                <Benefits benefitsAmt={commonBenefits.coverageAmount}
                          message={getTranslatedText(errorTranslatedText(benefitsMessage))}
                          showServiceBasedBanner={showServiceBasedBanner}
                          showReasonNotCoveredBanner ={showReasonNotCoveredBanner}
                          handleOnClick={this.showPricingInfoModal}/>
                : null
              }
              <div className="l-grid u-hr u-hr-left">
                {_map(serviceDetails.serviceDetails, this.renderServiceDetails.bind(this, getTranslatedText))}
                {showContinueAsPPUForServiceModal.active &&
                  <AlertModal
                    title={"Claims Used Up"}
                    content={"You may continue and pay for just today's service."}
                    primaryButton="CONTINUE"
                    primaryButtonCallback={this.suspendedBtnGuest}
                    secondaryButton="CANCEL"
                    secondaryButtonCallback={this.suspendedBtnCancel}
                  />}
                <div className="service-selection-notes-container">
                  <button
                    to="/"
                    onClick={() => {this.openHelpMeChoose()}}
                    id="more-options-link"
                    className="help-me-choose-text">
                    {getTranslatedText(TRANSLATION_CONSTANTS.MORE_OPTIONS)}
                  </button>
                </div>
              </div>

              <ModalDialogAlt id="choosehelp"
                              title={getTranslatedText(TRANSLATION_CONSTANTS.TELL_WHICH_BEST_DESCRIBES_SITUATION)}
                              isSlideDrawer
                              hideTrigger
                              showCloseBtn={true}
                              isActive={this.state.helpMeChooseModal}
                              onClose={this.closeHelpMeChoose.bind(this)}
                              footer={
                                this.state.helpMeChooseButtonDisabled ?
                                  <Button
                                    size='lg'
                                    hasUpgrade
                                    id="call-continue"
                                    onClick={this.continueToTow}
                                    utils={{
                                      fullWidth: true
                                    }}>
                                    {getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE)}
                                  </Button>
                                  : null
                              }
              >
                <div className="text_left">
                  <FormOption
                    label={getTranslatedText(TRANSLATION_CONSTANTS.STUCK_IN_DITCH)}
                    value="stuck"
                    name="helpmechoose"
                    type="radio"
                    onClick={this.enableHelpMeChooseModalButton}
                    onKeyDown={(e) => {
                      if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 13) {
                        this.enableHelpMeChooseModalButton()
                      }
                    }}
                  />

                  <div className="u-vr-1-top"/>

                  <FormOption
                    label={getTranslatedText(TRANSLATION_CONSTANTS.CAR_NEEDS_RECHARGING)}
                    value="recharge"
                    name="helpmechoose"
                    type="radio"
                    onClick={this.enableHelpMeChooseModalButton}
                    onKeyDown={(e) => {
                      if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 13) {
                        this.enableHelpMeChooseModalButton()
                      }
                    }}
                  />

                  <div className="u-vr-1-top"/>
                </div>
              </ModalDialogAlt>
              <PricingInfoModal
                showModal={showPricingInfoModal}
                handleOnClose={this.closePricingInfoModal}
                serviceDetails={serviceDetails?.serviceDetails || []}
              />
            </div>
          </div>
        </div>
        )}
      </LocaleConsumer>
    );
  }
}

function mapStateToProps(state) {
  return {
    serviceRequestPayload: state.serviceRequestPayload,
    serviceInfo: state.serviceInfo,
    serviceDetails: state.serviceDetails,
    accessToken: state.accessToken,
    partnerDetails: state.partnerDetails,
    commonBenefits: state.commonBenefits,
    userType: state.partnerDetails.partnerDetails.route,
    partnerCode: state.partnerDetails.partnerDetails.displayCode,
    isPayPerUse: isPayPerUseSelector(state),
    partnerDetailsLoaded: partnerDetailsLoadedSelector(state),
    isPpuEnabled: state.partnerDetails.partnerDetails.experience?.ppuEnabled,
    isMilesBased: state.partnerDetails.partnerDetails.experience?.milesBased,
    isDollarBased: state.partnerDetails.partnerDetails.experience?.dollarBased,
    canDetermineFinalCost: state.partnerDetails.partnerDetails.experience?.canDetermineFinalCost,
    submitRequest: state.submitRequest,
    hasPepSession: hasPepSessionSelector(state),
  };
}

const mapDispatchToProps = (dispatch) => ({
  updateServiceInfo: (data) => dispatch(updateServiceInfo(data)),
  updateFirstName: (data) => dispatch(updateFirstName(data)),
  updateLastName: (data) => dispatch(updateLastName(data)),
  refreshServiceRequestInfo: (data) => dispatch(refreshServiceRequestInfo(data)),
  selectedMakeModelYear: (data) => dispatch(selectedMakeModelYear(data)),
  requestPartnerDetails: (data) => dispatch(requestPartnerDetails(data)),
  updatePpuFlag: (data) => dispatch(updatePpuFlag(data)),
  updateCCardInfo: (data) => dispatch(updateCCardInfo(data)),
  updateCurrentLocation: (data) => dispatch(updateCurrentLocation(data)),
  updateServiceTransition: (data) => dispatch(updateServiceTransition(data)),
  updatePartnerDetailsExperience: (data) => dispatch(updatePartnerDetailsExperience(data)),
  updateIsPrimaryLookup: (data) => dispatch(updateIsPrimaryLookup(data)),
  updateLoggedInFlag: (data) => dispatch(updateLoggedInFlag(data)),
  updatePpuFuelPrice: (data) => dispatch(updatePpuFuelPrice(data)),
  updatePromoCode: (data) => dispatch(updatePromoCode(data)),
  clearServiceTypeOptions: () => dispatch(clearServiceTypeOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
