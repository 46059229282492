import React, { useState } from 'react';
import { Grid, FormField, Icon, Tooltip, Flex, Type, Button } from 'mesh-component-library';
import { QuestionIcon } from "mesh-icon-library";
import CONSTANTS from '../../app-consts/appConstants';
import { useLocale } from '../../contexts/LocaleContext';
import { TRANSLATION_CONSTANTS } from '../../app-consts/translations';
import { errorMessageTranslatedText } from '../../utils/translatedTextUtils';

const MotorNumberLookup = ({ dispatchCommonBenefitsLookup, appId, showModal, closeModal, currentFormValidations, enhancedAuthPartner }) => {
  //20-25,40-49,53-55,61
  const regex = currentFormValidations.motorVehicleNumber?.regex;
  const errorMessage = currentFormValidations.motorVehicleNumber?.errorMessage;
  const maxLength = currentFormValidations.motorVehicleNumber?.maxLength;
  const [motorVehicleNumber, setMotorVehicleNumber] = useState('');
  const [motorVehicleNumberHasError, setMotorVehicleNumberHasError] = useState(false);
  const getTranslatedText = useLocale();

  const continueClick = () => {
    let urlParams = new URLSearchParams(window.location.search);
    const arsTokenParameter = urlParams.get('ARS_TOKEN') || urlParams.get('ars_token');
    const user = {};
    user.memberId = motorVehicleNumber;
    user.phoneLookup = false;
    user.appId = appId;
    user.lookupType = 'policyNumber';
    if(enhancedAuthPartner) {
      user.arsToken = arsTokenParameter;
    }
    dispatchCommonBenefitsLookup(user);
  };

  const onMotorVehicleNumberChange = (e) => {
    const motorVehicleNumber = e.target?.value;
    let isValid = true;
    if (regex && motorVehicleNumber) {
      const stringToRegex = new RegExp(regex);
      isValid = stringToRegex.test(motorVehicleNumber);
    }
    setMotorVehicleNumber(motorVehicleNumber);
    setMotorVehicleNumberHasError(!isValid);
  };

  const onSubmit = () => {
    const confirmText = `${getTranslatedText(TRANSLATION_CONSTANTS.MOTOR_VEHICLE_NUMBER)}:`
    const content = <div>
      <p><span>{confirmText}</span> {motorVehicleNumber}</p>
    </div>
    const options = {
      titleHtml: content,
      yesNoSelected,
    }

    showModal(options);
  };

  const yesNoSelected = async ({ target: { value } }) => {
    closeModal()
    if (value === "yes") {
      continueClick()
    }
  }

  const handleMotorVehicleNumberError = (e) => {
    if (e.keyCode !== CONSTANTS.DELETE && e.keyCode !== CONSTANTS.BACKSPACE && e.target.value !== undefined && (e.keyCode === CONSTANTS.EXPONENTIAL || e.keyCode === CONSTANTS.DECIMALPOINT)) {
      e.preventDefault();
    } else {
      setMotorVehicleNumberHasError(true);
    }
  };
  return (
    <Grid verticalAlign="middle">
      <Grid.Column>
        <Type
          id="we-need-some-info-to-find-your-benefits"
          element="h1"
          weight="thin"
          variant="darker"
          align="left"
          size="4xl"
          utils={{
            vrBottom: "lg"
          }}>
          {getTranslatedText(TRANSLATION_CONSTANTS.NEED_INFO_BENEFITS)}
        </Type>
      </Grid.Column>
      <Grid.Column
        utils={{
          vrBottom: "sm"
        }}>
        <FormField
          size="lg"
          type="number"
          inputMode="numeric"
          id="motorVehicleNumberId"
          name="motorVehicleNumberId"
          label={<Flex alignItems="stretch" justifyContent="between" isBlock={true}>
            <Type size="l" weight="bold" emphasis="information">
              {getTranslatedText(TRANSLATION_CONSTANTS.MOTOR_VEHICLE_NUMBER)}
            </Type>
              <Tooltip
                id="mva-tooltip"
                size="lg"
                text={getTranslatedText(TRANSLATION_CONSTANTS.PLEASE_ENTER_YOUR_MVA)}
              >
                <Icon icon={QuestionIcon} color="#0033A0"
                  size={1.2} />
              </Tooltip>
          </Flex>}
          labelProps={{
            style: {
              width: "100%"
            }
          }}
          errorText={getTranslatedText(errorMessageTranslatedText(errorMessage))}
          value={motorVehicleNumber}
          onKeyDown={handleMotorVehicleNumberError}
          onChange={onMotorVehicleNumberChange}
          hasError={motorVehicleNumberHasError}
          required
          maxLength={maxLength}
          data-testid="mva-field"
        />
      </Grid.Column>
      <Grid.Column>
      <Button
          size='lg'
          hasUpgrade
          id="lookup-continue"
          disabled={!motorVehicleNumber || motorVehicleNumberHasError}
          onClick={onSubmit}
          data-testid="motor-vehicle-number-continue"
          utils={{
            fullWidth: true
          }}>
          {getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE)}
        </Button>
      </Grid.Column>
    </Grid>
  );
};

export default MotorNumberLookup;
