
export const TRANSLATION_CONSTANTS = {
  CLOCK_UPDATED:'CLOCK_UPDATED',
  REQUEST_SUMMARY: "REQUEST_SUMMARY",
  REFERENCE_NUMBER: "REFERENCE_NUMBER",
  CANCEL_RESCUE: "CANCEL_RESCUE",
  FINDING_NEARBY_RESCUER: "FINDING_NEARBY_RESCUER",
  WELL_LET_YOU_KNOW: "WELL_LET_YOU_KNOW",
  TRACKING_YOUR_RESCUE: "TRACKING_YOUR_RESCUE",
  TRACK_AND_MANAGE: "TRACK_AND_MANAGE",
  WELL_TAKE_IT_FROM_HERE: "WELL_TAKE_IT_FROM_HERE",
  PICKUP_LOCATION: "PICKUP_LOCATION",
  DISABLEMENT_LOCATION: "DISABLEMENT_LOCATION",
  DESTINATION_LOCATION: "DESTINATION_LOCATION",
  EXCHANGE_VEHICLE: "EXCHANGE_VEHICLE",
  NEW_VEHICLE_PICKUP_LOCATION: "NEW_VEHICLE_PICKUP_LOCATION",
  NEW_VEHICLE_DROP_0FF_LOCATION: "NEW_VEHICLE_DROP_0FF_LOCATION",
  DISABLED_VEHICLE_PICKUP_LOCATION: "DISABLED_VEHICLE_PICKUP_LOCATION",
  DISABLED_VEHICLE_DROP_0FF_LOCATION: "DISABLED_VEHICLE_DROP_0FF_LOCATION",
  PASSENGER_DROP_OFF_LOCATION: "PASSENGER_DROP_OFF_LOCATION",
  PROVIDER: "PROVIDER",
  CALL: "CALL",
  WEVE_FOUND_A_RESCUER: "WEVE_FOUND_A_RESCUER",
  NOTIFY_OF_PROVIDER_FOUND: "NOTIFY_OF_PROVIDER_FOUND",
  RESCUER_ON_WAY: "RESCUER_ON_WAY",
  STAY_WITH_VEHICLE: "STAY_WITH_VEHICLE",
  RESCUER_ARRIVED: "RESCUER_ARRIVED",
  RESCUER_WITH_VEHICLE: "RESCUER_WITH_VEHICLE",
  MISSION_ACCOMPLISHED: "MISSION_ACCOMPLISHED",
  RESCUE_COMPLETED: "RESCUE_COMPLETED",
  VEHICLE_DROPPED_OFF: "VEHICLE_DROPPED_OFF",
  BACK_ON_THE_ROAD: "BACK_ON_THE_ROAD",
  ASSIGNED: "ASSIGNED",
  EN_ROUTE: "EN_ROUTE",
  ON_SITE: "ON_SITE",
  COMPLETE: "COMPLETE",
  FINDING_PROVIDER: "FINDING_PROVIDER",
  PROVIDER_ASSIGNED: "PROVIDER_ASSIGNED",
  PICKING_REPLACEMENT: "PICKING_REPLACEMENT",
  PROVIDER_EN_ROUTE: "PROVIDER_EN_ROUTE",
  PROVIDER_ARRIVED: "PROVIDER_ARRIVED",
  PASSENGER_DROP_OFF: "PASSENGER_DROP_OFF",
  RESCUE_COMPLETE: "RESCUE_COMPLETE",
  PICKING_REPLACEMENT_DESC: "PICKING_REPLACEMENT_DESC",
  PROVIDER_EN_ROUTE_DESC: "PROVIDER_EN_ROUTE_DESC",
  PASSENGER_DROP_OFF_DESC: "PASSENGER_DROP_OFF_DESC",
  RESCUE_COMPLETE_DESC: "RESCUE_COMPLETE_DESC",
  RESCUE_COMPLETE_PASSENGER_DESC: "RESCUE_COMPLETE_PASSENGER_DESC",
  REPLACEMENT_VEHICLE_DELIVERY: "REPLACEMENT_VEHICLE_DELIVERY",
  DROPPED_OFF: "DROPPED_OFF",
  SERVICE_INCOMPLETE: "SERVICE_INCOMPLETE",
  GONE_ON_ARRIVAL: "GONE_ON_ARRIVAL",
  RESCUE_STEP: "RESCUE_STEP",
  ROADSIDE_ASSISTANCE: "ROADSIDE_ASSISTANCE",
  PROVIDED_BY: "PROVIDED_BY",
  PRIVACY_POLICY: "PRIVACY_POLICY",
  SMS_TERMS_CONDITION: "SMS_TERMS_CONDITION",
  NOTICE_AT_COLLECTION: "NOTICE_AT_COLLECTION",
  WEBSITE_ACCESSIBILITY: "WEBSITE_ACCESSIBILITY",
  CALCULATED_NO_GPS: "CALCULATED_NO_GPS",
  CALCULATED_GPS: "CALCULATED_GPS",
  ETA_UPDATED: "ETA_UPDATED",
  MOBILE_APP_LABEL: "MOBILE_APP_LABEL",
  SERVICE_CANCELLED: "SERVICE_CANCELLED",
  HELP_TEXT: "HELP_TEXT",
  CANCEL_ERROR: "CANCEL_ERROR",
  RESCUE_STATUS_ERROR: "RESCUE_STATUS_ERROR",
  FAMILY_AND_FRIENDS: "FAMILY_AND_FRIENDS",
  SHARE: "SHARE",
  TEXT: "TEXT",
  CANCEL_RESCUE_CONFIRMATION_HEADER:"CANCEL_RESCUE_CONFIRMATION_HEADER",
  CANCEL_RESCUE_AFTER_CONFIRMATION_BODY:"CANCEL_RESCUE_AFTER_CONFIRMATION_BODY",
  CANCEL_RESCUE_AFTER_CONFIRMATION_OPTION_TEXT1:"CANCEL_RESCUE_AFTER_CONFIRMATION_OPTION_TEXT1",
  CANCEL_RESCUE_AFTER_CONFIRMATION_OPTION_TEXT2:"CANCEL_RESCUE_AFTER_CONFIRMATION_OPTION_TEXT2",
  CANCEL_RESCUE_AFTER_CONFIRMATION_OPTION_TEXT3:"CANCEL_RESCUE_AFTER_CONFIRMATION_OPTION_TEXT3",
  CANCEL_RESCUE_BUTTON_TEXT:"CANCEL_RESCUE_BUTTON_TEXT",
  VIN_FIND_INSTRUCTIONS: "VIN_FIND_INSTRUCTIONS",
  HOW_HELP_TEXT: "HOW_HELP_TEXT",
  TOW: "TOW",
  LOCKOUT: "LOCKOUT",
  FUEL_DELIVERY: "FUEL_DELIVERY",
  TIRE_CHANGE: "TIRE_CHANGE",
  JUMP_START: "JUMP_START",
  MORE_OPTIONS: "MORE_OPTIONS",
  CONFIRM_DROP_OFF_LOCATION: "CONFIRM_DROP_OFF_LOCATION",
  LOCATION_IS: "LOCATION_IS",
  INSTRUCTIONS_CUSTOMER_SERVICE: "INSTRUCTIONS_CUSTOMER_SERVICE",
  CALL_CUSTOMER_SERVICE: "CALL_CUSTOMER_SERVICE",
  ESTIMATED_AMOUNT_DUE: "ESTIMATED_AMOUNT_DUE",
  ESTIMATED_MINIMUM_DUE: "ESTIMATED_MINIMUM_DUE",
  CONFIRM_CONTACT: "CONFIRM_CONTACT",
  CONFIRM_VEHICLE: "CONFIRM_VEHICLE",
  COLOR: "COLOR",
  FUEL_TYPE: "FUEL_TYPE",
  UNLEADED: "UNLEADED",
  DIESEL: "DIESEL",
  DELIVER_UP_TO: "DELIVER_UP_TO",
  COST_FUEL_NOT_INCLUDED: "COST_FUEL_NOT_INCLUDED",
  ADD_CONTACT: "ADD_CONTACT",
  NAME: "NAME",
  EMAIL: "EMAIL",
  PHONE_NUMBER: "PHONE_NUMBER",
  PROVIDE_PHONE_NUMBER: "PROVIDE_PHONE_NUMBER",
  SEND_TEXT_MESSAGES: "SEND_TEXT_MESSAGES",
  CONFIRM: "CONFIRM",
  SUBMIT_REQUEST: "SUBMIT_REQUEST",
  SCHEDULE_A_RIDE: "SCHEDULE_A_RIDE",
  HAVE_LYFT_READY: "HAVE_LYFT_READY",
  SCHEDULE: "SCHEDULE",
  HOW_EMBARRASSING: "HOW_EMBARRASSING",
  TECHNICAL_DIFFICULTIES: "TECHNICAL_DIFFICULTIES",
  CALL_HELP_NOW: "CALL_HELP_NOW",
  CALL_FOR_ASSISTANCE: "CALL_FOR_ASSISTANCE",
  ENTER_INFO_AS_ON_ACCOUNT: "ENTER_INFO_AS_ON_ACCOUNT",
  SORRY_NOT_FIND_BENEFITS: "SORRY_NOT_FIND_BENEFITS",
  CAPTURE_ACTUAL_MILEAGE: "CAPTURE_ACTUAL_MILEAGE",
  ENTER_CURRENT_LOCATION: "ENTER_CURRENT_LOCATION",
  CONFIRM_VEHICLE_LOCATION: "CONFIRM_VEHICLE_LOCATION",
  GEOLOCATION_DISABLED: "GEOLOCATION_DISABLED",
  HOME: "HOME",
  CALL_FOR_HELP: "CALL_FOR_HELP",
  PROVIDE_VEHICLE_INFO: "PROVIDE_VEHICLE_INFO",
  STATE: "STATE",
  ENTER_STATE: "ENTER_STATE",
  SELECT_A_STATE: "SELECT_A_STATE",
  LICENSE_PLATE_NUMBER: "LICENSE_PLATE_NUMBER",
  INVALID_LICENSE_PLATE_NUMBER: "INVALID_LICENSE_PLATE_NUMBER",
  CONTINUE: "CONTINUE",
  OR: "OR",
  SEARCH_BY_VIN: "SEARCH_BY_VIN",
  VIN: "VIN",
  ENTER_VALID_VIN: "ENTER_VALID_VIN",
  ODOMETER: "ODOMETER",
  ENTER_VALID_MILEAGE: "ENTER_VALID_MILEAGE",
  SEARCH_BY_LICENSE_PLATE: "SEARCH_BY_LICENSE_PLATE",
  ALL_RIGHT_RESERVED: "ALL_RIGHT_RESERVED",
  IS_INFORMATION_CORRECT: "IS_INFORMATION_CORRECT",
  YES: "YES",
  NO: "NO",
  IS_WHERE_YOUR_VEHICLE_IS: "IS_WHERE_YOUR_VEHICLE_IS",
  ADD_LOCATION_DETAILS: "ADD_LOCATION_DETAILS",
  EXAMPLE_STREET: "EXAMPLE_STREET",
  SAVE: "SAVE",
  CHARACTERS_REMAINING: "CHARACTERS_REMAINING",
  MEMBER_NOT_FOUND: "MEMBER_NOT_FOUND",
  NO_BENEFITS_AVAILABLE: "NO_BENEFITS_AVAILABLE",
  DID_NOT_PURCHASE_COVERAGE: "DID_NOT_PURCHASE_COVERAGE",
  COVERAGE_UP_TO: "COVERAGE_UP_TO",
  EXPERIMENTAL_AVAILABLE_BENEFITS: "EXPERIMENTAL_AVAILABLE_BENEFITS",
  TELL_WHICH_BEST_DESCRIBES_SITUATION: "TELL_WHICH_BEST_DESCRIBES_SITUATION",
  STUCK_IN_DITCH: "STUCK_IN_DITCH",
  CAR_NEEDS_RECHARGING: "CAR_NEEDS_RECHARGING",
  SELECT_TOW_LOCATION: "SELECT_TOW_LOCATION",
  SELECT_OR_ENTER_TOW_DESTINATION: "SELECT_OR_ENTER_TOW_DESTINATION",
  NO_REPAIR_CENTERS_FOUND: "NO_REPAIR_CENTERS_FOUND",
  SELECT_ONE: "SELECT_ONE",
  BLACK: "BLACK",
  BLUE: "BLUE",
  BROWN: "BROWN",
  BURGUNDY: "BURGUNDY",
  GOLD: "GOLD",
  GREEN: "GREEN",
  GREY: "GREY",
  ORANGE: "ORANGE",
  PURPLE: "PURPLE",
  RED: "RED",
  SILVER: "SILVER",
  WHITE: "WHITE",
  YELLOW: "YELLOW",
  WHICE_DRIVER_NEEDS_HELP: "WHICE_DRIVER_NEEDS_HELP",
  VEHICLE: "VEHICLE",
  PAYMENT: "PAYMENT",
  COVERED_BY_BENEFITS: "COVERED_BY_BENEFITS",
  FINAL_COST_WILL_BE_DETERMINED: "FINAL_COST_WILL_BE_DETERMINED",
  TOTAL: "TOTAL",
  WHERE_ARE_YOUR_KEYS: "WHERE_ARE_YOUR_KEYS",
  IN_MY_VEHICLE: "IN_MY_VEHICLE",
  IN_MY_TRUNK: "IN_MY_TRUNK",
  LOST_BROKEN: "LOST_BROKEN",
  CONFIRM_VEHICLE_INFORMATION: "CONFIRM_VEHICLE_INFORMATION",
  WE_ARE_SORRY: "WE_ARE_SORRY",
  COVERAGE_SUSPENDED_CONTINUE_PPU: "COVERAGE_SUSPENDED_CONTINUE_PPU",
  COVERAGE_SUSPENDED_CONTACT_REPRESENTATIVE: "COVERAGE_SUSPENDED_CONTACT_REPRESENTATIVE",
  WHICH_TIRE_IS_FLAT: "WHICH_TIRE_IS_FLAT",
  HAVE_A_SPARE_TIRE: "HAVE_A_SPARE_TIRE",
  LEAVE_PAGE_INFO: "LEAVE_PAGE_INFO",
  VEHICLE_STOP_DRIVING: "VEHICLE_STOP_DRIVING",
  BATTER_MOST_LIKELY_PROBLEM: "BATTER_MOST_LIKELY_PROBLEM",
  NO_WORRIES_HAPPENS: "NO_WORRIES_HAPPENS",
  SERVICE_CENTERS_EQUIPPED: "SERVICE_CENTERS_EQUIPPED",
  WITHOUT_A_SPARE_TOW_VEHICLE: "WITHOUT_A_SPARE_TOW_VEHICLE",
  DEFAULT: "DEFAULT",
  SINCE_KEYS_LOST_BROKEN: "SINCE_KEYS_LOST_BROKEN",
  SINCE_KEYS_LOST_BROKEN_TOW_ENABLED: "SINCE_KEYS_LOST_BROKEN_TOW_ENABLED",
  CHECKED_TRUNK_RELEASE_HATCH: "CHECKED_TRUNK_RELEASE_HATCH",
  NEED_A_NEW_TIRE: "NEED_A_NEW_TIRE",
  NEED_A_NEW_TIRE_TOW_ENABLED: "NEED_A_NEW_TIRE_TOW_ENABLED",
  CONTINUE_TO_TOW_VEHICLE: "CONTINUE_TO_TOW_VEHICLE",
  CONTINUE_TO_TOW_VEHICLE_TOW_ENABLED: "CONTINUE_TO_TOW_VEHICLE_TOW_ENABLED",
  CONTINUE_TO_TOW: "CONTINUE_TO_TOW",
  STARTS_AT: "STARTS_AT",
  NO_THANKS_UNLOCK_MY_VEHICLE: "NO_THANKS_UNLOCK_MY_VEHICLE",
  SORRY_YOU_HAVE_REACHED: "SORRY_YOU_HAVE_REACHED",
  LIMIT: "LIMIT",
  CAN_STILL_HELP_THANK: "CAN_STILL_HELP_THANK",
  CAN_STILL_HELP: "CAN_STILL_HELP",
  USED_ALL_YOUR_BENEFITS: "USED_ALL_YOUR_BENEFITS",
  VEHICLE_WEIGHT_LIMIT: "VEHICLE_WEIGHT_LIMIT",
  WEIGHT_CLASS_SPECIAL_SERVICE: "WEIGHT_CLASS_SPECIAL_SERVICE",
  RUN_INTO_ISSUES_BENEFITS: "RUN_INTO_ISSUES_BENEFITS",
  ENTER_FIRST_LAST_NAME: "ENTER_FIRST_LAST_NAME",
  SELECT_A_DRIVER: "SELECT_A_DRIVER",
  ENTER_VALID_EMAIL_ADDRESS: "ENTER_VALID_EMAIL_ADDRESS",
  ENTER_PHONE_NUMBER: "ENTER_PHONE_NUMBER",
  PAYMENT_INFO: "PAYMENT_INFO",
  ENTER_DIGIT_ZIP_CODE: "ENTER_DIGIT_ZIP_CODE",
  DRIVER: "DRIVER",
  CONTACT_PAYMENT: "CONTACT_PAYMENT",
  TAKE_UP_5_DAYS_CREDIT_REFUND: "TAKE_UP_5_DAYS_CREDIT_REFUND",
  CANCEL_REQUEST_SUCCESFULLY: "CANCEL_REQUEST_SUCCESFULLY",
  APPRECIATE_FEEDBACK: "APPRECIATE_FEEDBACK",
  CONTINUE_AS_GUEST: "CONTINUE_AS_GUEST",
  USE_PPU_OPTION_TO_PAY: "USE_PPU_OPTION_TO_PAY",
  MISSION_ACCOMPLISHED_SHARE_FEEDBACK: "MISSION_ACCOMPLISHED_SHARE_FEEDBACK",
  QUICK_UPDATE: "QUICK_UPDATE",
  WE_WILL_CONTINUE_TO_WORK: "WE_WILL_CONTINUE_TO_WORK",
  WE_CAN_HELP: "WE_CAN_HELP",
  GIVE_US_A_CALL: "GIVE_US_A_CALL",
  LOOKS_LIKE_CAN_NOT_CHARGE: "LOOKS_LIKE_CAN_NOT_CHARGE",
  CONTINUE_LIKE_US_TO_TOW: "CONTINUE_LIKE_US_TO_TOW",
  INCLUDED: "INCLUDED",
  ADDITIONAL: "ADDITIONAL",
  OVER: "OVER",
  OVER2: "OVER2", // for use in $10/mile over 10 miles (different translation than first over)
  WEBSITE: "WEBSITE",
  REVIEW_SUMMARY: "REVIEW_SUMMARY",
  REVIEWS: "REVIEWS",
  REPAIR_SHOP_NOT_BEEN_REVIEWED: "REPAIR_SHOP_NOT_BEEN_REVIEWED",
  SHARE_YOUR_FEEBACK: "SHARE_YOUR_FEEBACK",
  HOW_WAS_YOUR_EXPERIENCE_RESCUER: "HOW_WAS_YOUR_EXPERIENCE_RESCUER",
  HOW_WAS_YOUR_EXPERIENCE_SITE: "HOW_WAS_YOUR_EXPERIENCE_SITE",
  SHARE_ANY_ADDITIONAL_COMMENTS: "SHARE_ANY_ADDITIONAL_COMMENTS",
  SUBMIT: "SUBMIT",
  SKIP: "SKIP",
  BENEFITS: "BENEFITS",
  FINAL_COST_WILL_BE_DETERMINED_PROVIDER: "FINAL_COST_WILL_BE_DETERMINED_PROVIDER",
  SERVICE_NOT_AVAILABLE: "SERVICE_NOT_AVAILABLE",
  WE_SORRY_SERVICE_NOT_AVAILABLE: "WE_SORRY_SERVICE_NOT_AVAILABLE",
  ENTER_ADDITIONAL_DETAILS_CURRENT_LOCATION: "ENTER_ADDITIONAL_DETAILS_CURRENT_LOCATION",
  I_E_STREET_NAME: "I_E_STREET_NAME",
  OK: "OK",
  SMS_TERMS_CONDITION_LINK: 'SMS_TERMS_CONDITION_LINK',
  SECURITY_FACILITY: 'SECURITY_FACILITY',
  THIS_LOCATION_IS: 'THIS_LOCATION_IS',
  CLOSED: 'CLOSED',
  CLOSING_SOON: 'CLOSING_SOON',
  RUN_INTO_ISSUES: "RUN_INTO_ISSUES",
  SUSPENDED_VEHICLE_TO_CALL_CENTER: 'SUSPENDED_VEHICLE_TO_CALL_CENTER',
  POLICY_NUMBER: 'POLICY_NUMBER',
  MEMBER_NUMBER: 'MEMBER_NUMBER',
  MOTOR_VEHICLE_NUMBER: 'MOTOR_VEHICLE_NUMBER',
  FIND_POLICY_NUMBER: 'FIND_POLICY_NUMBER',
  FIND_MEMBER_NUMBER: 'FIND_MEMBER_NUMBER',
  LOST_POLICY_NUMBER: 'LOST_POLICY_NUMBER',
  LOST_MEMBER_NUMBER: 'LOST_MEMBER_NUMBER',
  MONDAY_FRIDAY: 'MONDAY_FRIDAY',
  WHAT_IS_THIS: 'WHAT_IS_THIS',
  NEED_INFO_BENEFITS: 'NEED_INFO_BENEFITS',
  PHONE_LOOKUP_FAILURE: 'PHONE_LOOKUP_FAILURE',
  FN_LN_ZIP_LOOKUP_FAILURE: 'FN_LN_ZIP_LOOKUP_FAILURE',
  NAME_STATE_ZIP_LOOKUP_FAILURE: 'NAME_STATE_ZIP_LOOKUP_FAILURE',
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  ZIP: 'ZIP',
  PLEASE_ENTER_FIRST_NAME: 'PLEASE_ENTER_FIRST_NAME',
  PLEASE_ENTER_LAST_NAME: 'PLEASE_ENTER_LAST_NAME',
  PLEASE_ENTER_YOUR_ACCOUNT: 'PLEASE_ENTER_YOUR_ACCOUNT',
  PLEASE_ENTER_YOUR_MVA: 'PLEASE_ENTER_YOUR_MVA',
  PLEASE_ENTER_MEMBER_ID: 'PLEASE_ENTER_MEMBER_ID',
  ZIP_CODE: 'ZIP_CODE',
  AS_APPEARS_ACCOUNT: 'AS_APPEARS_ACCOUNT',
  UP_TO: 'UP_TO',
  MILES: 'MILES',
  MILE: "MILE",
  KILOMETERS: 'KILOMETERS',
  KILOMETER: 'KILOMETER',
  MILES_INCLUDED: 'MILES_INCLUDED',
  KILOMETERS_INCLUDED: 'KILOMETERS_INCLUDED',
  ADDITIONAL_MILES: 'ADDITIONAL_MILES',
  ADDITIONAL_KILOMETERS: 'ADDITIONAL_KILOMETERS',
  FUEL_PRICE: 'FUEL_PRICE',
  USED_ALL_YOUR_BENEFITS_TO_PAY: 'USED_ALL_YOUR_BENEFITS_TO_PAY',
  VEHICLE_INFO: 'VEHICLE_INFO',
  ADD_VEHICLE: 'ADD_VEHICLE',
  PLEASE_ENTER_CAR_MODEL: 'PLEASE_ENTER_CAR_MODEL',
  YEAR: 'YEAR',
  MAKE_MODEL: 'MAKE_MODEL',
  YYYYY: 'YYYYY',
  ADD: 'ADD',
  BILLING_ZIP: 'BILLING_ZIP',
  ERROR_MEMBERSHIP_NUMBER: 'ERROR_MEMBERSHIP_NUMBER',
  ERROR_POLICY_NUMBER: 'ERROR_POLICY_NUMBER',
  PLEASE_CONFIRM_VEHICLE: 'PLEASE_CONFIRM_VEHICLE',
  FOUND: 'FOUND',
  VEHICLES: 'VEHICLES',
  PLEASE_SELECT_OPTION: 'PLEASE_SELECT_OPTION',
  ESTIMATED_MILEAGE: 'ESTIMATED_MILEAGE',
  PLEASE_ENTER_VALID_ESTIMATED_MILEAGE: 'PLEASE_ENTER_VALID_ESTIMATED_MILEAGE',
  MEMBERSHIP_2_DAY_WAIT_PERIOD: 'MEMBERSHIP_2_DAY_WAIT_PERIOD',
  CLAIMS_USED_UP: 'CLAIMS_USED_UP',
  ETA_CALCULATING: 'ETA_CALCULATING',
  FINDING_RESCUER_DESC: "FINDING_RESCUER_DESC",
  FINDING_RESCUER: "FINDING_RESCUER",
  START_OVER_ALERT_MESSAGE: "START_OVER_ALERT_MESSAGE",
  APP_REDIRECTION_ALERT_MESSAGE: "APP_REDIRECTION_ALERT_MESSAGE",
  MEMBER_NOT_FOUND_PRICING_INFO: "MEMBER_NOT_FOUND_PRICING_INFO",
  WE_CANNOT_SERVICE_TITLE: "WE_CANNOT_SERVICE_TITLE",
  REQUEST: "REQUEST",
  PRIMER_HELP: "PRIMER_HELP",
  SHARING_PHONE: "SHARING_PHONE",
  CLICK_ALLOW: "CLICK_ALLOW",
  LOCATION: "LOCATION",
  PRIMER_DATA: "PRIMER_DATA",
  MOTOR_VEHICLE_ALERT_MESSAGE: "MOTOR_VEHICLE_ALERT_MESSAGE",
  FURTHER_ASSISTANCE: "FURTHER_ASSISTANCE",
  TRIAGE_STEPS_TITLE: "TRIAGE_STEPS_TITLE",
  TRIAGE_STEPS_JUMP_START_STEP_ONE_TITLE: "TRIAGE_STEPS_JUMP_START_STEP_ONE_TITLE",
  TRIAGE_STEPS_JUMP_START_STEP_ONE_CONTENT: "TRIAGE_STEPS_JUMP_START_STEP_ONE_CONTENT",
  TRIAGE_STEPS_JUMP_START_STEP_TWO_TITLE: "TRIAGE_STEPS_JUMP_START_STEP_TWO_TITLE",
  TRIAGE_STEPS_JUMP_START_STEP_TWO_CONTENT: "TRIAGE_STEPS_JUMP_START_STEP_TWO_CONTENT",
  TRIAGE_STEPS_JUMP_START_STEP_THREE_TITLE: "TRIAGE_STEPS_JUMP_START_STEP_THREE_TITLE",
  TRIAGE_STEPS_JUMP_START_STEP_THREE_CONTENT: "TRIAGE_STEPS_JUMP_START_STEP_THREE_CONTENT",
  TRIAGE_STEPS_JUMP_START_STEP_FOUR_TITLE: "TRIAGE_STEPS_JUMP_START_STEP_FOUR_TITLE",
  TRIAGE_STEPS_JUMP_START_STEP_FOUR_CONTENT: "TRIAGE_STEPS_JUMP_START_STEP_FOUR_CONTENT",
  TRIAGE_STEPS_JUMP_START_STEP_FIVE_TITLE: "TRIAGE_STEPS_JUMP_START_STEP_FIVE_TITLE",
  TRIAGE_STEPS_JUMP_START_STEP_FIVE_CONTENT: "TRIAGE_STEPS_JUMP_START_STEP_FIVE_CONTENT",
  TRIAGE_STEPS_FIXED: "TRIAGE_STEPS_FIXED",
  TRIAGE_STEPS_CONTINUE: "TRIAGE_STEPS_CONTINUE",
  TRIAGE_STEPS_FUEL_DELIVERY_STEP_ONE_TITLE: "TRIAGE_STEPS_FUEL_DELIVERY_STEP_ONE_TITLE",
  TRIAGE_STEPS_FUEL_DELIVERY_STEP_ONE_CONTENT: "TRIAGE_STEPS_FUEL_DELIVERY_STEP_ONE_CONTENT",
  TRIAGE_STEPS_FUEL_DELIVERY_STEP_TWO_TITLE: "TRIAGE_STEPS_FUEL_DELIVERY_STEP_TWO_TITLE",
  TRIAGE_STEPS_FUEL_DELIVERY_STEP_TWO_CONTENT: "TRIAGE_STEPS_FUEL_DELIVERY_STEP_TWO_CONTENT",
  TRIAGE_STEPS_FUEL_DELIVERY_STEP_THREE_TITLE: "TRIAGE_STEPS_FUEL_DELIVERY_STEP_THREE_TITLE",
  TRIAGE_STEPS_FUEL_DELIVERY_STEP_THREE_CONTENT: "TRIAGE_STEPS_FUEL_DELIVERY_STEP_THREE_CONTENT",
  TRIAGE_STEPS_FUEL_DELIVERY_FINAL_CONTENT: "TRIAGE_STEPS_FUEL_DELIVERY_FINAL_CONTENT",
  TRIAGE_STEPS_FIXED_ALERT_MESSAGE: "TRIAGE_STEPS_FIXED_ALERT_MESSAGE",
  TRIAGE_LOCKOUT_TITLE: "TRIAGE_LOCKOUT_TITLE",
  TRIAGE_LOCKOUT_CONTENT: "TRIAGE_LOCKOUT_CONTENT",
  TRIAGE_LOCKOUT_CONTENT_MEMBER: "TRIAGE_LOCKOUT_CONTENT_MEMBER",
  GET_THE_APP: "GET_THE_APP",
  START_AT_PRICING_INFO: "START_AT_PRICING_INFO",
  RENTAL_EXCHANGE_TITLE: "RENTAL_EXCHANGE_TITLE",
  RENTAL_EXCHANGE_CONTENT: "RENTAL_EXCHANGE_CONTENT",
  MANAGE_MEMBERSHIP: "MANAGE_MEMBERSHIP",
  SEE_MORE: "SEE_MORE",
  WHEN_HELP_TEXT: "WHEN_HELP_TEXT",
  GET_HELP_NOW: "GET_HELP_NOW",
  SCHEDULE_LATER: "SCHEDULE_LATER",
  COMPLIANCE_CONFIRMATION: "COMPLIANCE_CONFIRMATION",
  APPOINTMENT_CONFIRMED: "APPOINTMENT_CONFIRMED",
  HAPPEN_NEXT: "HAPPEN_NEXT",
  APPOINTMENT_SCHEDULED: "APPOINTMENT_SCHEDULED",
  PAYMENT_ISSUE: "PAYMENT_ISSUE",
  NEED_CHANGES: "NEED_CHANGES",
  ANYTHING_HAPPENS: "ANYTHING_HAPPENS",
  VEHICLE_LOCATION: "VEHICLE_LOCATION",
  APPOINTMENT_CANCELLED: "APPOINTMENT_CANCELLED",
  APPOINTMENT_CANCELLED_ERROR: "APPOINTMENT_CANCELLED_ERROR",
  APPOINTMENT_DETAILS_ERROR: "APPOINTMENT_DETAILS_ERROR",
  APPOINTMENT_DETAILS_RESPONSE_ERROR: "APPOINTMENT_DETAILS_RESPONSE_ERROR",
  CANCEL: "CANCEL",
  ALLSTATE_FOOTER_TEXT: "ALLSTATE_FOOTER_TEXT",
  SERVICE_DATE: "SERVICE_DATE",
  PREFERRED_TIME: "PREFERRED_TIME",
  PICK_A_TIME: "PICK_A_TIME",
  ETA_SUGGESTION: "ETA_SUGGESTION",
  CONFIRM_DATE_TIME: "CONFIRM_DATE_TIME",
  CLOSING_SOON_OPEN_UNTIL: "CLOSING_SOON_OPEN_UNTIL",
  TOW_DESTINATION_CLOSED: "TOW_DESTINATION_CLOSED",
  OPEN_UNTIL: "OPEN_UNTIL",
  A_WEEK_AGO: "A_WEEK_AGO",
  X_WEEKS_AGO: "X_WEEKS_AGO",
  A_MONTH_AGO: "A_MONTH_AGO",
  X_MONTHS_AGO: "X_MONTHS_AGO",
  A_YEAR_AGO: "A_YEAR_AGO",
  X_YEARS_AGO: "X_YEARS_AGO",
  SORRY_TO_SEE_YOU_GO: "SORRY_TO_SEE_YOU_GO",
  CALL_FOR_HELP_DESCRIPTION: "CALL_FOR_HELP_DESCRIPTION",
  ISSUE_IN_ROADSIDE_COVERAGE: "ISSUE_IN_ROADSIDE_COVERAGE",
  SCHEDULE_SERVICE_FOR_FUTURE_TIME: "SCHEDULE_SERVICE_FOR_FUTURE_TIME",
  TALK_TO_PERSON: "TALK_TO_PERSON",
  DISABLEMENT_LOCATION_WRONG: "DISABLEMENT_LOCATION_WRONG",
  OTHER_SPECIFY: "OTHER_SPECIFY",
  GO_BACK: "GO_BACK",
  ETA: "ETA",
  CONTACT: "CONTACT",
  INFO_TO_DISPATCH: "INFO_TO_DISPATCH",
  AVERAGE_ESTIMATED_CHARGES: "AVERAGE_ESTIMATED_CHARGES",
  WINCH: "WINCH",
  TOW_SERVICES: "TOW_SERVICES",
  LOST_KEY_TOWING: "LOST_KEY_TOWING",
  KEY_COST_AVG_TOW_COST: "KEY_COST_AVG_TOW_COST",
  ADDITIONAL_COSTS: "ADDITIONAL_COSTS",
  SERVICE_CHARGES: "SERVICE_CHARGES",
  PEP_BOYS_FLEET: "PEP_BOYS_FLEET",
  COMPANY_NAME: "COMPANY_NAME",
  PLEASE_ENTER_YOUR_FIRST_NAME: "PLEASE_ENTER_YOUR_FIRST_NAME",
  PLEASE_ENTER_YOUR_LAST_NAME: "PLEASE_ENTER_YOUR_LAST_NAME",
  WHAT_IS_YOUR_FUEL_TYPE: "WHAT_IS_YOUR_FUEL_TYPE",
  OUT_OF_POCKET: "OUT_OF_POCKET",
  PER_RESCUE: "PER_RESCUE",
  SERVICE_COVERAGE_UPTO: "SERVICE_COVERAGE_UPTO",
  SERVICE_COVERED: "SERVICE_COVERED",
  SERVICE_BASED_BANNER: "SERVICE_BASED_BANNER",
  PROVIDE_MESSAGE: "PROVIDE_MESSAGE",
  PEP_BANNER: "PEP_BANNER",
  NO_SUGGESTIONS: "NO_SUGGESTIONS",
  NO_SUGGESTIONS_CALL_CENTER: "NO_SUGGESTIONS_CALL_CENTER",
  RETRIEVING_LOCATIONS: "RETRIEVING_LOCATIONS",
  ENTER_MANUAL_LOCATION: "ENTER_MANUAL_LOCATION",
  LIVE_CHAT: "LIVE_CHAT",
  MEMBER_NOT_UNIQUELY_FOUND_MESSAGE: "MEMBER_NOT_UNIQUELY_FOUND_MESSAGE",
  CREDIT_CARD_OFFER_TITLE: "CREDIT_CARD_OFFER_TITLE",
  CREDIT_CARD_OFFER_CONTENT: "CREDIT_CARD_OFFER_CONTENT",
  CREDIT_CARD_OFFER_PREQUALIFY: "CREDIT_CARD_OFFER_PREQUALIFY",
  ENTER_COMPANY_NAME: "ENTER_COMPANY_NAME",
  FOOTER_COMPANY_SIGNATURE_CLUB: "FOOTER_COMPANY_SIGNATURE_CLUB",
  FOOTER_COMPANY_SLOGAN: "FOOTER_COMPANY_SLOGAN",
  STATUS_OPEN24: "STATUS_OPEN24",
  STATUS_CLOSED: "STATUS_CLOSED",
  STATUS_CLOSING_SOON: "STATUS_CLOSING_SOON",
  STATUS_OPEN: "STATUS_OPEN",
  STATUS_CLOSED_OPENSAT: "STATUS_CLOSED_OPENSAT",
  PROVIDER_TEN_MINS_ALERT: "PROVIDER_TEN_MINS_ALERT",
  TOWING_CHARGES_NOT_APPLY: "TOWING_CHARGES_NOT_APPLY",
  DUPLICATE_RESCUE_TITLE: "DUPLICATE_RESCUE_TITLE",
  DUPLICATE_RESCUE_DESC: "DUPLICATE_RESCUE_DESC",
  DUPLICATE_RESCUE_SMS_RT_BUTTON_TEXT: "DUPLICATE_RESCUE_SMS_RT_BUTTON_TEXT",
  PETA_ALERT: "PETA_ALERT",
  RESCUE_COMPLETE_REVERSE_TOW: "RESCUE_COMPLETE_REVERSE_TOW",
  PROVIDER_EN_ROUTE_PASSENGER: "PROVIDER_EN_ROUTE_PASSENGER",
  VAS_FUEL_COVERED: "VAS_FUEL_COVERED",
  VAS_FUEL_NOT_COVERED: "VAS_FUEL_NOT_COVERED",
  COVERS_UPTO: "COVERS_UPTO",
  SERVICE_FEE_REDUCTION_REASON: "SERVICE_FEE_REDUCTION_REASON",
  TOW_TRIAGE_ACCIDENT: "TOW_TRIAGE_ACCIDENT",
  TOW_TRIAGE_VANDALISM: "TOW_TRIAGE_VANDALISM",
  TOW_TRIAGE_TIRE_WITHOUT_SPARE: "TOW_TRIAGE_TIRE_WITHOUT_SPARE",
  TOW_TRIAGE_MECHANICAL_KEY: "TOW_TRIAGE_MECHANICAL_KEY",
  TOW_TRIAGE_BATTERY: "TOW_TRIAGE_BATTERY",
  TOW_TRIAGE_OTHER: "TOW_TRIAGE_OTHER",
  TOW_TRIAGE_TITLE: "TOW_TRIAGE_TITLE",
  TOW_TRIAGE_REASON_LABEL: "TOW_TRIAGE_REASON_LABEL",
  PEP_BOYS_OFFERS: "PEP_BOYS_OFFERS",
  PEP_BOYS_COUPON: "PEP_BOYS_COUPON",
  CONTRACT_CANCELLED_MESSAGE: "CONTRACT_CANCELLED_MESSAGE",
  CONTRACT_EXPIRED_MESSAGE: "CONTRACT_EXPIRED_MESSAGE",
  CONTRACT_NOT_ACTIVE_YET_MESSAGE: "CONTRACT_NOT_ACTIVE_YET_MESSAGE",
  MEMBER_NOT_UNIQUELY_FOUND_MESSAGE_POLICY: "MEMBER_NOT_UNIQUELY_FOUND_MESSAGE_POLICY",
  CARD_RETRY_MESSAGE: "CARD_RETRY_MESSAGE",
  TOW_REASON_NOT_COVERED_MSG: "TOW_REASON_NOT_COVERED_MSG",
}
