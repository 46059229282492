import React, { useState, useEffect } from "react";
import { FUEL_SERVICE_ID, GAS, supportedColors } from "../../app-consts/appConstants";
import {
  sendFuelTypeToJacada,
  sendVehicleToJacada
} from "../../jacada/sendJacadaEventData";
import { datadogRum } from "@datadog/browser-rum";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import {
  updateVehicle,
  updateServiceTypeOptions,
  updateVehicleInfo,
  requestMakeModel,
  resetMakeModel
} from "../../action";
import { sendJacadaRequest } from '../../api/api';
import { cloneDeep } from 'lodash';
import { TimelineMax } from 'gsap';
import { generatePath } from '../../utilities/RedirectUtils';
import { useLocale } from "../../contexts/LocaleContext";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  getServiceInfo,
  getServiceRequestPayload,
  getPartnerDetails,
  getCommonBenefits,
  getMakemodel
} from "../../selector";
import { CloseIcon } from "mesh-icon-library";
import {
  Button,
  Divider,
  Dropdown,
  Flex,
  FormOptionGroup,
  Grid,
  Headline,
  Icon,
  Type,
  Utility,
  FormField,
  Autocomplete,
  Loader
} from "mesh-component-library";
import { getTranslatedColors } from "../../utils/translatedTextUtils";
import { FuelAlert } from "../VehicleSelect";
import isEmpty from "lodash/isEmpty";

const AddVehicle = () => {
  const getTranslatedText = useLocale();
  const history = useHistory();
  const dispatch = useDispatch();
  const serviceInfo = useSelector(getServiceInfo);
  const serviceRequestPayload = useSelector(getServiceRequestPayload);
  const partnerDetails = useSelector(getPartnerDetails);
  const commonBenefits = useSelector(getCommonBenefits);
  const makemodel = useSelector(getMakemodel);
  let vehicle = serviceRequestPayload?.serviceRequest.vehicle || serviceRequestPayload?.serviceRequest.vehicles;
  const [vehicleDisabled, setVehicleDisabled] = useState(!vehicle?.make);
  const [yearHasError, setYearHasError] = useState(false);
  const [reRender, setReRender] = useState(true);
  const [vehicleValue, setVehicleValue] = useState(vehicle?.make && vehicle?.model ? `${vehicle?.make} ${vehicle?.model}` : '');
  const [year, setYear] = useState(vehicle?.year || '');
  const [make, setMake] = useState(vehicle?.make || '');
  const [model, setModel] = useState(vehicle?.model || '');
  const [vehicleColor, setVehicleColor] = useState(vehicle?.color || '');
  const [fuelType, setFuelType] = useState(serviceRequestPayload?.serviceRequest.serviceTypeOptions?.gas || '');
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    window.dispatchEvent(new Event("locationchange"));
    if (year) {
      setModelList(year)
    }
  }, []);

  useEffect(() => {
    if (makemodel.payload.length && reRender) {
      setVehicleDisabled(false);
      setReRender(false);
      setShowSpinner(false);
    }
  }, [makemodel.payload.length, reRender]);

  const selectFuelType = (event) => {
    setFuelType(event.currentTarget.value);
    sendFuelTypeToJacada(event)
  }

  const setModelList = (year) => {
    dispatch(requestMakeModel({ year }));
    setShowSpinner(true);
  }

  const enableVehicle = (event) => {
    let yearCopy = event.target.value
    setYear(yearCopy);

    const current_year = new Date().getFullYear();

    if (yearCopy.length === 4) {
      if ((yearCopy > 1970) && (yearCopy < current_year + 2)) {
        setYearHasError(false);
        setModelList(yearCopy);
      } else {
        setYearHasError(true);
      }
    } else {
      if (makemodel.payload.length) {
        dispatch(resetMakeModel());
      }
      setVehicleDisabled(true);
      setVehicleValue('');
      setMake('');
      setModel('');
      setReRender(true);
    }

  }

  const confirmVehicleInfo = (payload) => {
    const caranimationtl = new TimelineMax({ paused: true });
    caranimationtl.play(0, false);
    let eventsData = [{
      'name': 'color_name',
      'value': payload.color
    }];
    eventsData.push(
      {
        'name': 'equpmnt_clss_id',
        'value': 1
      }
    );
    let jacadaRequestData = {
      'externalSystemId': localStorage.getItem('conversationId'),
      'events': eventsData
    }
    sendJacadaRequest(jacadaRequestData);
    if (payload.rentalorborrowedflag) {
      const sortedVehiclesByHighestCoverage = cloneDeep(commonBenefits.vehicles)
      sortedVehiclesByHighestCoverage.sort((a, b) => a.coverageAmount.localeCompare(b.coverageAmount)).reverse()
      payload.consumervehicleid = sortedVehiclesByHighestCoverage[0]?.vehicleID || ''
    }
    dispatch(updateVehicle(payload));
    if (serviceInfo.id === FUEL_SERVICE_ID && payload.fuelType) {
      dispatch(updateServiceTypeOptions({ gas: payload.fuelType }));
    }
    history.push(generatePath('location'));
  }

  const confirmVehicleInformation = () => {
    let vehicleColors = vehicleColor ? vehicleColor.toLowerCase() : "";
    vehicleColors = vehicleColors.charAt(0).toUpperCase() + vehicleColors.slice(1);

    let vehicle = {
      color: vehicleColors,
      make,
      model,
      year,
      equipmentClass: 'Light',
      vin: ''
    }

    if (partnerDetails.experience.supportRentalOrBorrowed) {
      vehicle.rentalorborrowedflag = 'Y'
    }

    if (fuelType) {
      dispatch(updateServiceTypeOptions({ gas: fuelType }));
    }
    sendVehicleToJacada(vehicle)
    setReRender(false);
    dispatch(resetMakeModel());
    if (commonBenefits?.benefits) {
      handleAddedVehicle(true)
    }
    datadogRum.addAction('Redirect to Add Vehicle page')
    confirmVehicleInfo(vehicle);
  }

  const handleAddedVehicle = (flag) => {
    dispatch(updateVehicleInfo({ addedVehicleFlag: flag }));
    updateVehicleIndex(commonBenefits.vehicles.length)
  }

  const updateVehicleIndex = (index) => {
    dispatch(updateVehicleInfo({ selectedVehicleIndex: index }));
  }

  const setMakeModelState = (event) => {
    if (event.target.value.value) {
      let value = event.target.value.value.split("||");
      let make = value[0].replace(/&amp;/g, '&');
      let model = value[1].replace(/&amp;/g, '&');
      setMake(make);
      setModel(model);
      setVehicleValue(`${make} ${model}`);
    }
  }

  const isButtonEnabled = () => {
    if (year.length === 4 && vehicleColor && make && model && vehicleColor !== getTranslatedText(TRANSLATION_CONSTANTS.SELECT_ONE)) {
      if (serviceInfo.id === FUEL_SERVICE_ID) {
        return !!fuelType
      }
      return true
    } else {
      return false
    }
  }

  let endYear = new Date().getFullYear() + 2;
  const getYearErrorText = (getTranslatedText) => `${getTranslatedText(TRANSLATION_CONSTANTS.PLEASE_ENTER_CAR_MODEL)} ${endYear}.`;
  let vehicleSuggestions = makemodel.payload.map(vehicle => { return { 'text': vehicle.text, 'value': `${vehicle.value.make}||${vehicle.value.model}` } });

  const fuelServiceCoverages = commonBenefits?.services?.find((service) => service.type === GAS)?.coverages;

  return (
    <Utility.Div data-dd-privacy="allow">
      {showSpinner &&
        (<Utility.Div className="c-overlay has-loader is-fixed c-container-warning">
          <Loader data-testid="loader" className="c-loader--xl c-loader--position" size="xl" />
        </Utility.Div>)
      }
      <Grid
        id="addVehicleScreen"
        utils={{
          hrLeft: "md",
          hrRight: "md",
          vrTop: "md",
        }}
        verticalAlign="middle"
      >
        <Grid.Column
          col={{ sm: 12, md: 4, lg: 4, xl: 4 }}
          offset={{ md: 4, lg: 4, xl: 4 }}
        >
          <Grid.Column>
            <Flex alignItems="stretch" justifyContent="between" isBlock={true}>
              <Headline
                title={
                  <Type
                    variant="brand"
                    align="left"
                    size="3xl"
                    weight="semibold"
                    lineHeight="md"
                    data-testid="add-vehicle-title"
                  >
                    {getTranslatedText(TRANSLATION_CONSTANTS.VEHICLE_INFO)}
                  </Type>
                }
              />
              <Utility.Div
                onClick={() => {
                  history.push(generatePath("location"));
                }}
                data-testid="add-vehicle-close-icon"
              >
                <Icon icon={CloseIcon} size={1.75} />
              </Utility.Div>
            </Flex>
          </Grid.Column>
          <Divider
            thickness="thick"
            utils={{
              vrBottom: "md",
            }}
          />
          <Grid.Column utils={{vrBottom: 'md'}}>
            <FormField
              hasError={yearHasError}
              errorText={getYearErrorText(getTranslatedText)}
              placeholder={getTranslatedText(TRANSLATION_CONSTANTS.YYYY)}
              name="year"
              id="year"
              label={getTranslatedText(TRANSLATION_CONSTANTS.YEAR)}
              type="tel"
              maxLength={4}
              value={year}
              onChange={event => {
                enableVehicle(event)
              }}
              data-testid="add-vehicle-year"
            />
          </Grid.Column>
          <Grid.Column utils={{vrBottom: 'md'}}>
            <Autocomplete
              placeholder=""
              id="vehicleinfo"
              label={getTranslatedText(TRANSLATION_CONSTANTS.MAKE_MODEL)}
              value={vehicleValue?.replace("||", " ")}
              suggestions={vehicleSuggestions}
              disabled={vehicleDisabled}
              onChange={event => {
                setMakeModelState(event)
              }}
              required
              data-testid="add-vehicle-autocomplete"
            />
          </Grid.Column>
          <Grid.Column>
            <Dropdown
              id="color"
              name="color"
              label={getTranslatedText(TRANSLATION_CONSTANTS.COLOR)}
              value={vehicleColor.toUpperCase()}
              defaultOption={{
                text: "",
                value: getTranslatedText(TRANSLATION_CONSTANTS.SELECT_ONE),
              }}
              options={getTranslatedColors(getTranslatedText)}
              onChange={event => {
                setVehicleColor(event.target.value);
              }}
              required
              sort="asc"
              data-testid="add-vehicle-color"
            />
          </Grid.Column>
          {serviceInfo.id === FUEL_SERVICE_ID && (
            <React.Fragment>
              <Grid.Column util={{ vrTop: 'md' }}>
                <FormOptionGroup
                  label={getTranslatedText(TRANSLATION_CONSTANTS.FUEL_TYPE)}
                  id="helpmechoose"
                  type="radio"
                  required={true}
                  options={[
                    {
                      id: "helpmechoose1",
                      name: "helpmechoose",
                      text: getTranslatedText(TRANSLATION_CONSTANTS.UNLEADED),
                      value: "Unleaded",
                      checked: fuelType === "Unleaded",
                      "data-testid": "add-vehicle-fuel-unleaded",
                      onChange: (event) => {
                        selectFuelType(event);
                      }
                    },
                    {
                      id: "helpmechoose2",
                      name: "helpmechoose",
                      text: getTranslatedText(TRANSLATION_CONSTANTS.DIESEL),
                      value: "Diesel",
                      checked: fuelType === "Diesel",
                      "data-testid": "add-vehicle-fuel-diesel",
                      onChange: (event) => {
                        selectFuelType(event);
                      }
                    },
                  ]}
                />
              </Grid.Column>
              {commonBenefits.experience?.fuelCostCovered && !isEmpty(fuelServiceCoverages) && (
                <Grid.Column>
                  <FuelAlert
                    fuelServiceCoverages={fuelServiceCoverages}
                  />
                </Grid.Column>
              )}
            </React.Fragment>
          )}
          <Grid.Column
            utils={{
              vrBottom: "md",
              vrTop: "lg"
            }}
          >
            <Button
              hasUpgrade
              shape="pill"
              size="lg"
              onClick={confirmVehicleInformation}
              disabled={!isButtonEnabled()}
              utils={{
                fullWidth: true,
              }}
              id="add-vehicle-button"
              data-testid="add-vehicle-button"
            >
              {getTranslatedText(TRANSLATION_CONSTANTS.ADD)}
            </Button>
          </Grid.Column>
        </Grid.Column>
      </Grid>
    </Utility.Div>
  )
}

export default AddVehicle;
