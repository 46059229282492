import React, {useEffect, useState} from "react";
import { Button, Grid } from "mesh-component-library";
import {AutoC, CreditcardC} from "../../icon-library/lib";
import renderIcon from "../../utilities/render-icon";
import Icon from "../../components/icon";
import titleCase from "../../utils/titleCase";
import {datadogRum} from "@datadog/browser-rum";
import {LocaleConsumer} from "../../contexts/LocaleContext";
import {TRANSLATION_CONSTANTS} from "../../app-consts/translations";
import {
  colorTranslatedText,
  serviceTranslatedText
} from "../../utils/translatedTextUtils";
import {PersonIcon, CalendarIcon} from "mesh-icon-library";
import {Icon as MeshIcon} from "mesh-component-library";
import {CancellationModal} from "../../components/cancellationmodal";
import {getRescueTrackerStatus} from "../../api/api";
import Spinner from "../../components/spinner";
import Alert from "../../components/alert";
import {
  updateScheduledDateToReadableValue,
  updateScheduledTimeToReadableTimeRange
} from "../../utils/timeUtil";
import {
  ACTIVE_RESCUE_STATUSES,
  CANCELLED
} from "../../app-consts/appConstants";
import useInterval from "../../hooks/useInterval";
import { clearDuplicateRescue } from "../../action";
import { useDispatch } from "react-redux";

const initialStatusResponseState = {
  addresses: [],
  services: [],
}

const AppointmentConfirmation = (props) => {
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [statusResponse, setStatusResponse] = useState(initialStatusResponseState)
  const [rescueCancelled, setRescueCancelled] = useState(false)
  const [showCancellationError, setShowCancellationError] = useState(false)
  const [showAlertModal, setShowAlertModal] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [continuePolling, setContinuePolling] = useState(true)
  const timeLeft = useInterval(getAppointmentInformation, 30000, true);
  const dispatch = useDispatch();

  let urlParams = new URLSearchParams(window.location.search);
  const usageId = urlParams.get('id');
  useEffect(() => {
    document.title = "Appointment Confirmation | Roadside Assistance";
    window.scrollTo(1, 1);
    getAppointmentInformation();
    datadogRum.addAction("Appointment Confirmation page loaded")
  }, []);

  async function getAppointmentInformation() {
    if (continuePolling) {
      const result = await getRescueTrackerStatus("omni", usageId, "usageid");
      setIsLoading(false)
      if (result === undefined ) {
        setShowAlertModal(true)
        setAlertMessage(TRANSLATION_CONSTANTS.APPOINTMENT_DETAILS_RESPONSE_ERROR)
      } else if (result.rescueStatus?.toUpperCase() === CANCELLED) {
        setRescueCancelled(true)
        setAlertMessage("")
        setShowAlertModal(false)
        setContinuePolling(false)
      } else if (ACTIVE_RESCUE_STATUSES.includes(result.providers[0]?.currentCheckpointStatus?.toUpperCase())) {
        props.history.push(`/wd/l/${result.partnerCode}/appointmentconfirmationtracker/${usageId}`)
      } else {
        setShowAlertModal(false)
        setAlertMessage("")
        setStatusResponse(result)
      }
    }
  }

  const renderCancelRescueSection = (getTranslatedText) => {
    return (
      <Grid.Column 
        col={{ sm: 10, md: 2, lg: 2, xl: 2 }}
        offset={{ sm: 1, md: 5, lg: 5, xl: 5 }}>
        <Button
          hasUpgrade
          isPrimary={false}
          size='lg'
          onClick={() => setShowCancelModal(true)}
          data-testid="cancelRescueButton"
          utils={{
            fullWidth: true
          }}>
          {getTranslatedText(TRANSLATION_CONSTANTS.CANCEL_RESCUE)}
        </Button>
      </Grid.Column>
    );
  }

  const renderRequestSummarySection = (getTranslatedText) => {
    const scheduledTime = statusResponse?.scheduledTime;
    const scheduledDate = scheduledTime?.split(" ")[0];
    const scheduledStartTime = scheduledTime?.split(" ")[1];

    const currentAddress = statusResponse?.addresses.find(address => address?.addressType.toUpperCase() === "DISABLEMENT PICK UP");
    const destinationAddress = statusResponse?.addresses.find(address => address?.addressType.toUpperCase() === "DISABLEMENT DROP OFF")
    const formatAddress = address => address ? `${address?.line1}, ${address?.city}, ${address?.state} ${address?.zipCode}` : ''

    const vehicle = statusResponse?.vehicle;

    const cost = parseFloat(statusResponse?.cost)?.toFixed(2)

    return (
      <>
        {isLoading ? <Spinner/> : null}
        {!isLoading && !showAlertModal ?
          <div className="l-confirmation-container mtop">
            <div className="l-container-location request-section">
              <div>
                <h4
                  className="title">{getTranslatedText(TRANSLATION_CONSTANTS.REQUEST_SUMMARY)}</h4>
              </div>
              <div data-testid="requestSummaryDetails">
                <div className="c-container-vehicle">
                  <div className="icon-calendar">
                    <MeshIcon icon={CalendarIcon} color="#FFF" size={1}/>
                  </div>
                  <h4 className="c-container-vehicle__title">{getTranslatedText(TRANSLATION_CONSTANTS.SERVICE_DATE)}</h4>
                  <h6 style={{
                    textAlign: 'left',
                    fontSize: '1em',
                    fontWeight: 'bold',
                    color: '#2F3847',
                    gridColumn: '2/span 3'
                  }}>
                    {scheduledDate && updateScheduledDateToReadableValue(scheduledDate)}
                  </h6>
                  <h6 style={{
                    textAlign: 'left',
                    fontSize: '0.9em',
                    color: '#2F3847',
                    gridColumn: '2/span 3'
                  }}>
                    {scheduledStartTime && updateScheduledTimeToReadableTimeRange(scheduledStartTime)}
                  </h6>
                </div>
                <div
                  className={`${statusResponse?.towService ? 'c-current-location--tow' : ''} c-current-location nomargin`}>
                  <Icon className="c-current-location__icon"
                        icon={renderIcon(statusResponse?.services[0]?.name)}
                        color="brand"
                        size={1.5}/>
                  <h4
                    className="c-current-location__tow">{getTranslatedText(serviceTranslatedText(statusResponse?.services[0]?.name))}</h4>
                  <React.Fragment>
                    <h6
                      className="c-current-location__title">{getTranslatedText(TRANSLATION_CONSTANTS.VEHICLE_LOCATION)}</h6>
                    <p data-testid="currentAddress"
                       className="c-current-location__address">{titleCase(formatAddress(currentAddress))}</p>
                  </React.Fragment>
                  {statusResponse?.towService &&
                    <React.Fragment>
                      <h6
                        className="c-current-location__dropoff-title"> {getTranslatedText(TRANSLATION_CONSTANTS.DESTINATION_LOCATION)} </h6>
                      <p data-testid="destinationAddress"
                         className="c-current-location__dropoff-shop-name">{titleCase(formatAddress(destinationAddress))}</p>
                    </React.Fragment>
                  }
                </div>
                <div className="c-container-vehicle">
                  <Icon icon={AutoC} color="brand" size={1.5}/>
                  <h4
                    className="c-container-vehicle__title"> {getTranslatedText(TRANSLATION_CONSTANTS.VEHICLE)}  </h4>
                  <h6 className="c-container-vehicle__values u-text-capitalize">
                    {titleCase(`${vehicle?.year} ${getTranslatedText(colorTranslatedText(vehicle?.color))} ${vehicle?.make} ${vehicle?.model}`)}
                  </h6>
                </div>
                <div className="c-contact-payment__payment-info">
                  <div className="u-flex u-flex--middle u-flex--start">
                    <div className="icon-person u-hr-2">
                      <MeshIcon icon={PersonIcon} color="#FFF" size={1.5}/>
                    </div>
                    <h4 className="c-contact-payment__title">{getTranslatedText(TRANSLATION_CONSTANTS.CONTACT)}</h4>
                  </div>
                  {statusResponse?.customer?.firstName &&
                    <div
                      className="u-flex u-flex--top u-flex--between u-hr-p-left">
                      <p className="c-contact-payment__benefits-text mbottom">
                        {titleCase(statusResponse.customer.firstName + ' ' + (statusResponse.customer.lastName ?? ''))}
                      </p>
                    </div>
                  }
                  {statusResponse?.customer?.emailId &&
                    <div
                      className="u-flex u-flex--top u-flex--between u-hr-p-left">
                      <p
                        className="c-contact-payment__benefits-text mbottom">{statusResponse.customer.emailId.toLowerCase()}</p>
                    </div>
                  }
                  {statusResponse?.customer?.contactNumber &&
                    <div
                      className="u-flex u-flex--top u-flex--between u-hr-p-left">
                      <p
                        className="c-contact-payment__benefits-text mbottom">{statusResponse.customer.contactNumber}</p>
                    </div>
                  }
                </div>
                {cost !== "0.00" &&
                  <div className="c-container-vehicle">
                    <Icon icon={CreditcardC}
                          color="brand"
                          size={1.5}/>
                    <h4 className="u-flex u-flex--top u-flex--between">{getTranslatedText(TRANSLATION_CONSTANTS.PAYMENT)}</h4>
                    <h6 className="c-container-vehicle__values ">${cost}</h6>
                  </div>
                }
              </div>
            </div>
          </div>
          : null}
      </>
    );
  }

  return (
    <LocaleConsumer>
      {(getTranslatedText) => (
        <>
          <div role="main" className="l-container">
            {rescueCancelled ? <Alert type='info'
                                      alertMessages={[getTranslatedText(TRANSLATION_CONSTANTS.APPOINTMENT_CANCELLED)]}
                                      className='rescue-tracker-alert'/> : null}
            {showCancellationError ? <Alert type='error'
                                            alertMessages={[getTranslatedText(TRANSLATION_CONSTANTS.APPOINTMENT_CANCELLED_ERROR)]}
                                            className='rescue-tracker-alert'/> : null}
            {showAlertModal ? <Alert type='error'
                                     alertMessages={[getTranslatedText(alertMessage).replace('{timeLeft}', timeLeft)]}
                                     className='rescue-tracker-alert'/> : null}

            {showCancelModal &&
              <CancellationModal
                usageId={usageId}
                setRescueCancelled={setRescueCancelled}
                setShowCancellationError={setShowCancellationError}
                useAlternateCancellationEndpoint={true}
                requestId={statusResponse?.initialCallId}
                hideCancelModal={() => setShowCancelModal(false)}
                scheduled={true}
                cancellationSource={"WLE-SS"}
                clearDuplicateRescue={() => dispatch(clearDuplicateRescue())}/>
            }
            {!rescueCancelled && !showAlertModal && <>
              <div>
                <h4 className="appointment-confirmed"
                    id="tracking-your-rescue">{getTranslatedText(TRANSLATION_CONSTANTS.APPOINTMENT_CONFIRMED)}</h4>
              </div>
              <>
                <div className="l-tracker-container" data-testid="lyftSection">
                  <div className="c-lyftSection">
                    <div className="c-lyftSection--content">
                      <div className="c-lyftSection--title">
                        <h3
                          className="happen-next-title">{getTranslatedText(TRANSLATION_CONSTANTS.HAPPEN_NEXT)}</h3>
                      </div>
                      <div className="c-lyftSection--main">
                        <p
                          className="happen-next-content">{getTranslatedText(TRANSLATION_CONSTANTS.APPOINTMENT_SCHEDULED)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              {renderRequestSummarySection(getTranslatedText)}
              <>
                <div className="l-tracker-container" data-testid="lyftSection">
                  <div className="c-lyftSection">
                    <div className="c-lyftSection--content">
                      <div className="c-lyftSection--title">
                        <h3
                          className="need-changes-title">{getTranslatedText(TRANSLATION_CONSTANTS.NEED_CHANGES)}</h3>
                      </div>
                      <div className="c-lyftSection--main">
                        <p>{getTranslatedText(TRANSLATION_CONSTANTS.ANYTHING_HAPPENS)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              <div className="c-shareSection--button">
                {(rescueCancelled || showCancellationError) ? null : renderCancelRescueSection(getTranslatedText)}
              </div>
            </>}
          </div>
        </>
      )}
    </LocaleConsumer>
  );
};
export default AppointmentConfirmation;
