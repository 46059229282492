import axios from 'axios';
import {datadogRum} from "@datadog/browser-rum";
import partnerCodeHandler, {splashPageHandler} from "./interceptor";
import {
  API_COMBO,
  JACADA_DEV_URL,
  JACADA_PROD_URL
} from '../app-consts/appConstants';
import getUserTypeFromUrl from '../utils/getUserTypeFromUrl';
import {ErrorSource} from '@datadog/browser-core'
import {cookieMatcher} from '../utils/cookieUtils';

const endpoint_url = process.env.REACT_APP_LOCAL ? "http://localhost:8883" : "";
const drw_endpoint_url = process.env.REACT_APP_LOCAL ? "http://localhost:8888" : "";
const userType = getUserTypeFromUrl();
const make_model = `/wle/${userType}/vehicle/year`;
const create_session = "/"+userType+"/api/createSession";
const provider_info = "/"+userType+"/api/getProviderInfo";
const searchVehicleByLicensePlateUrl = `/vehicle/licenseplate`

axios.interceptors.request.use(request => partnerCodeHandler(request));

axios.interceptors.response.use(splashPageHandler, error => {
  return Promise.reject(error);
})

export async function callToGetTimeZone (latitude, longitude, time){
  return axios.get(`${endpoint_url}/wle/timezone?location=${latitude},${longitude}&timestamp=${time}`)
    .then(response => {
      return response})
    .catch(error =>  {
      datadogRum.addError(`Call to Google Timezone API resulted in an error: `,  {latitude, longitude, timestamp: time, error})
    })
}

export async function getPredictiveETA(addressDetails){
  return axios.post(`${endpoint_url}/wle/getPredictiveETA`, addressDetails)
    .then(response => {
      return response
    });
}

export function getMakeModelApi(year){
    return axios.get(`${endpoint_url}${make_model}/${year}`)
      .then(response => ({ response }))
      .catch(error => ({ error: error.response }))
}

export async function cancelRescueApi(appId, partnerCode, reqId, sessionId, cancelReason, source) {
  return axios.post(`${endpoint_url}/openapi/${appId}/partner/${partnerCode}/session/${sessionId}/rescue/${reqId}/cancel`, {
    reqId,
    sessionId,
    cancelReason,
    source
  }, {headers: {}, handlerEnabled: true});
}

export const searchVehicleByLicensePlate = async (route, licensePlate, state) => {
  let cleanPlate = licensePlate.replace(/[^0-9a-zA-Z]/g, '');
  return axios.post(`${drw_endpoint_url}/api/${route}${searchVehicleByLicensePlateUrl}`, {plate: cleanPlate, stateCode: state})
    .catch(function (error) {
      datadogRum.addError(new Error(`Vehicle search by license plate API returned non-2xx status for licensePlate: ${licensePlate} / state: ${state}. Error: ${error}`))
    });
}

export function submitServiceRequest(payload, partnerCode, serviceType, experience, hasPepSession = false, omniStreamlined = ""){
  const sessionId = payload.sessionId;
  const appId = payload.serviceRequest.appId;
  let isPpu = payload.serviceRequest.ppuFlag === "Y";
  if((!experience.ppuEnabled && !experience.benefitsEnabled) || hasPepSession){
    isPpu = true
  }
  let url = ""
  if (!!omniStreamlined) {
    url = `${drw_endpoint_url}/api/omniapi/${appId}/partner/${partnerCode}/rescue?serviceType=${serviceType}&final=Y`
  } else {
    url = `${endpoint_url}/openapi/${appId}/partner/${partnerCode}/session/${sessionId}/rescue?serviceType=${serviceType}`
  }

  return axios.post(url, payload, {headers: {"isPpu": `${isPpu}`}})
}

export function createSessionRequest(createSessionRequest){
    return axios.post(`${endpoint_url}${create_session}?disablementlatitude=${createSessionRequest.disablementlatitude}&disablementlongitude=${createSessionRequest.disablementlongitude}&appId=${createSessionRequest.appId}`,{}, { headers: {}, handlerEnabled: true });
}

export function serviceRequestInfoApi(request, appId, partnerCode){
    let serviceRequestInfoRequest = {requestId: request.requestId, sessionId:request.sessionId};
    return axios.post(`${endpoint_url}/openapi/${appId}/partner/${partnerCode}/session/${request.sessionId}/rescue/${request.requestId}/serviceinfo`,serviceRequestInfoRequest);
}

export function getProviderInfoApi(request, appId, partnerCode){
    let providerInfoRequest = {requestId: request.requestId, sessionId:request.sessionId};
    const headers = {};
    let defaultEndpoint = `/openapi/${appId}/partner/${partnerCode}/session/${request.sessionId}/rescue/${request.requestId}/providerinfo`
    if (userType === 'benefits') {
      const token = getTokenFromCookie();
      headers["X-Authorization"] = `Bearer ${token}`;
      defaultEndpoint = provider_info
    }
    return axios.post(`${endpoint_url}${defaultEndpoint}`,providerInfoRequest, { headers, handlerEnabled: true });
}

export function getPartnerDetailsApi(route, displayCode){
  return axios.get(`${endpoint_url}/wle/${route}/partner/${displayCode}`);
}

export const submitFeedback = async (feedbackRequest, appId, partnerCode) => {
  if(partnerCode === 'VAV' || partnerCode === 'V15') {
    return axios.post(`${drw_endpoint_url}/api/omniapi/${partnerCode}/${localStorage.getItem('benefitSession')}/feedback-survey`,feedbackRequest, { headers: {}, handlerEnabled: true });
  } else {
    return axios.post(`${endpoint_url}/openapi/${appId}/partner/${partnerCode}/session/${feedbackRequest.sessionId}/feedback`,feedbackRequest, { headers: {}, handlerEnabled: true });
  }
}

export function userBenefitsApi(sessionId){
  const userType_getBenefits_path = "/"+getUserTypeFromUrl()+"/api/getBenefits";
    return axios.post(`${endpoint_url}${userType_getBenefits_path}?sessionId=${sessionId}`,{}).then((response) => {
			    				return response;
                            }, (error) => {
                                return error.response;
                            });
}

export async function createMemberSessionOpenApi(route, { memberId, partnerCode, type, createSessionRequest, phoneLookup, appId, details, vin, mileage, flippedToPpu, arsToken, lastName }) {
  let customerVin = "", customerMileage = "", latitude = "", longitude = "", detail = "";

  if(createSessionRequest) {
    customerVin = createSessionRequest.vin;
    customerMileage = createSessionRequest.mileage;
    latitude = createSessionRequest.latitude ? createSessionRequest.latitude : "";
    longitude = createSessionRequest.longitude ? createSessionRequest.longitude : "";
  }
  if(details){
    const { firstName, lastName, zip, phone, companyName, stateCode } = details;
    detail  = `${firstName ? firstName : null},${lastName ? lastName : null},${zip ? zip : null},${phone ? phone : null},${companyName ? companyName : null},${stateCode ? stateCode : null}`;
  }
  const body = {
    memberIdentifier: memberId,
    vin : vin ? vin : customerVin,
    mileage: mileage ? mileage : customerMileage,
    latitude,
    longitude,
    details: detail,
    phoneLookup,
    flippedToPpu,
    arsToken,
    lastName
  };

  const url = `${drw_endpoint_url}/api/openapi/${route}/${appId}/partner/${partnerCode}/session?type=${type}`;
  let retry = 0
  while (retry < 3) {
    try {
      let response = await axios.post(url, body)
      if(retry){
        let message = `Create Session api call failed and recovered for memberId: ${memberId}, detail: ${detail}`
        datadogRum.addError(new Error(message));
      }
      return response
    } catch (err) {
      if (err.response?.status === 500 && retry < 2) {
        retry += 1
      } else {
        return err.response
      }
    }
  }
}

export async function fetchCommonBenefitsForUser(sessionId, appId, partnerCode) {
  const conversationId = localStorage.getItem('conversationId');
  let url = `${drw_endpoint_url}/api/openapi/${appId}/partner/${partnerCode}/session/${sessionId}/benefits`;
  let urlSearchParams = [];
  if (conversationId) {
    urlSearchParams.push([ "conversationId", conversationId ])
  }
  if(urlSearchParams.length > 0) {
    url += `?${urlSearchParams.map(([key, value]) => `${key}=${value}`).join("&")}`
  }
  let retry = 0
  while (retry < 3) {
    try {
      let response = await axios.get(url)
      if(retry){
        let message = `Common Benefits api call failed and recovered for appId: ${appId}, partnerCode: ${partnerCode}, sessionId: ${sessionId}`
        datadogRum.addError(new Error(message));
      }
      return response
    } catch(err) {
      if (err.response?.status === 500 && retry < 2) {
        retry += 1
      } else {
        return err.response
      }
    }
  }
}

export async function fetchBenefits(options, appId, partnerCode) {
  let url = `${drw_endpoint_url}/api/omniapi/${appId}/partner/${partnerCode}/retrieveBenefits`;
  const conversationId = localStorage.getItem('conversationId');
  let urlSearchParams = [];
  if (conversationId) {
    urlSearchParams.push([ "conversationId", conversationId ])
  }
  if(urlSearchParams.length > 0) {
    url += `?${urlSearchParams.map(([key, value]) => `${key}=${value}`).join("&")}`
  }
  let firstName, lastName, zipCode, stateCode;
  if(options.details){
    firstName = options.details.firstName ? options.details.firstName : null
    lastName = options.details.lastName ? options.details.lastName : null
    zipCode = options.details.zip ? options.details.zip : null
    stateCode = !!options?.details?.stateCode ? options?.details?.stateCode : null;
  }
  const body = {
    partnerMemberId: options.partnerMemberId,
    vin : options.vin,
    firstName,
    lastName,
    zipCode,
    stateCode,
    phoneNumber: options.phoneNumber,
    incomingNumber: options.phoneNumber,
    tollFreeNumber: options.tollFreeNumber,
    jobId: options.jobId,
    lookupType: options.lookupType
  };
  let retry = 0
  while (retry < 3) {
    try {
      let response = await axios.post(url, body)
      if(retry){
        let message = `Omni Search API call failed and recovered for appId: ${appId}, partnerCode: ${partnerCode}`
        datadogRum.addError(new Error(message));
      }
      return response
    } catch(err) {
      if (err.response?.status === 500 && retry < 2) {
        retry += 1
      } else {
        datadogRum.addError(`Omni Search API call failed with status ${err.response?.status}`)
        return err.response
      }
    }
  }
}

export function getPartnerSpecificTowToLocations(location, appId, partnercode, countrycode) {
  const {lat, lng} = location;
  const sessionId = localStorage.getItem('benefitSession');
  return axios.post(`${endpoint_url}/openapi/${appId}/partner/${partnercode}/session/${sessionId}/towlocations?lat=${lat}&lng=${lng}&countrycode=${countrycode}`, {})
    .then(
      (response) => {return {
        destinations: response.data.towTo,
        customDestinationList: response.data.destinationsList
      }},
      (error) => {
        datadogRum.addError(new Error(`getPartnerSpecificTowToLocations error: ${error}`))
        return {error: error.response}
      }
    )
}

export function sendJacadaRequest(data) {
  if(!window.location.href.toLowerCase().includes('omniassist')){
    return
  }

  const params = new URLSearchParams(window.location.search);
  data.externalSystemId = params.get('conversationId');

  if (!data.externalSystemId) {
    datadogRum.addError(new Error('Could not send Jacada request becasue externalSystemId was undefined'), undefined, ErrorSource.CUSTOM);
    return
  }
  if (window.location.href.indexOf('D24') > -1 || window.location.href.indexOf("OmniAssist") === -1) {
    return
  }

  data.events?.forEach(event=>{
    if (event.value === undefined || event.value === null) event.value = ''
  });

  let baseUrl = window.location.href.indexOf("https://roadside.allstate.com") > -1 ? JACADA_PROD_URL : JACADA_DEV_URL;
  const url =`${baseUrl}/${data.externalSystemId}/events`
  let envHeader = window.location.href.indexOf("https://roadside.allstate.com") > -1 ? `Prod` : `Dev`;
  let axiosJacada = axios;
  datadogRum.addAction('jacadaEvent',{url, data, envHeader});
  return axiosJacada.post(url, {data}, {headers: {"x-api-key": API_COMBO, "env": envHeader}}).then((response) => {
    return response;
  });
}

export function getTokenFromCookie() {
  try {
    let cookieMatches = cookieMatcher();
    return cookieMatches ? JSON.parse(cookieMatches[2]).token : '';
  } catch (e) {
    console.error("Error retrieving token: ", e)
    return ''
  }
}

export async function getRescueTrackerStatus(partnerCode, idvalue, idtype) {
  try {
    const response = await axios.get(`${endpoint_url}/wle/tracker/partner/${partnerCode}/${idtype}/${idvalue}/rescuestatus`)
    if(response?.data?.error) {
      datadogRum.addError('rescueStatusTrackerError-response', {
        partnerCode,
        idtype,
        idvalue,
        response
      });
    } else {
      return response.data
    }
  } catch (error) {
    datadogRum.addError(`Rescue Status View Error: ${error?.response?.data?.error?.errors?.filter((_x, index) => index === 0)?.[0] ?? error?.message}`, {rescueStatusViewError: {partnerCode, idvalue, error}});
  }
}

export async function cancelViaRescueTracker(partnerCode, usageId, cancelReason, source, scheduled){
  try {
    const response = await axios.post(`${endpoint_url}/wle/partner/${partnerCode}/usageid/${usageId}/cancel`, {cancelReason, source, scheduled})
    if(response?.data?.error) {
      datadogRum.addError('rescueTrackerCancelError-response', {
        partnerCode,
        usageId,
        response
      });
    } else {
      return response.data
    }
  } catch (error) {
    datadogRum.addError('rescueTrackerCancelError',{partnerCode, usageId, error});
  }
}

export function getShortenedUrl(partnerCode, usageId, url) {
  return axios.post(`${endpoint_url}/wle/urlshortener`, {url: url})
  .then(response => response.data.shorturl)
  .catch(error => {datadogRum.addError('urlShortenerError',{partnerCode, usageId, error});})
}

export async function searchRescue(partnerCode = "", appId = "", lookupType = "", searchBy = "") {
  const body = {
    lookupType,
    searchBy
  };

  const url = `${drw_endpoint_url}/api/omniapi/${appId}/partner/${partnerCode}/searchrescue`;

  try {
    let response = await axios.post(url, body);
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function saveRescueDetails(serviceRequestPayload, partnerCode, serviceType) {

  let url = `${drw_endpoint_url}/api/omniapi/${serviceRequestPayload.serviceRequest.appId}/partner/${partnerCode}/rescue`;
  if(serviceType) {
    url += `?serviceType=${serviceType}`
  }

  try {
    return await axios.post(url, serviceRequestPayload);
  } catch (err) {
    return err.response;
  }
}
