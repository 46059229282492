import {
  REFRESH_REDUCERS,
  UPDATE_ADDITIONAL_COMMENTS,
  UPDATE_CCARD_INFO,
  UPDATE_CONTACT_NUMBER,
  UPDATE_DISABLEMENT_ADDRESS,
  CLEAR_DISABLEMENT_ADDRESS,
  UPDATE_EMAIL,
  UPDATE_FIRST_NAME,
  UPDATE_LAST_NAME,
  UPDATE_OPERATOR_ID,
  UPDATE_PPU_FLAG,
  UPDATE_LOGGED_IN_FLAG,
  UPDATE_SESSION_ID,
  UPDATE_VEHICLE,
  UPDATE_SMS_OPT_IN,
  UPDATE_SERVICE_TYPE_OPTIONS,
  UPDATE_DESTINATION_ADDRESS,
  REMOVE_DESTINATION_ADDRESS,
  CLEAR_CCARD_INFO,
  UPDATE_PROMO_CODE,
  UPDATE_PARTNER_MEMBER_ID,
  UPDATE_USAGE_ID,
  UPDATE_TOW_REASON,
  UPDATE_TOW_DISTANCE,
  UPDATE_CURRENT_MILEAGE,
  UPDATE_RENTAL_DETAIL
} from '../action';
import {
  CLEAR_SERVICE_TYPE_OPTIONS,
  REMOVE_ADDITIONAL_FIELD,
  UPDATE_ADDITIONAL_FIELDS,
  UPDATE_APP_ID,
  UPDATE_BUSINESS_NAME
} from "../action/updateServiceRequestPayload-action";
import _cloneDeep from "lodash/cloneDeep";

const initialState = {
  sessionId: "",
  serviceRequest: {
    additionalFields: [[]],
    deviceTimeZone: "CST",
    ppuFlag: "",
    loggedIn: "N",
    appId: "",
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    consumerId: "",
    smsOptIn: "Y",
    partnerTransactionId: "",
    disablementAddress: {
      addressLine1: "",
      city: "",
      zipCode: "",
      country: "",
      state: "",
      latitude: 0,
      longitude: 0
    },
  }
};

export default function (state = initialState, action) {
  let additionalFields = state.serviceRequest.additionalFields
  switch (action.type) {
    case UPDATE_APP_ID: {
      return {
        ...state,
        serviceRequest: {
          ...state.serviceRequest,
          appId: action.payload
        }
      };
    }
    case UPDATE_SESSION_ID: {
      return {
        ...state,
        sessionId: action.payload
      }
    }
    case UPDATE_PROMO_CODE: {
      return {
        ...state,
        promoCode: action.payload
      }
    }
    case UPDATE_PARTNER_MEMBER_ID: {
      return {
        ...state,
        partnerMemberId: action.payload
      }
    }
    case UPDATE_USAGE_ID: {
      return {
        ...state,
        usageId: action.payload
      }
    }
    case UPDATE_TOW_REASON: {
      return {
        ...state,
        towReason: action.payload
      }
    }
    case UPDATE_TOW_DISTANCE: {
      return {
        ...state,
        towDistance: action.payload
      }
    }
    case UPDATE_RENTAL_DETAIL: {
      return {
        ...state,
        rentalDetail: action.payload
      }
    }
    case CLEAR_DISABLEMENT_ADDRESS:
      let initialDisablementAddress = {...initialState.serviceRequest.disablementAddress}
      return {
        ...state,
        serviceRequest: {
          ...state.serviceRequest,
          disablementAddress: initialDisablementAddress
        }
      }
    case UPDATE_DISABLEMENT_ADDRESS:
      let confirmedAddress = {
        addressLine1: action.payload.addressLine1,
        city: action.payload.city,
        zipCode: action.payload.zipCode,
        country: action.payload.country,
        state: action.payload.state,
        latitude: action.payload.latitude,
        longitude: action.payload.longitude
      }
      return {
        ...state,
        serviceRequest: {
          ...state.serviceRequest,
          disablementAddress: confirmedAddress
        }
      };
    case UPDATE_DESTINATION_ADDRESS:
      return {
        ...state,
        serviceRequest: {
          ...state.serviceRequest,
          destinationAddress: action.payload
        }
      };
    case REMOVE_DESTINATION_ADDRESS:
      let newServiceRequest = {...state.serviceRequest}
      delete newServiceRequest.destinationAddress
      return {
        ...state,
        serviceRequest: newServiceRequest
      };
    case UPDATE_ADDITIONAL_COMMENTS:
      return {
        ...state,
        serviceRequest: {
          ...state.serviceRequest,
          additionalComments: action.payload
        }
      };
    case UPDATE_FIRST_NAME:
      return {
        ...state,
        serviceRequest: {
          ...state.serviceRequest,
          firstName: action.payload
        }
      };
    case UPDATE_LAST_NAME:
      return {
        ...state,
        serviceRequest: {
          ...state.serviceRequest,
          lastName: action.payload
        }
      };
    case UPDATE_OPERATOR_ID:
      return {
        ...state,
        operatorId: action.payload
      };
    case UPDATE_SMS_OPT_IN:
      return {
        ...state,
        serviceRequest: {
          ...state.serviceRequest,
          smsOptIn: action.payload
        }
      };
    case UPDATE_EMAIL:
      return {
        ...state,
        serviceRequest: {
          ...state.serviceRequest,
          email: action.payload,
          consumerId: action.payload
        }
      };
    case UPDATE_ADDITIONAL_FIELDS:
      const [[key, value]] = Object.entries(action.payload)

      return {
        ...state,
        serviceRequest: {
          ...state.serviceRequest,
          additionalFields: [removeDuplicateFields(key, value, additionalFields)]
        }
      }
    case REMOVE_ADDITIONAL_FIELD:
      const newAdditionalFields = additionalFields[0].filter(field => {
        return field.key !== action.payload
      })
      return {
        ...state,
        serviceRequest: {
          ...state.serviceRequest,
          additionalFields: [newAdditionalFields]
        }
      }

    case UPDATE_CONTACT_NUMBER:
      const phoneNumber = action.payload.replace(/-/g,"");

      return {
        ...state,
        serviceRequest: {
          ...state.serviceRequest,
          contactNumber: phoneNumber,
          additionalFields: [removeDuplicateFields('aniNbr', phoneNumber, additionalFields)]
        }
      };
    case UPDATE_VEHICLE:
      let payloadCopy = _cloneDeep(action.payload);
      const sanitizedVehicle = sanitizeVehiclePayload(action.payload);
      let finalState = {
        ...state,
        serviceRequest: {
          ...state.serviceRequest,
          vehicles: sanitizedVehicle
        }
      };
      if (payloadCopy?.assetDescription) {
        finalState.assetDescription = payloadCopy?.assetDescription;
      }
      return finalState;
    case UPDATE_CURRENT_MILEAGE:
        return {
          ...state,
          serviceRequest: {
            ...state.serviceRequest,
            currentMileage: action.payload
          }
        };
    case UPDATE_PPU_FLAG:
      return {
        ...state,
        serviceRequest: {
          ...state.serviceRequest,
          ppuFlag: action.payload
        }
      };
    case UPDATE_LOGGED_IN_FLAG:
      return {
        ...state,
        serviceRequest: {
          ...state.serviceRequest,
          loggedIn: action.payload
        }
      };
    case UPDATE_CCARD_INFO:
      return {
        ...state,
        serviceRequest: {
          ...state.serviceRequest,
          CCardInfo: {
            ...state.serviceRequest.CCardInfo,
            ...action.payload
          }
        }
      };
    case CLEAR_CCARD_INFO:
      let newRequest = {...state.serviceRequest}
      delete newRequest.CCardInfo
      return {
        ...state,
        serviceRequest: newRequest
      }
    case CLEAR_SERVICE_TYPE_OPTIONS:
      delete state.serviceRequest?.serviceTypeOptions;
      return { ...state }
    case UPDATE_SERVICE_TYPE_OPTIONS:
      return {
        ...state,
        serviceRequest: {
          ...state.serviceRequest,
          serviceTypeOptions: {
            ...action.payload
          }
        }
      }
    case UPDATE_BUSINESS_NAME:
      return {
        ...state,
        businessName: action.payload,
      }
    case REFRESH_REDUCERS:
      return {...initialState};
    default:
      return state;
  }
}

const supportedVehicleAttributes = [
  "make",
  "color",
  "model",
  "year",
  "vin",
  "equipmentClass",
  "consumervehicleid",
  "consumervehiclepropid",
  "rentalorborrowedflag"
];

const removeDuplicateFields = (key, value, additionalFields) => {
  let newArray = additionalFields[0].filter(field => {
    return field.key !== key
  })

  newArray.push({key, value})

  return newArray
}

export const sanitizeVehiclePayload = (vehicleInfo) => {
  if(vehicleInfo.vehicleID){
    vehicleInfo.consumervehicleid = vehicleInfo.vehicleID;
  }
  for(let key in vehicleInfo){
    if(!supportedVehicleAttributes.includes(key)) {
      delete vehicleInfo[key]
    }
  }
  return vehicleInfo
}
