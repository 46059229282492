import {call, put, takeEvery,select, all} from 'redux-saga/effects';
import {getPartnerDetails, getTowDistance, getTowReason} from '../selector';

import {
  REQUESTED_EPCS_STATUS,
  REQUESTED_EPCS_STATUS_FAILED,
  REQUESTED_EPCS_STATUS_SUCCESS,
  UPDATE_CCARD_INFO,
  UPDATE_EPCSSTATUS_RETRY
} from './../action';
import {datadogRum} from "@datadog/browser-rum";
import {status} from './../api';
import { EPCS_STATUS_CARD_ERROR, EPCS_STATUS_FATAL, EPCS_STATUS_SUCCESS } from '../app-consts/appConstants';

export function* epcsStatusSaga(action) {
  try {
    const partnerDetails = yield select(getPartnerDetails);
    const towReason = yield select(getTowReason);
    const towDistance = yield select(getTowDistance);
    let { partnerCode, appId, omniStreamlined, promoCode } = partnerDetails;
    const response = yield call(status, action.payload, partnerCode, appId, omniStreamlined, towReason, towDistance, promoCode);
    const payload = yield response.data;
    if(omniStreamlined && !!payload?.paymentStatus) {
      const PAYMENT_STATUS = payload.paymentStatus;
      if([EPCS_STATUS_FATAL, EPCS_STATUS_CARD_ERROR].includes(PAYMENT_STATUS)) {
        yield all([
          put({type: UPDATE_EPCSSTATUS_RETRY, payload}),
        ])
      }
      if(PAYMENT_STATUS === EPCS_STATUS_SUCCESS) {
        yield all(epcsStatusSuccess(payload))
      }
    } else {
      yield all(epcsStatusSuccess(payload))
    }
  } catch (e) {
    datadogRum.addError(new Error(`Could not get EPCS Status ${e}`));
    yield put({type: REQUESTED_EPCS_STATUS_FAILED});

  }
}

export function* watchEpcsStatusSaga() {
  yield takeEvery(REQUESTED_EPCS_STATUS, epcsStatusSaga);
}

const sanitizeEpcsResponseForServiceRequestPayload = (payload) => {
  const sanitizedPayload = {...payload}
  sanitizedPayload.expirationDate = sanitizedPayload.cardExpirationDate
  delete sanitizedPayload.cardExpirationDate
  delete sanitizedPayload.cardType
  return sanitizedPayload
}

const epcsStatusSuccess = (payload) => {
  const sanitizedPayload = sanitizeEpcsResponseForServiceRequestPayload(payload);
  return [
    put({type: REQUESTED_EPCS_STATUS_SUCCESS, payload: payload}),
    put({type: UPDATE_CCARD_INFO, payload: sanitizedPayload})
  ]
}
